import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { CodeOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const HelloSample = () => (
  <Fragment>
            <Typography>
      <Title level={1}>Common AVAP Code Examples</Title>
      <Paragraph>
        In this document, we will list a series of AVAP code examples for common problems and scenarios. Each example will cover a specific topic, providing a detailed solution in AVAP code.
      </Paragraph>

      <Title level={2}>2. Hello World Sample</Title>
      <Paragraph>
        In this simple example, we assign the value 'Hello World!' to a variable and return it.
      </Paragraph>

      <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
        <pre>
        <code>
            {`// Hello World Sample

// Assign 'Hello World!' value to a variable
addVar(result, 'Hello World!')

// Return this variable
addResult(result)`}
          </code>
        </pre>
      </div>

    </Typography>
  </Fragment>
);
export default HelloSample;
