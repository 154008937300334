import React, { FunctionComponent, useEffect, useState } from 'react';
// import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, } from 'react-bootstrap';
// import ConsumptionChart from './GraphComponents/ConsumptionChart';
// import Consumptions from './Consumption/Consumptions';
// import CategoriesConsumption from './Consumption/CategoriesConsumption';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import NewSidebarAVAP from './newSidebarAVAP/NewSidebarAVAP';
import Header from '../../components/functionalComponents/baseLayout/HeaderMenu';
import "./NewDashboard.css"
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import PersonalInformation from './personalInformation/PersonalInformation';
import SiginSecurity from './signinSecurity/SiginSecurity';
import LicensesAnddownloads from './licensesAnddownloads/LicensesAnddownloads';
import ProjectGranted from './projectGranted/ProjectGranted';
import PaymentsAndbilling from './paymentsAndbilling/PaymentsAndbilling';
import Privacy from './privacy/Privacy';
import NewHeader from './newHeaderDashboard/NewHeaderMenu';
import LicensesAndSuscriptions from './licensesAndSusccriptions/LicensesAndSuscriptions';
import SupportBanner from '../../components/DashboardComponents/supportBanner';
import BillingAndpayments from './billingAndpayments/BillingAndpayments';
import licensesManager from './licensesManager/licensesManager';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/Disclaimer';
// import FooterMenu from '../../components/functionalComponents/FooterLayout/FooterMenu';

type DashProps = {
  sider: any
};



const NewDashboard = () => {
  let { path, url } = useRouteMatch();

  const history = useHistory();
  /*
const getVerified = async () => {
    SessionService.RENOVATEDSUSCRIPTION =true;
    const result = await ObexRequestHandler.get('/check_renovated_suscription');
    
    const { success, data } = result;
    if (success) {
      const { renovated } = data;
      
      const wast = document.getElementById('nopayment') as HTMLDivElement;
      if (!renovated) {
        wast.style.display = 'block';
        SessionService.RENOVATEDSUSCRIPTION = renovated;
      }
    }
}
*/
  useEffect(() => {
    //getVerified();
  }, []);



 

  return (
    // <DashLayout sider={sider}>

    //   <div id = 'nopayment' style={{ 
    //     display: 'none', 
    //     backgroundColor: 'red', 
    //     height:'22px', 
    //     color: 'white', 
    //     marginLeft:'-328px', 
    //     textAlign: 'center',
    //     paddingTop:'3px', 
    //     marginBottom:'90px', 
    //     marginTop:'-90px', 
    //     position:'fixed', 
    //     zIndex:9999, 
    //     width: '100%' 
    //     }}>
    //     You has pending charges, and your suscription will be suspended <a onClick={()=>{history.push('/plan_subcription#nopayment2')}}>please click here to make the payment</a>
    //   </div>

    //   <Consumptions/>
    //   <Row className="">
    //     <Col className="mt-4 p-0">
    //       <ConsumptionChart/>
    //     </Col>
    //   </Row>
    //   <CategoriesConsumption/>
    // </DashLayout>
    <>
      <BrowserView>
    <div>
      
      <div style={{ minHeight: '100vh' }} className="d-flex">

        <div className='newSidebar boxShadowRight' style={{position:'fixed', height:'100%'}}>
          <NewSidebarAVAP />
        </div>

        <div className='d-flex flex-column newContent'>
          <div>
            <NewHeader />
          </div>
          <div className='marginToShowContent'>
            <main >
              <Switch>
                <Route exact path={path}>
                  <Redirect to={`${path}/personal-information`} />
                </Route>
                <Route path={`${path}/personal-information`} component={PersonalInformation} />
                <Route path={`${path}/sigin-and-security`} component={SiginSecurity} />
                <Route path={`${path}/licenses-and-suscriptions`} component={LicensesAndSuscriptions} />
                <Route path={`${path}/licenses-and-downloads`} component={LicensesAnddownloads} />
                <Route path={`${path}/project-granted`} component={ProjectGranted} />
                <Route path={`${path}/payments`} component={PaymentsAndbilling} />
                <Route path={`${path}/billing`} component={BillingAndpayments} />
                <Route path={`${path}/licenses-manager`} component={licensesManager} />
                <Route path={`${path}/privacy`} component={Privacy} />

              </Switch>
            </main>
          </div>
        </div>
      </div>


      <>
          
    <div style={{backgroundColor:'black', zIndex:999999999}}>
           { false && <SupportBanner /> }
          
          <div id="issue360" style={{ display: 'none', position: 'fixed', marginLeft: '1vw', marginTop: '80vh', zIndex: 999999 }}>
            <iframe
              width="250"
              height="50"
              src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=teal&size=standard&radius=4px&label=ISSUE360 on WhatsApp&number=525544593433">
            </iframe>
          </div>

          </div>
        </>

      <div style={{position:'relative', zIndex:0}}>
        <FooterMenu green={true}/>
      </div>

    </div>
          </BrowserView>
          <MobileView>
            <Disclaimer />
          </MobileView>
        </>
  )
}

export default NewDashboard;