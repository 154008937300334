import React, { FunctionComponent, useState, useEffect, Fragment } from 'react'; 
import { Container, Row, Col, Image, Navbar, Nav } from 'react-bootstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Logo2 from '../../../assets/img/ilustracion_accountVerified@2x.png'
import { useHistory, useParams } from 'react-router-dom';
import Logo from '../../../assets/img/AVAP_ID_horizontal.png';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import VerifySuccess from '../../../components/AccountVerified/VerifiedSuccess';
import FooterMenu from '../../../components/functionalComponents/DashboardLayout/FooterMenu';
import Header from './Verifyheader';

import logo_framework from "../../../assets/img/ilustracion_accountVerified@2x.png"
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../../components/mobileComponents/Disclaimer';

const Avapidverify:FunctionComponent<{}> = () => {

  const [verified, setVerified] = useState(false);
  const [username, setUsername] = useState('');
  const [finished, setFinish] = useState(false);
  const { key } = useParams();
  const history = useHistory();

  function routeHome() {
    const path = '/login';
    window.location.href = path;
  }

  const getVerified = async () => {

    const result = await ObexRequestHandler.get(`/validation_avapid/${key}`);
    const { success, data } = result;
    if (success) {
      const { username } = data;
      setUsername(username);      
      setVerified(true);
      setFinish(true);
    }
}

  useEffect(() => {
    getVerified();
  }, []);


  return(
    <>
      <BrowserView>
    <div className="text-center d-flex flex-column containerLogin ">
       {/*} <Header></Header> */}
{/*}
       <Navbar className='navbar' style={{position:'fixed', width:'100%'}}>
            <Container className="">
            <Navbar.Brand href="#home" style={{}}>
                <Image src={logo_framework} style={{ marginBottom: "5px" }} height="33px" width="" alt="Logo" />
            </Navbar.Brand>
            <Nav style={{ width: "80%" }}>
            <Nav.Link href="#section1" style={{marginRight:"10px", marginLeft:"10px"}}>Products</Nav.Link>
            <Nav.Link href="#section2" style={{marginRight:"10px", marginLeft:"10px"}}>Partners</Nav.Link>
            <Nav.Link href="#section3" style={{marginRight:"10px", marginLeft:"10px"}}>Resource</Nav.Link>
            <Nav.Link href="#section4" style={{marginRight:"10px", marginLeft:"10px"}}>Company</Nav.Link>
            <Nav.Link href="#section4" style={{marginRight:"10px", marginLeft:"10px"}}>Contact Sales</Nav.Link>
            </Nav>
            </Container>
        </Navbar>
*/}
        <div className="justify-content-center pt-5 rowLogin " style={{minHeight:"74vh"}}>
          <div className="login-form px-5 pt-4">
          <Row className="justify-content-center" style={{marginTop:'3rem'}}>
              <Col >
                {/* <Image onClick={routeHome} src={Logo} fluid /> */}
                <Image onClick={routeHome} src={Logo} fluid
                  width="300"
                  style={{ marginBottom: '60px' }}
                />
                
              </Col>
            </Row>
        

            <Col className="my-2 text-center">
              <h1 className="mb-2">Congratulations!</h1>
              
            </Col>


        
      {/* {!finished
        ? <Fragment>Loading...</Fragment>
        :  <Fragment>
            {verified
            ? <VerifySuccess username={username}/> 
            : <VerifyError/>}
          </Fragment>
      } */}

<div>

  </div>

  <Row className="justify-content-center my-4">
            <Col style={{width:'80%'}}>
              <Image src={Logo2} fluid/>
            </Col>
          </Row>        

        <Col>
        <div>
          <h6 className='green textBold' style={{color:'#316952'}}><span>Congratulations!</span>
          <span> Your identity has been successfully verified.</span></h6>
        </div>

        {/*}
        <div style={{width:'80%' , marginLeft:'auto', marginRight:'auto'}}>
        <h6 className='text-boldLogin'>Your sender identify has been successfully verified.</h6>
        </div>
        */}

        <button style={{marginTop:'60px'}} onClick={()=>{history.push('/login')}}className='custom-button-green textBold w-100'>Go to login</button>
        <div style={{height:'7rem'}}></div>
        </Col>

      </div>
      
        </div>
        <FooterMenu green={true}/>
    </div>
    </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}


export default Avapidverify;