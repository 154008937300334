import { Container, Row, Col } from 'react-bootstrap';
import React, { FunctionComponent, useEffect, useState } from 'react';
import '../../../assets/css/baseLayout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderMenu from '../baseLayout/HeaderMenu';
import DashboardSideBar from './DashboardSidebar';
import Footer from './FooterMenu';

type BaseProps = {
  sider: any,
  active?: string,
  sublinks?: Array<any>,
  projects?: Array<any>,
  children?: any
};

const BaseLayout:FunctionComponent<BaseProps> = (props: BaseProps) => {
  const { sider, children, active, sublinks } = props;

  const valuesSideBarClient = [
    "Dashboard",
    "Projects",
    "Requests",
    "FX Exchange",
    "Balance",
    "Income",
    "Marketplace",
    "Developer Teams",
    "Organizations",
    "Developers",
    "Subscription",
    "Settings"
]

const [isDashboardBlue, setIsDashboardBlue] = useState(false);

  const groupSider = ()=>{
    const groupTitles = sider.menus.map(item => item.title);
    const equal = arraysAreEqual(valuesSideBarClient, groupTitles);
    if(equal == true){
      setIsDashboardBlue(true);
    }
    // debugger
  }

  function arraysAreEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  useEffect(() => {
    groupSider();
  }, []);

  return (
    // view old dashboard to blue AVAP CLOUD
    <>
    {
      isDashboardBlue &&
      (
        <Container fluid className='px-0' style={{ paddingLeft:'0px !important', paddingRight: '0px !important', marginRight:'9rem !important' }}>
        <HeaderMenu isDashboardBlue={isDashboardBlue}/>
        <Row id="content-page" className='marginTopDashboardBlue'>
          <DashboardSideBar sider={sider} active={active} sublinks={sublinks}/>
          <Col id="content" className={`d-flex flex-column min-vh-100 ${isDashboardBlue ? 'marginContentAvapCloud' : ''}`}>
            <Row className="pt-4 pl-4 pr-4 mr-0 ml-0" style={{ marginRight:'9rem' }}>
              <Col>
                {children}
              </Col>
            </Row>
          </Col>
        </Row>
        <Footer/>
      </Container>
      )
    }

    {
      !isDashboardBlue &&
      (
        <Container fluid className='px-0' style={{ paddingLeft:'0px !important', paddingRight:'0px !important', marginRight:'9rem !important' }}>
        <HeaderMenu isDashboardBlue={true}/>
        <Row id="content-page">
          <DashboardSideBar sider={sider} active={active} sublinks={sublinks}/>
          <Col id="content" className={'d-flex flex-column min-vh-100'}>
            <Row className="" style={{ marginLeft:'63px', marginRight:'9rem', paddingTop:'2rem' }}>
              <Col>
                {children}
              </Col>
            </Row>
            <Row id="footer-dashboard" className={'justify-content-md-center footer-dashboard mt-auto'}>
              <Col>
                
              </Col>
            </Row>
            
          </Col>
        </Row>
        
        <Footer/>
        
      </Container>
      )
    }
    </>


  )
}

export default BaseLayout;