import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Modal, Nav,  } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import SupportBanner from '../../DashboardComponents/supportBanner';
import UpgradePlanModal from '../../Modals/UpgradePlanModal';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import SessionService from '../../../services/SessionsService';



// ESTE ES EL SIDEBAR DE DASHBOARD(UNA VEZ LOGUEADO ETC)

type SideProps = {
  sider: any,
  active?: string,
  sublinks?: Array<any>
};

const DashboardSideBar:FunctionComponent<SideProps> = (props: SideProps) => {

  const { sider, active, sublinks } = props;
  
  const history = useHistory();

  const { menus } = sider;
  const urlPathName = window.location.pathname;
  const [navMenu, setNav] = useState([]);
  const [expanded, setExpanded] = useState('');
  const [showModal, setShowModal2] = useState(false);
  const [showModa3, setShowModal3] = useState(false);
  const handleClose2 = () => setShowModal2(false);
  const handleClose3 = () => setShowModal3(false);


  const [showSplash, setShowSplash] = useState(true);
  const [showSplash2, setShowSplash2] = useState(false);
  const [showSplash3, setShowSplash3] = useState(false);
  const [showSplash4, setShowSplash4] = useState(false);
  const [showSplash5, setShowSplash5] = useState(false);
  const [showSplash6, setShowSplash6] = useState(false);
  const [showSplash7, setShowSplash7] = useState(false);
  const [showSplash8, setShowSplash8] = useState(false);
  const [showSplash9, setShowSplash9] = useState(false);
  const [showSplash10, setShowSplash10] = useState(false);
  const [showSplash11, setShowSplash11] = useState(false);
  const [showSplash12, setShowSplash12] = useState(false);
  const [showSplash13, setShowSplash13] = useState(false);
  const [showSplash14, setShowSplash14] = useState(false);
  const [showSplash15, setShowSplash15] = useState(false);
  const [showSplash16, setShowSplash16] = useState(false);
  const handleClose = () => {

    setShowSplash(false)
  
  }
  const handleClose2a = () => {setShowSplash2(false); }

  const handleClose2b = () => {setShowSplash2(false);setShowSplash3(true) }
  const handleShow = () => setShowSplash2(!showSplash2);
  
  const handleClose3b = () => setShowSplash3(false)
  const handleShow2 = () => setShowSplash3(!showSplash3);

  const handleClose4 = () => setShowSplash4(false)
  const handleShow3 = () => setShowSplash4(!showSplash4);

  const handleClose5 = () => setShowSplash5(false)
  const handleShow4 = () => setShowSplash5(!showSplash5);

  const handleClose6 = () => setShowSplash6(false)
  const handleShow5 = () => setShowSplash6(!showSplash6);

  const handleClose7 = () => setShowSplash7(false)
  const handleShow6 = () => setShowSplash7(!showSplash7);

  const handleClose8 = () => setShowSplash8(false)
  const handleShow7 = () => setShowSplash8(!showSplash8);

  const handleClose9 = () => setShowSplash9(false)
  const handleShow8 = () => setShowSplash9(!showSplash9);

  const handleClose10 = () => setShowSplash10(false)
  const handleShow9 = () => setShowSplash10(!showSplash10);

  const handleClose11 = () => setShowSplash11(false)
  const handleShow10 = () => setShowSplash11(!showSplash11);

  const handleClose12 = () => setShowSplash12(false)
  const handleShow11 = () => setShowSplash12(!showSplash12);

  const handleClose13 = () => setShowSplash13(false)
  const handleShow12 = () => setShowSplash13(!showSplash13);

  const handleClose14 = () => setShowSplash14(false)
  const handleShow13 = () => setShowSplash14(!showSplash14);

  const handleClose15 = () => setShowSplash15(false)
  const handleShow14 = () => setShowSplash15(!showSplash15);


  const handleClose16 = () => setShowSplash16(false)
  const handleShow15 = () => setShowSplash16(!showSplash16);


const [currentSubscription, SetCurrentsuscripcion] = useState(0);

  const valuesSideBarClient = [
    "Dashboard",
    "Projects",
    "Requests",
    "FX Exchange",
    "Balance",
    "Income",
    "Marketplace",
    "Developer Teams",
    "Organizations",
    "Developers",
    "Subscription",
    "Settings"
]

const [isDashboardBlue, setIsDashboardBlue] = useState(false);

  const groupSider = ()=>{
    const groupTitles = sider.menus.map(item => item.title );
    const equal = arraysAreEqual(valuesSideBarClient, groupTitles);
    if(equal==true){
      setIsDashboardBlue(true);
    }
    // debugger
  }

  function arraysAreEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }

  const loadNav = () => {
    //const cookies = new Cookies();
    //const sessionCookie = cookies.get('101Obex');
    // let showAdmin = false;
    // if (sessionCookie && sessionCookie.admin) showAdmin = true;
    const navs = menus.map((nav) => {
      const { id, links, basePath, icon, title } = nav;
      let linka = links;
      if (active === id && sublinks) linka = linka.concat(sublinks);
      return (
        (title!='Requests' && title!='Settings' && title!='Marketplace' && title!='Income' && title!='Balance' && title!='FX Exchange')  &&
    
        <Nav key={'base_nav'+nav.title} className="flex-column mb-2 pl-4">
          <div className={'px-0 title-nav'}>


            <div style={{ marginLeft: '12px' }}className={`${active == id ? 'selectedBlue text-bold': 'colorBlack3 fontSizeLinks'}`} onClick={() => {
              
              if (basePath == '/signout') setShowModal2(true);
              else if (basePath == '/upgrade_plan_splash') setShowModal3(true);
              else if (basePath == '/tutorial') {

                if (window.location.pathname === '/cloud_dashboard') window.location.reload(); else 
                {
                  SessionService.TUTORIAL_SHOWED = false;
                  history.push('/cloud_dashboard');
                }
                
                /*setShowSplash2(true);*/}
              else history.push(basePath, {});



          
          }}>
              {/* {nav.icon &&
                <img className={'pr-2'} src={`/src/assets/img/iconosv2/icon-${icon}.svg`} />
              } */}
              {title}
            </div>



          </div>
          {(expanded === id || active === id) && ( 
            // si el expandido desde la raiz es el id o el activo mandado por props lo es expandimos el menú
            <div>
              <Nav.Item className="ml-4">
                {linka.map(link => {
                  const { title, path, icon } = link;
                  const url = `${basePath}/${path}`;

                  return (
                    (title != 'Sandbox' && title != 'Sandbox' && title != 'Staging Projects' && title != 'API Test Runner' && title != 'API Test Results' && title != 'Api Status') && 
                    <Fragment key={'base'+link.path}>
                      <Nav.Link className={`pl-2 ${urlPathName == url ? 'link-active' : ''}`} 
                        onClick={() => history.push(url, {})}>
                        {/* {icon &&
                          <img className={'pr-2'} src={`/src/assets/img/iconosv2/icon-${icon}.png`} />
                        } */}
                        <span>{title}</span>
                      </Nav.Link>
                    </Fragment>
                  );
                })}
              </Nav.Item>
            </div>
          )}
        </Nav>

    
        
      )
    })
    setNav(navs);
  }


        const statusScript = document.getElementById('st-snippet') as HTMLScriptElement;
        if (!statusScript) { // si no existe el script
          const scriptS = document.createElement('script');

          scriptS.id = 'st-snippet';
          scriptS.src = 'https://vch0nryy6nvg.statuspage.io/embed/script.js';
          scriptS.async = true;
        
          document.body.appendChild(scriptS);
        }


  const setExpandedByBasePath = () => {
    const { pathname } = location;
    const expanded = pathname.split('/')[1]
    setExpanded(expanded);
  }

  const getConsumption = async (disableCache = false) => {
    const result = await ObexRequestHandler.get('/consumption', {}, !disableCache);
    const { success, data } = result;
    if (success) {
      
      SetCurrentsuscripcion(data.subscriptionInfo.subscription_id);
      
    }
}

  useEffect(()=> {
    getConsumption()
    groupSider();
    loadNav();
    setExpandedByBasePath();
    
  }, []);

  useEffect(()=> {
    loadNav();
  }, [sublinks]);

  return (
    <>




<Modal className='tip1'
   show={showSplash2} 
   animation={false}
   onHide={handleClose2}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Organizations</div>
        <div style={{fontSize:'12px'}}>
        Organizations allow you to group developers and projects within the same operational scope. Multiple organizations can be present in a deployment, but information cannot be exchanged between them
        </div>
          <button onClick={()=>{handleClose2a()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button onClick={()=>{handleClose2()}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip2'
   show={showSplash3} 
   animation={false}
   onHide={handleClose3}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Developers</div>
        <div style={{fontSize:'12px'}}>
        Displays information about the maximum number of available slots in your membership, the slots contracted, and the slots in use. The account owner automatically consumes one developer slot.
        </div>
        <button onClick={()=>{handleClose3()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button onClick={()=>{handleClose3(); setShowSplash4(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip3'
   show={showSplash4} 
   animation={false}
   onHide={handleClose4}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>API Projects</div>
        <div style={{fontSize:'12px'}}>
        Shows information about the maximum capacity of API Projects in your membership, the available slots, and those used.
        </div>
        <button onClick={()=>{handleClose4()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button onClick={()=>{handleClose4(); setShowSplash5(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip4'
   show={showSplash5} 
   animation={false}
   onHide={handleClose5}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Managed APIs</div>
        <div style={{fontSize:'12px'}}>
        Helps you keep track of the maximum regarding managed APIs, as well as the available slots for API creation and the ones currently in use. An API consumes one managed API slot.
        </div>
        <button onClick={()=>{handleClose5()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button  onClick={()=>{handleClose5(); setShowSplash6(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip5'
   show={showSplash6} 
   animation={false}
   onHide={handleClose6}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Requests</div>
        <div style={{fontSize:'12px'}}>
        Each API call consumes a request, and this section of the Dashboard allows you to monitor the maximum allowed requests per month according to your membership, the requests contracted, and the consumption to date.
        </div>
        <button onClick={()=>{handleClose6()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button  onClick={()=>{handleClose6(); setShowSplash7(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip6'
   show={showSplash7} 
   animation={false}
   onHide={handleClose7}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{ height:'180px' }}>
        <div style={{ fontSize:'18px' }} className='textBold mb-3'>Storage</div>
        <div style={{ fontSize:'12px' }}>
        Manages the storage of your account. Here you can find the maximum size allowed for your database, the contracted storage, and real-time usage.
        </div>
        <button onClick={()=>{handleClose7()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button onClick={()=>{handleClose7(); setShowSplash8(true)}} style={{ marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0 }} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip7'
   show={showSplash8} 
   animation={false}
   onHide={handleClose8}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Organizations</div>
        <div style={{fontSize:'12px'}}>
        In addition to creating new organizations, you can search for and modify information related to them by clicking the edit button that appears to the right of each entry.

        </div>
        <button onClick={()=>{handleClose8()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button onClick={()=>{handleClose8(); setShowSplash9(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip8'
   show={showSplash9} 
   animation={false}
   onHide={handleClose9}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Developers</div>
        <div style={{fontSize:'12px'}}>
        Invite new developers to your organization and work with your team on developing your API Products. Only active developers consume a developer slot.
        </div>
        <button onClick={()=>{handleClose9()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button onClick={()=>{handleClose9(); setShowSplash10(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip9'
   show={showSplash10} 
   animation={false}
   onHide={handleClose10}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Developer Teams</div>
        <div style={{fontSize:'12px'}}>
        Group your developers into development teams to optimize the management of your work teams.
        </div>
        <button onClick={()=>{handleClose10()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button  onClick={()=>{handleClose10(); setShowSplash11(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip10'
   show={showSplash11} 
   animation={false}
   onHide={handleClose11}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>API Projects</div>
        <div style={{fontSize:'12px'}}>
        Organize your API development projects in API Projects, create your API Products, and assign the APIs that make up those products.
        </div>
        <button onClick={()=>{handleClose11()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button  onClick={()=>{handleClose11(); setShowSplash12(true)}}  style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip11'
   show={showSplash12} 
   animation={false}
   onHide={handleClose12}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Subscription</div>
        <div style={{fontSize:'12px'}}>
        Keep track of your subscription and the resources contracted. Configure the payment model that best suits your needs and cancel any unused resources at any time.
        </div>
        <button onClick={()=>{handleClose12()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button  onClick={()=>{handleClose12(); setShowSplash13(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip12'
   show={showSplash13} 
   animation={false}
   onHide={handleClose13}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Upgrade Plan</div>
        <div style={{fontSize:'12px'}}>
        Upgrade your membership and switch to Business or Enterprise based on your needs. Obtain new resources without service downtime.
        </div>
        <button onClick={()=>{handleClose13()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button  onClick={()=>{handleClose13(); setShowSplash14(true)}} style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip13'
   show={showSplash14} 
   animation={false}
   onHide={handleClose14}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Billing</div>
        <div style={{fontSize:'12px'}}>
        At any time, you can view the invoices generated for your membership to keep track of expenses. This includes not only your membership but also all contracted resources.
        </div>
        <button onClick={()=>{handleClose14()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button  onClick={()=>{handleClose14(); setShowSplash15(true)}}  style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>

  <Modal className='tip14'
   show={showSplash15} 
   animation={false}
   onHide={handleClose15}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Tutorial</div>
        <div style={{fontSize:'12px'}}>
        Launch this tutorial at any time. It allows you to access the tutorial and stay updated with any changes. Remember that this tutorial is dynamic and constantly updated!.
        </div>
        <button onClick={()=>{handleClose15()}} style={{marginLeft:'10px', marginBottom:'10px', position:'absolute', bottom:0, left:0}} className='custom-button-blue'>Skip</button>
          <button  onClick={()=>{handleClose15(); setShowSplash16(true)}}  style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>


  <Modal className='tip15'
   show={showSplash16} 
   animation={false}
   onHide={handleClose16}
   
  >
    <Modal.Body className='tipsBorder'
    style={{
      backgroundColor: '#4472b6',
      color: 'white'
    }}
    >
        <div style={{height:'180px'}}>
        <div style={{fontSize:'18px'}} className='textBold mb-3'>Sign out </div>
        <div style={{fontSize:'12px'}}>
        Log out of your session to avoid security issues. It is a good practice to sign out if you move to a different location or remain inactive for an extended period.
        </div>

          <button  onClick={()=>{handleClose16()}}  style={{marginRight:'10px', marginBottom:'10px', position:'absolute', bottom:0, right:0}} className='custom-button-blue'>Got it</button>
        </div>
    </Modal.Body>
  </Modal>






        <Modal style={{ marginTop:'7rem' }} show={showModal} onHide={handleClose2}>
        <Modal.Body className='colorBlack'>Do you want to sign out?</Modal.Body>
        <Modal.Footer>
          <button className="btn custom-button-blue  textBold" onClick={() => history.push('/cloud_login')}>
            Yes
          </button>

          <button className="btn custom-button-blue  textBold" onClick={handleClose2}>
            No
          </button>
          
        </Modal.Footer>
      </Modal>


<UpgradePlanModal 
          showModal={showModa3} 
          text={''} 
          onCloseModal={handleClose3} 
          onCancelModal={handleClose3} 
          currentSuscription={currentSubscription}
          onSuccess={()=>{
            handleClose3()
            window.location.replace('/cloud_dashboard');
          }}/>






      {!isDashboardBlue && (

        <>
          <div id="sidebar" className="pt-4 d-flex flex-column min-vh-100 " style={{ overflow:'scroll', marginTop:'-40px', borderRightColor:'#b9babb', borderRightStyle:'solid', borderRightWidth:'1px' }}>
            {navMenu}
          {  false && <SupportBanner />}
          </div>
          <div id="issue360" style={{ display: 'none', position: 'fixed', marginLeft: '1vw', marginTop: '80vh', zIndex: 999999 }}>
            <iframe
              width="250"
              height="50"
              src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=teal&size=standard&radius=4px&label=ISSUE360 on WhatsApp&number=525544593433">
            </iframe>
          </div>
        </>


      )
      }

      {isDashboardBlue && (

        <>
          <div id="sidebar" className="pt-4 d-flex flex-column min-vh-100 border-right position-fixed pb-0 withoutPaddingButton zIndex0 paddingLeftSidebarBlue" style={{ overflow:'scroll', marginTop:'-40px', paddingBottom:"0px !impoartant", position:"fixed" }}>
            {navMenu}
           { false && <SupportBanner />}
          </div>
          <div id="issue360" style={{ display: 'none', position: 'fixed', marginLeft: '1vw', marginTop: '80vh', zIndex: 999999 }}>
            <iframe
              width="250"
              height="50"
              src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=teal&size=standard&radius=4px&label=ISSUE360 on WhatsApp&number=525544593433">
            </iframe>
          </div>
        </>


      )
      }
    </>
  )
}

export default DashboardSideBar;