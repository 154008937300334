import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import Logo from '../../../assets/img/logodeveloper-pequeño.png';
import { Row, Col, Dropdown, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import SessionExpired from '../../Modals/sessionExpired';
import InactivityModal from '../../Modals/inactivityModal';
import Cookies from 'universal-cookie';
import SessionService from '../../../services/SessionsService';
import ReactGA from 'react-ga4';
import AddOrganizationModal from '../../Modals/addOrganizationModal';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import config from '../../../configs/appConfig.json'

import logo2 from "../../../assets/img/newAvapCloud/logo_Cloud@2x.png";

interface HeaderProps {
  isDashboardBlue?: any; // Aquí especificas que 'sider' puede ser de cualquier tipo
}

const DevHeader:FunctionComponent<HeaderProps> = ({ isDashboardBlue = false }) => {

  const history = useHistory();

  const [isLoggedIn, setLogged] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [inactivity, setInactivity] = useState(false);
  const [developer, setDeveloper] = useState(false);
  const [usersuscriptor, setusersuscriptor] = useState(true);
  const [client, setClient] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentOrganizations, setCurrentOrganizations] = useState(0);
  const [maxOrganizations, setMaxOrganizations] = useState(0);

  const [projects, setProjects] = useState([]);

  const [nombreOrganizacion, setNameOrg] = useState('');

  const cookies = new Cookies();

  const analyticsTags = [
    'G-MQZQJN7TQL',
    'G-98LF8608ZM',
    'G-KKCW4SZLW4',
    'G-EL6DCZWCM7',
    'G-5LKNTQFGD8',
    'G-11ZHMK4Y4Z',
    'G-PDYER55NCM'
  ];

  //ReactGA.initialize('UA-170480071-2');
  //analyticsTags.forEach(tag => {
  //  ReactGA.initialize(tag);
 // });
  
  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SessionService.logout();
    history.push('/login');

  }

  // const getProjects = async () => {
  //   try {
  //     setProjects([]);


  //     // console.log(`CONSULTANDO ORGANIZACIONES PARA DEVELOPER ${SessionService.DEV_ID}`);
  //     const projects = (
  //       await ObexRequestHandler.get(`/my_organizations?dev_id=${SessionService.DEV_ID}`, {}, true)
  //       ).data || [];

  //       //console.log(projects);
  //     setProjects(projects);
      
  //     if (SessionService.ORGANIZATION_NAME == null || SessionService.ORGANIZATION_NAME == ''){

  //     if (projects.length>0 /* SessionService.ORGANIZATION_NAME == null || true*/) {
  //       setNameOrg(projects[0]['name']);
  //       SessionService.ORGANIZATION_NAME = projects[0]['name'];
  //       SessionService.ORGANIZATION = projects[0]['id'];
  //     } else {
  //       setNameOrg(SessionService.ORGANIZATION_NAME);
  //     }

  //     SessionService.eventEmitter.emit('scream', 'Bob');
  //   } else {
  //     setNameOrg(SessionService.ORGANIZATION_NAME);
  //   }
  //   } catch (error) {
  //     console.error('ERROR FETCHING PROJECTS ', error);


  //   }


  // }

  const getProjects = async () => {
    try {
      setProjects([]);
      let projects = null;
      if (SessionService.ORGANIZATIONS == null) {
        projects = (await ObexRequestHandler.get('/organizations', {}, true)).data || [];
        SessionService.ORGANIZATIONS = projects
      }
      else {
        projects = SessionService.ORGANIZATIONS;
      }
      if (projects.length == 0) {
        setNameOrg('My Company');
        SessionService.ORGANIZATION_NAME = 'My Company';
        SessionService.ORGANIZATION = -1;
      } else {
        if (projects.result.length == 0) {
          setNameOrg('My Company');
          SessionService.ORGANIZATION_NAME = 'My Company';
          SessionService.ORGANIZATION = -1;
        }
      }
      setCurrentOrganizations(1);
      setMaxOrganizations(1);
      setCurrentOrganizations(projects['result'].length);
      setMaxOrganizations(projects['max']);

      setProjects(projects['result']);

      if (projects['result'].length > 0) {
        if (SessionService.ORGANIZATION_NAME == null) {
          setNameOrg(projects['result'][0]['name']);
          SessionService.ORGANIZATION_NAME = projects['result'][0]['name'];
          SessionService.ORGANIZATION = projects['result'][0]['id'];
        } else {
          setNameOrg(SessionService.ORGANIZATION_NAME);
        }
      } else {
        SessionService.ORGANIZATION_NAME = 'Sin Organización';
        SessionService.ORGANIZATION = -1;
      }

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }

  }


  useEffect(() => {
    // projects.map(x => console.log(x))
    
    // projects.map(x => console.log(x))
    if (config.url != 'https://avs-primary-pre.101obex.mooo.com') ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Cloud' })//.pageview(window.location.pathname + window.location.search);

    const user = cookies.get('101Obex');
    setDeveloper(SessionService.Developer);
    setusersuscriptor(SessionService.User);
    setClient(SessionService.Client);
    if (user){
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) 
      {
        SessionService.renewSession();
      }
      setLogged(true);
      getProjects();
    }
  }, [])

  // Como este componente siempre está presente ponemos aquí el listener del inactivityEvent para que lancé el modal
  document.addEventListener('inactivityEvent', (e) => {

    e.preventDefault();
    e.stopPropagation();
    setInactivity(true);
  }, false);

  // Como este componente siempre está presente ponemos aquí el listener del sessionExpiredEvent para que lancé el modal
  document.addEventListener('sessionExpiredEvent', (e) => {

    if (sessionExpired) return;
    e.preventDefault();
    e.stopPropagation();
    setSessionExpired(true);
  }, false);

const setName = (orga) =>{

console.log(orga.name);

setNameOrg(orga.name);
SessionService.ORGANIZATION_NAME = orga.name;
SessionService.ORGANIZATION = orga.id;
history.push('/devdashboard');
SessionService.eventEmitter.emit('scream', 'Bob');
return orga.name;
}



  const renewSession = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      console.log('Pulsado RENEW')
      await SessionService.renewSession();
      setInactivity(false);
    } catch(error) {
      console.error(error);
    }
  }
  const handleModal = () => {
    
    setShowModal(!showModal);
  };

  return (
    <Fragment>
      <SessionExpired show={sessionExpired} handleHide={handleLogout} />
      <InactivityModal show={inactivity /* && !sessionExpired*/} renewSession={renewSession} closeSession={handleLogout} />

      {!isDashboardBlue && (
        <Row style={{ justifyContent: 'center' }} className={'py-0 fixed-top px-2'} id="header">

          <Row style={{ width: '1378px' }}>
            <Col md={3} className={'pl-3 my-auto'}>
              <a href="/">
                <img className="logo" src={Logo} />
              </a>
            </Col>
            <Col className="my-auto">
              <Row className="menu-header">
                <Col><a className="link link-header" onClick={() => history.push('/docs/Currencies/Currencies_FX_Exchange_EN')} style={{ textAlign: 'center' }}>Docs</a></Col>
                <Col><a className="link link-header" onClick={() => history.push('/apis/login/Accesos_EN')} style={{ textAlign: 'center' }}>APIs</a></Col>
                <Col><a target="_blank" rel="noopener noreferrer" href="https://status.101obex.com/" className="link link-header" style={{ textAlign: 'center' }}>Status</a></Col>
                {isLoggedIn ?
                  //     <Dropdown>
                  //     <Dropdown.Toggle className="button-support" >Community Support</Dropdown.Toggle>
                  //     <Dropdown.Menu>
                  //       <Dropdown.Item onClick={() => (history.push('https://101obexworkspace.slack.com/'))}>Technical Support</Dropdown.Item>
                  //       
                  //     </Dropdown.Menu>{' '}
                  //   </Dropdown>
                  <Col><a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header" style={{ textAlign: 'center' }}>Community Support</a></Col>
                  :
                  <Col><a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header" style={{ textAlign: 'center' }}>Community Support</a></Col>

                }

              </Row>
            </Col>

            {isLoggedIn ?
              <Dropdown className='my-auto'>
                <Dropdown.Toggle className="organizations" >{nombreOrganizacion}</Dropdown.Toggle>
                <Dropdown.Menu>

                  {projects.map(x => {
                    return (<Dropdown.Item key={x.id} onClick={() => {
                      setName(x);
                    }}>{x.name}</Dropdown.Item>);
                  })}

                </Dropdown.Menu>{' '}
              </Dropdown>

              :
              <span></span>

            }

            {isLoggedIn ?
              <Col md={1} className="user text-right my-auto">
                <Dropdown className="mr-1">
                  <Dropdown.Toggle className="link-user" id="obex-user-menu">{/*<FontAwesomeIcon icon={faUserCircle} size="2x"/>*/}<div style={{ height: '30px' }}><Image id='AvatarImageHeader' style={{ height: '30px', borderRadius:'50%'}} src={SessionService.imageContent} /></div></Dropdown.Toggle>
                  {/*}
                  <Dropdown.Menu>
                    {usersuscriptor && <Dropdown.Item onClick={

                      () => {
                        SessionService.ORGANIZATION_NAME = null;
                        history.push('/cloud_dashboard');
                      }}>Administrator Dashboard</Dropdown.Item>}
                    {developer && <Dropdown.Item onClick={() => {

                      history.push('/devdashboard');
                    }}><h5>Developer Dashboard</h5></Dropdown.Item>}
                    {client && <Dropdown.Item onClick={() => {
                      SessionService.ORGANIZATION_NAME = null;
                      history.push('/clientdashboard')

                    }}>Client Dashboard</Dropdown.Item>}
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown.Menu>{' '}
                  */}
                </Dropdown>
              </Col> :
              <Col md={2} className="login text-right my-auto">
                <a href="/login" className="link-login">Log into Dashboard</a>
              </Col>
            }
            {/* <Col md={1}>
         <SelectLanguage/> 
       </Col>*/}
            <></>
          </Row>

        </Row>


      )}


{isDashboardBlue  && (
        <>
          <Row style={{ justifyContent: 'center' }} className={'py-0 fixed-top px-2'} id="header">

            <Row style={{ width: '100%' }}>

              {/* IM REMOVE THIS */}
              <div className={'pl-3 my-auto'} style={{ width: "320px" }}>
                <a href="/dashboard">
                  <img className="logo sizeAvapCloud" src={logo2} height={"30px"} />
                </a>
              </div>

              <div className="my-auto col mx-0 px-0">
                <div className="d-flex justify-content-between align-items-center">
                  <div className='d-flex align-items-center'>
                  <div className="pr-5"><a className="link link-header fonsize14 px-0 mx-0" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center', color: "#000" }}>Docs</a></div>
                  <div className="pr-5"><a className="link link-header fonsize14 px-0 mx-0" onClick={() => history.push('/wiki')} style={{ textAlign: 'center', color: "#000" }}>Wiki</a></div>
                  <div className="pr-5"><a className="link link-header fonsize14 px-0 mx-0" onClick={() => history.push('/apis/login/Accesos_EN')} style={{ textAlign: 'center', color: "#000" }}>APIs</a></div>


                  {/*    <Col><a className="link link-header" onClick={() => history.push('/downloads')}style={{textAlign: "center"}}>Downloads & GitHub</a></Col>  */}


                  {/* IM REMOVE THIS */}
                  <div className="pr-5"><a target="_blank" rel="noopener noreferrer" href="https://status.101obex.com/" className="link link-header px-0 mx-0" style={{ color: "#000" }}>Status</a></div>

                  {isLoggedIn ?
                    //     <Dropdown>
                    //     <Dropdown.Toggle className="button-support" >Community Support</Dropdown.Toggle>
                    //     <Dropdown.Menu>
                    //       <Dropdown.Item onClick={() => (history.push('https://101obexworkspace.slack.com/'))}>Technical Support</Dropdown.Item>
                    //
                    //     </Dropdown.Menu>{' '}
                    //   </Dropdown>
                    <div className="pr-5"><a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header fonsize14 px-0 mx-0" style={{ color: "#000" }}>Community Support</a></div>
                    :
                    <div className="pr-5"> <a target="_blank" rel="noopener noreferrer" href="https://101obexworkspace.slack.com/" className="link link-header fonsize14 px-0 mx-0" style={{ color: "#000" }}>Community Support</a></div>

                  }
                  </div>

                  <div className='d-flex align-items-center'>
                    <div>
                      {isLoggedIn ?
                        <Dropdown className='my-auto pr-4'>
                          <Dropdown.Toggle className="organizations pl-0 ml-0 fonsize14 " id='fonto'>{nombreOrganizacion}</Dropdown.Toggle>
                          <Dropdown.Menu className='fonsize14'>

                            {projects.map(x => {

                              return (<Dropdown.Item key={x.id} onClick={() => { setName(x) }} className="fonsize14">{x.name}</Dropdown.Item>);

                            })}


                            {currentOrganizations < maxOrganizations ?
                              <Dropdown.Item onClick={() => { setShowModal(true) }}>Create Organization</Dropdown.Item>
                              :
                              <Dropdown.Item onClick={() => { history.push('/plan_subcription/upgrade_plan'); }}>Create Organization</Dropdown.Item>

                            }

                          </Dropdown.Menu>{' '}
                        </Dropdown>

                        :
                        <span></span>

                      }


                    </div>

                    <div>

                      {isLoggedIn ? (
                        <>
                          <div className='pr-4'><a className="link link-header fonsize14" onClick={() => history.push('/login')} style={{ textAlign: 'center', color: "#000" }}>
                            <div onClick={handleLogout}>
                              Sign out
                            </div>
                          </a></div>
                        </>
                      ) :
                        <>
                          <div ><a className="link link-header fonsize14" onClick={() => history.push('/login')} style={{ textAlign: 'center' }}>
                            <div>
                              Sign In <span className='fonsize12'>→</span>
                            </div>
                          </a></div>
                        </>
                      }

                    </div>

                    <div>
                      {isLoggedIn ?
                        <>
                          <div  className="user text-right my-auto pr-5">
                            <Dropdown className="mr-1">
                              <Dropdown.Toggle className="link-user" id="obex-user-menu">{/*<FontAwesomeIcon icon={faUserCircle} size="2x"/>*/}<div style={{ height: '30px', width:"30px", borderRadius:'50%' }}><Image id='AvatarImageHeader' style={{ height: '30px' }} src={SessionService.imageContent} /></div></Dropdown.Toggle>
                              {/*}
                              <Dropdown.Menu>
                                {usersuscriptor && <Dropdown.Item onClick={() => {
                                  history.push('/cloud_dashboard')
                                }}><h5>Administrator Dashboard</h5></Dropdown.Item>}
                                {developer && <Dropdown.Item onClick={() => {
                                  SessionService.ORGANIZATION_NAME = null;
                                  history.push('/devdashboard')
                                }}>Developer Dashboard</Dropdown.Item>}
                                {client && <Dropdown.Item onClick={() => {
                                  SessionService.ORGANIZATION_NAME = null;
                                  history.push('/clientdashboard')
                                }}>Client Dashboard</Dropdown.Item>}
                                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                              </Dropdown.Menu>{' '}
                              */}
                            </Dropdown>
                          </div>
                        </>
                        :
                        <>
                          <div  className="login text-right my-auto">
                            <a style={{ height: '47px', width: '200px', marginRight: '-20px', paddingTop: '12px', fontSize: '16px' }} href="/login" className="link-login">Log into Developer Portal</a>
                          </div>
                        </>
                      }

                    </div>

                  </div>






                </div>
              </div>





              {/* <Col md={1}>
  <SelectLanguage/>
</Col>*/}


            </Row>
          </Row>
        </>
      )}



      {showModal && <AddOrganizationModal show={showModal} handleClose={handleModal} />}

    </Fragment>
  );
}

export default DevHeader;