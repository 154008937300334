import React, { FunctionComponent, Component, useEffect, useState, useRef } from 'react';
import DashLayout from '../../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../../configs/routers/dashboard-routers.json';
import { Row, Col, Alert, Spinner, Card, Button, Form, Image, Popover, OverlayTrigger, InputGroup, FormControl } from 'react-bootstrap';
import AddCreditCard from '../../Modals/addCreditCard';
import visa from '../../../assets/img/visa.png';
import mastercard from '../../../assets/img/mastercard.png';
import dinner from '../../../assets/img/dinner-club.png';
import amex from '../../../assets/img/amex.png';
import { formartTwoDigit } from '../../../services/TimeService';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import '../../../assets/css/card.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import ObexToast from '../../basicComponents/ObexToasts';
import SessionService from '../../../services/SessionsService';
import editicon from '../../../assets/img/icon_edit.png';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../mobileComponents/Disclaimer';

type PaymentProps = {
};


type PCProps = {
  id: string,
  alias?: string,
  brand: string,
  lastFour: string,
  monthExp: number,
  yearExp: number,
  cardName: string,
  defaultCard?: boolean,
  setDefault: () => void,
  remove: () => void
}

// Componente de la card
const PaymentCard:FunctionComponent<PCProps> = (props) => {
  const { id, alias, brand, lastFour, monthExp, yearExp, cardName, defaultCard, setDefault, remove } = props;
  const cardNumber = `****${lastFour}`;
  const expiration = `${formartTwoDigit(monthExp)}/${parseInt(yearExp.toString().split('').slice(-2).join(''))}`;
  const [disabledInput, setDisabledInput] = useState(true);
  const [aliasCard, setAliasCard] = useState(alias);
  const aliasInput = useRef(null);
  const brandsLogos = {
    "visa": visa,
    "mastercard": mastercard,
    "dinner": dinner,
    "amex": amex
  }
 

  const enableEditAlias = () => {
    aliasInput.current.select();
    setDisabledInput(!disabledInput);
  };

  const [removing, setRemoving] = useState(false);
  const [makingDefault, setmakingDefault] = useState(false);

  const clickOnDocument = () => {
    document.body.click()
    // document.body.click() simula un clic en el body del documento para asi cerrar el popover
  }

  const makeDefault = async () => {
    setmakingDefault(true);
    try {
      await setDefault();
    } catch(error) {
      console.error('ERROR SETTING DEFAULT CARD ', error)
    }
    setmakingDefault(false);
  }
  
  const removeCard = async () => {
    setRemoving(true);
    try {
      await remove();
      clickOnDocument();
    } catch(error) {
      console.error('ERROR DELETING CARD ', error)
    }
    setRemoving(false);
  }

  const changeAlias = (e) => {
    setAliasCard(e.target.value);
  }

  const handleEditAlias = async() => {
    try {
      await ObexRequestHandler.put(`/cards/${id}/alias`, { alias: aliasCard });
      

      try {
        const result = (await ObexRequestHandler.get('/cards')).data || {};
        const { defaultCard, cards } = result;

        if (cards) {
          for (const c of cards) {
            const isDefault = c.id === defaultCard;
            if (isDefault){
              console.log('DEFAULT CARD');
              console.log(c);
              SessionService.StripeDefaultCard.alias = aliasCard;
              SessionService.StripeDefaultCard.brand = c.brand;
              SessionService.StripeDefaultCard.last4 = c.last4;
              SessionService.StripeDefaultCard.paymentId = c.id;
            }
          }
        }
      } catch (error) {
        console.error('ERROR FETCHING CARDS ', error);
        throw error;
      }




    } catch (error) {
      console.error('ERROR UPDATING ALIAS CARD ', error)
    }
    setDisabledInput(!disabledInput);
  }

  const confirmDeletePopOver = (
    <Popover id="delete-card-popover">
      <Popover.Title as="h3">Removing card</Popover.Title>
      <Popover.Content>
        Are you sure?
        <Button onClick={removeCard} className="obex-btn btn-block btn-orange">
          Remove 
          {removing && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
        </Button>
        <Button onClick={clickOnDocument} variant="secondary" className="obex-btn btn-block">Cancel</Button>
      </Popover.Content>
    </Popover>
  );
  
  return (
    <Card className="product-detail-card mb-3 ">
    <Card.Body className="p-3">
      <Row>
        <Col md="8">
          <Row>
            <Col md="2">
              <Image fluid src={brandsLogos[brand]}/>
            </Col>
            <Col md="10">
              <Row className="card-info ml-5">
                <Form className="obex-form alias-card-form w-100">
                  <Form.Row>
                  <Col md="5" className="card-name"><span className="credit-font credit-author"> {cardName}</span></Col>
                    <Col md="5">
                      <FormControl
                        ref={aliasInput}
                        className="alias-name"
                        placeholder="Alias"
                        aria-describedby="alias"
                        type="text"
                        plaintext={disabledInput}
                        readOnly={disabledInput}
                        value={aliasCard}
                        onChange={changeAlias} onBlur={handleEditAlias}
                      />
                    </Col>
                    
                    <Col>
                      <Button onClick={enableEditAlias} className="obex-btn btn-edit-alias">
                      <Image src={editicon}  style={{ marginBottom:'' }} width="20px" alt="Logo" />
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
                <Col md="5" className="card-number"><span>{cardNumber}</span></Col>
                <Col md="5" className="card-number"><span>{expiration}</span></Col>
              </Row>
            </Col>
          </Row>
          <Row>
            
          </Row>
        </Col>
        {!defaultCard &&
          <Col>
            <Row className="mb-3">
              <Col md="12" className="ml-auto">
                <Button onClick={makeDefault} className="obex-btn btn-block btn-green">
                  Make Primary
                  {makingDefault && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
              </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12" className=" ml-auto">
                <OverlayTrigger rootClose trigger="click" placement="top" overlay={confirmDeletePopOver}>
                  <Button className="obex-btn btn-block btn-orange">Remove</Button>
                </OverlayTrigger>
              </Col>
            </Row>
          </Col>
        }
      </Row>
      {defaultCard &&
        <div className="border-top my-2"></div>
      }
      {defaultCard &&
        <Row>
          <Col className="my-2">
            <span>This is your primary payment method</span>
          </Col>
        </Row>   
      }
    </Card.Body>
  </Card>
  )
}


// Componente que renderiza todas las tarjetas
const PaymentMethodContent:FunctionComponent<PaymentProps> = ({ }) => {

  const [cards, setCards] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [errorMessage , setErrorMessage] = useState('');
  const handleModal = () => {
    getCards();
    setShowModal(!showModal);
  };

  const setDefaultCard = async (card) => {
    try {
      await ObexRequestHandler.put('/update_default_card', { card });
      await getCards();
    } catch (error) {
      console.error('ERROR UPDATING DEFAULT CARD ', error)
    }
  }

  const removeCard = async (card) => {
    try {
      await ObexRequestHandler.delete(`/cards/${card}`);
      await getCards();
    } catch (error) {
      console.error('ERROR DELETING CARD paymentmethodcontent', error)
      throw error;
    }
  }

  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          if (isDefault){
            console.log('DEFAULT CARD');
            console.log(c);
            SessionService.StripeDefaultCard.alias = c.alias;
            SessionService.StripeDefaultCard.brand = c.brand;
            SessionService.StripeDefaultCard.last4 = c.last4;
            SessionService.StripeDefaultCard.paymentId = c.id;
          }
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          c.setDefault = () => setDefaultCard(c.id);
          c.remove = () => removeCard(c.id);
        }
      }
      setCards(pmCards);
      setLoadingContent(false);
      setSubmitDisabled(false);
    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
      setErrorMessage(error);
    }
  }

  useEffect(() => {
    getCards();
  }, [])

  const listOfCards = cards.map(c => 
    <Row>
      <Col>
        <PaymentCard 
          id={c.id} 
          alias={c.alias}
          brand={c.brand} 
          lastFour={c.last4} 
          monthExp={c.exp_month} 
          yearExp={c.exp_year} 
          cardName={c.card_name} 
          defaultCard={c.defaultCard} 
          setDefault={c.setDefault} 
          remove={c.remove} 
        />
      </Col>
    </Row>
  )

  const loadingSpinner = <Row><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;

  return(
    <>
      <BrowserView>
    <DashLayout sider={DashRouters} active={'setting'}>
      <Row>
        <Col md="9" className="mx-auto">
          <Row>
            <Col>
              <h1 className='textBold'>Payment methods</h1>
            </Col>
          </Row>
          {loadingContent? loadingSpinner : listOfCards}
          <Row>
            <Col>
              <Row>
                <Col md="4" className="ml-auto text-center mb-3 mr-2 pr-4 pl-3">
                  <Button disabled={submitDisabled} className="obex-btn btn-green btn-block" onClick={handleModal}>Add Payment</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {showModal && <AddCreditCard show={showModal} handleClose={handleModal} spanish={false}/>}
        </Col>
      </Row>
      <ObexToast type="error" msg={errorMessage} clean={() => setErrorMessage('')}/>
    </DashLayout>
          </BrowserView>
          <MobileView>
            <Disclaimer />
          </MobileView>
        </>
  )
}

export default PaymentMethodContent;