import React, { Fragment } from 'react';

const AVAP_Cloud = () => (
  <Fragment>
    <div className="body-md">
      <h1>Getting Started with AVAP CLI (Command Line Interface)</h1>
      
      <h2>AVAP CLI</h2>
      <p>
        AVAP CLI (Advanced Virtual API Programming Command Line Interface) is the command line interface for the AVAP Framework platform. This CLI allows users to perform a wide range of operations on the AVAP Framework platform, from API management to advanced deployment configuration. This guide provides the first steps to work with AVAP CLI, the process to obtain and configure the developer token, and the necessary help to start using AVAP CLI effectively.
      </p>

      <h2>What is a CLI?</h2>
      
      <p>
        A CLI (Command Line Interface) is a tool that allows users to interact with a computer system through textual commands entered into a console or terminal. Unlike graphical user interfaces (GUIs), where actions are performed through visual elements like buttons and menus, a CLI is based on typing specific commands to perform tasks.
      </p>
      
      <h3>Key Features of a CLI</h3>
      <ul className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
        <li>Direct Interaction: Allows direct communication with the system via text. Users enter commands that the system interprets and executes.</li>
        <li>Task Automation: Facilitates task automation through scripts, which can increase efficiency and reduce the risk of human errors.</li>
        <li>Access to Advanced Features: Provides access to settings and functionalities that may not be available in a GUI.</li>
        <li>Precise Control: Provides more detailed and specific control over system operations.</li>
      </ul>

      <h2>AVAP CLI in AVAP Framework</h2>
      <p>AVAP CLI is the CLI tool designed to interact with AVAP Framework, the platform that manages the complete lifecycle of APIs.</p>

      <h3>AVAP CLI Features</h3>
      <ul className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
        <li>Creating API repositories: Creates a repository where the developer can maintain different versions of the API throughout the various publishing stages.</li>
        <li>Interacting with API repositories: Manages uploading and downloading information from the repository.</li>
        <li>Publishing APIs: Allows APIs to be published on AVAP Cloud and on-premise environments.</li>
        <li>Configuration and Maintenance: Offers tools to configure and maintain the API development environment.</li>
        <li>Managing API access: Allows developers to request and grant API usage permissions.</li>
      </ul>

      <h3>Common Command Examples</h3>
      <p><b>Obtain and initialize the developer token:</b></p>
      <pre>
        <code>avapcli init &lt;deployment&gt;</code>
      </pre>
      <p>&lt;deployment&gt; can be any of the following variables:</p>
      <ul className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
        <li><b>local</b>: to obtain the developer token from a standalone deployment.</li>
        <li><b>p2p</b>: to obtain the developer token from the peer-to-peer deployment in AVAP Collaborative Network.</li>
        <li><b>cloud</b>: to obtain the developer token from an AVAP Cloud Professional or Business deployment.</li>
      </ul>

      <p><b>Initialize a repository:</b></p>
      <pre>
        <code>avapcli init_repo &lt;api&gt; &lt;entrypoint&gt; &lt;endpoint&gt; &lt;deployment&gt;</code>
      </pre>

      <p><b>Publish an API:</b></p>
      <pre>
        <code>avapcli publish &lt;api&gt; &lt;environment&gt; &lt;deployment&gt;</code>
      </pre>

      <p><b>Check the update log of a repository:</b></p>
      <pre>
        <code>avapcli log &lt;api&gt; &lt;deployment&gt;</code>
      </pre>

      <p>You can find more information related to AVAP CLI at <a href="https://alexandria.avapframework.com">AVAP Alexandria</a> and connect with the user community at <a href="https://communities.avapframework.com">AVAP Communities</a>.</p>

      <h3>Obtaining Development Configuration</h3>
      <h4>Developer Token</h4>
      <p>
        To obtain the developer token and work as such, we need to initialize our configuration via the following command.
      </p>
      <pre>
        <code>avapcli init &lt;deployment&gt;</code>
      </pre>
      <p>
        When we run it, the browser will show a login screen where we will identify ourselves as developers, and upon doing so, provide us with an authentication number to input into the process initiated by the CLI to authenticate.
      </p>

      <h4>Authentication Screen</h4>
      <ul className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
        <li><b>Authentication Code</b></li>
        <li>Entering the code in the CLI process.</li>
      </ul>

      <p>
        Once this process is completed, all the information necessary to work with the various tools provided by AVAP Framework can be accessed via the following command:
      </p>
      <pre>
        <code>avapcli info &lt;deployment&gt;</code>
      </pre>

      <h3>Repository Initialization</h3>
      <p>
        The next thing a developer will need to create their APIs in AVAP Framework is to initialize an API repository. API repositories are not just a code repository, but they also allow configuring the following parameters:
      </p>
      <ul className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
        <li><b>Repository Name</b>: The name we give to identify an API.</li>
        <li><b>Entrypoint</b>: The name of the code file that will execute when an API consumption request is received.</li>
        <li><b>Endpoint</b>: The actual endpoint for consuming the API.</li>
      </ul>

      <p>The command to create a code repository is:</p>
      <pre>
        <code>avapcli init_repo &lt;api&gt; &lt;entrypoint&gt; &lt;endpoint&gt; &lt;deployment&gt;</code>
      </pre>

      <h3>Local Repository Cloning</h3>
      <p>
        It may also be the case that a developer has been invited to develop in an existing code repository or wishes to retrieve the entire structure of an API that they developed. Therefore, they need to clone the entire repository structure to their local machine with the following command:
      </p>
      <pre>
        <code>avapcli clone &lt;api&gt; &lt;deployment&gt;</code>
      </pre>

      <h3>Updating the Code in the Repository</h3>
      <p>
        Once the developer has made changes, additions, or deletions to the API code and wants to update the repository, they need to upload the changes by creating an upload package.
      </p>
      <pre>
        <code>avapcli push &lt;api&gt; &lt;message&gt; &lt;deployment&gt;</code>
      </pre>
      <p>
        This command creates an entry in the repository with the changes from the previous state, allowing the changes to be identified with a descriptive message.
      </p>

      <h3>Retrieving Code from the Repository</h3>
      <p>
        In a normal development cycle, there are many occasions where it is necessary to revert to a previous version of the code in the repository. To do so, the developer must use the following command:
      </p>
      <pre>
        <code>avapcli checkout &lt;commit_id&gt; &lt;deployment&gt;</code>
      </pre>
      <p>
        You must indicate the commit ID that identifies the version of the code you wish to retrieve. Note that this command only works if you are in the repository folder.
      </p>

      <h3>Commit History</h3>
      <p>
        All activity logs, along with the repository commit IDs, can be retrieved via the following command:
      </p>
      <pre>
        <code>avapvli log &lt;api&gt;</code>
      </pre>

      <h3>Publishing an API</h3>
      <p>
        The final step in publishing an API in AVAP Framework is managed through the following command:
      </p>
      <pre>
        <code>avapcli publish &lt;api&gt; &lt;environment&gt; &lt;deployment&gt;</code>
      </pre>
      <p>
        This starts the process of publishing the latest commit in the repository, in the selected environment. It is worth noting that the process passes the code from the latest commit through the specified unit test validation process.
      </p>
    </div>
  </Fragment>
);
export default AVAP_Cloud;
