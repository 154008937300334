import React, { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col, Container, Modal, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import Cookies from 'universal-cookie';

type CookieMessageProps = {
}

const CookieMessage:FunctionComponent<CookieMessageProps> = (props) => {
  const [ showModal, setShowModal ] = useState(false);
  
  const cookies = new Cookies();

  const handleClose = () => {  
    cookies.set('AcceptCookie', JSON.stringify({ Accept: true }), { path: '/' });
    setShowModal(!showModal)
  }


  const changeRoute = () => {
    this.context.router.push('/promospace/detail');
}




  useEffect(() =>{
    if (!cookies.get('AcceptCookie')){
      setShowModal(true);
    }
  }, [])

  return (
    <Modal dialogClassName="mw-100 fixed-bottom my-0 pr-0 cookie-message-dialog"
      show={showModal}
      onHide={handleClose}
      backdrop="static"
    >
      <Modal.Body>
        <Row>
          <Col md={{ span:5, offset:2 }}>
            <span>
            We use cookies to improve your experience.
            </span>
          </Col>
          <Col md="2" className="my-auto">
            <button className="obex-btn custom-button-green btn-block textBold" onClick={handleClose}>Accept Cookies</button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default CookieMessage;