import React, { FunctionComponent, useState, useEffect } from 'react';
import {  Modal, Button, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SessionService from '../../services/SessionsService';


type addTeamProps = {
  show: boolean,
  handleClose: () => void
}

const AddOrganizationModal:FunctionComponent<addTeamProps> = (props: addTeamProps) => {

  const [cardNumber, setCardNumber] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [notFocused, setFocused] = useState(true);
  const [errorNombre, setErrorNombre] = useState(false);

  const [validName, setValidName ] = useState(false);
  const [validTarget, setValidTarget ] = useState(false);
  const [validEndpoint, setValidEndpoint ] = useState(false);
  // const [errorNombre, setErrorNombre] = useState(false);
  const [errorTarget, setErrorTarget] = useState(false);
  const [errorEndpoint, setErrorEndpoint] = useState(false);



  const changeCardNumber = (e) => {
    const newCardNumber = e.target.value; // remove hyphens
    setCardNumber(newCardNumber);
    //if (newCardNumber.length>3 && cardholder.length>3 && errorNombre == false) setSubmitDisabled(false);
    validateTarget(newCardNumber)
  
  }

  const changeCardholder = (e) => {
    const newCardholder = e.target.value;
    setCardholder(newCardholder);

    validateName(newCardholder);
    //if (newCardholder.length>3 && cardNumber.length>3) setSubmitDisabled(false);
    

  }


  const validateName = (email) => {
    const emailFormat = /[a-zA-Z]{1,18}$/;
    if (email.match(emailFormat)) {
      setValidName(true); 
      setErrorNombre(false)
      
      if (validTarget) {
        setSubmitDisabled(false);
        //setErrorNombre(true)
      }
    }
      else {
        setValidName(false);
        setSubmitDisabled(true);
        setErrorNombre(true);
      }
  }


  const validateTarget = (email) => {
    const emailFormat = /[a-zA-Z]{1,18}$/;
    if (email.match(emailFormat)) {
      setValidTarget(true); 
      setErrorTarget(false)
      
      if (validName) {
        setSubmitDisabled(false);
        //setErrorTarget(true)
      }
    }
      else {
        setValidTarget(false);
        setSubmitDisabled(true);
        setErrorTarget(true);
      }
  }



  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    setError('');
    const result = 
    await ObexRequestHandler.get(
      `/create_organization?organization_name=${cardholder}&organization_description=${cardNumber}`, 
      {}, true);
    const { success, data, message } = result;

    SessionService.ORGANIZATIONS = null

    if (success){
      setLoading(false);
      setSuccess(data);
        setTimeout(function() { 
          setSuccess('');
          setSubmitDisabled(true);
          props.handleClose();
          }.bind(this), 1000)
    } else {
      setError(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (notFocused) {
      document.getElementById('entrada-organizacion').focus();
      setFocused(false);
    }
    {/*}
    if (cardNumber && cardholder){
      setSubmitDisabled(false);
    }*/}
  })
  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={props.show} 
      onHide={props.handleClose}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Col md="12" className="addons-title">
            <span>Create a new organization</span>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="10" className="mx-auto">
            <Form className="obex-form payment-method-form" onSubmit={handleSubmit}>
              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  Organization Name
                </Form.Label>
                <Form.Control isValid={!errorNombre && cardholder.length>0} isInvalid={errorNombre} maxLength={18} 
                  id="entrada-organizacion" 
                  required 
                  type="text" 
                  placeholder="Name" 
                  onChange={changeCardholder}/>
                </Form.Row>
              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  Organization Description
                </Form.Label>
                  <Form.Control 
                    isValid={!errorTarget && cardNumber.length>0} isInvalid={errorTarget} 
                    required type="text" 
                    maxLength={100} 
                    placeholder="Description" 
                    onChange={changeCardNumber}/>
                  </Form.Row>
                  
              <Form.Row className="my-3">
                <Col md="4" className="ml-auto">
                  <button disabled={submitDisabled} type="submit" className="btn-block textBold custom-button-blue">
                      Create {success && <FontAwesomeIcon icon={faCheck}/>}
                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                  </button>
                </Col>
              </Form.Row>
            </Form>
            {error && <Alert variant="danger">
              {error}
            </Alert>}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default AddOrganizationModal;