import React, { FunctionComponent, Component, useEffect, useState } from 'react';
import BaseLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Table, Button, Spinner, Form, Accordion, Card, Image } from 'react-bootstrap';
import TransactionsMediaChart from './GraphComponents/TransactionsMediaChart';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPencilAlt, faPlusCircle, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import UpdateRateLimitModal from '../../components/Modals/updateRateLimitModal';
import AddTxSelModal from '../Modals/addTxModalSelect';
import { firstDayInThisMonth, lastDayInThisMonth } from '../../services/TimeService';
import dropdown from "../../assets/img/newDashboard/icono-flecha-expandir.png";

import Logo from '../../assets/img/ilustracion_noInfo2.png'
import SessionService from '../../services/SessionsService';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type TransactionsProps = {
};


const TransactionsContent: FunctionComponent<TransactionsProps> = props => {
  const menuActive = 'transactions';
  var puntose = '';
  var contadore = 0;
  const [loadingTable, setLoadingTable] = useState(false);
  const [editedProject, setEditedProject] = useState({ name: '', id: '', currentLimit: 0, currentConsumed: 0 });
  const [projects, setProjects] = useState([]);
  const [projectsMovements, setProjectMovements] = useState({});
  const [projectTx, setProjectTx] = useState([]);
  const [globalTx, setGlobalTx] = useState({ limit: 0, current: 0, rest: 0 });
  const [editing, setEditing] = useState(false);
  const [addingTx, setAddingTx] = useState(false);
  const [maxTX, setMaxTX] = useState(3100);
  const [actualTX, setActualTX] = useState(0);
  const [sublinks, setSublinks] = useState([
    {
      "path": `#`,
      "title": "Loading Data ",
    }
  ]);
  const [allLoaded, setAllLoaded] = useState(true);

  const [currentProject, setProject] = useState('');
  const [from, setFrom] = useState(firstDayInThisMonth());
  const [to, setTo] = useState(lastDayInThisMonth());
  const [activeKey, setActiveKey] = useState('0');
  const [cardsLoading, setCardsLoading] = useState(true);

  const handleAddingTx = () => setAddingTx(true);

  const getInfo = async () => {
    try {
      setLoadingTable(true);

      var interval = setInterval(() => {
        if (contadore < 5) {
          puntose = puntose + "."
          contadore++;

        } else {
          contadore = 0;
          puntose = '.';
        }

        setSublinks([{ "path": `#`, "title": "Loading Data " + puntose }]);

      }, 100);

      const projectsInfo = (await ObexRequestHandler.get(`/projects?organization_id=${SessionService.ORGANIZATION}`, {}, true)).data || [];
      const movements = (await ObexRequestHandler.get(`/movements?organization_id=${SessionService.ORGANIZATION}`, {}, true)).data || {};
      const consup = (await ObexRequestHandler.get(`/consumption?organization_id=${SessionService.ORGANIZATION}`, {}, true)).data || {};
      const tx = consup.tx;
      setMaxTX(consup.subscriptionInfo.max_month_rate_limit);
      setActualTX(tx.limit);
      setProjects(projectsInfo);
      // Seteamos por defecto el primer proyecto
      const firstProjectName = projectsInfo[0].name;
      setProject(firstProjectName);
      setAllLoaded(false);
      const movsPerProject = {};
      movements.movements.forEach(p => {
        const { project } = p;
        if (!Object.keys(movsPerProject).includes(project)) movsPerProject[project] = { txNumber: 0, movements: [] };
        movsPerProject[project].movements.push(p);
      })
      Object.keys(movsPerProject).forEach(k => movsPerProject[k].txNumber = movsPerProject[k].movements.length);
      setProjectMovements(movsPerProject);
      const { limit, current, rest } = tx;
      setGlobalTx({ limit, current, rest });
      const projectsTx = tx.projects;
      const sublinksRequests = [];
      const transactions = Object.keys(projectsTx).map(p => {
        const info = projectsTx[p];
        const { limit, current, rest, percent } = info;
        let description = '';
        try{
        description  = projectsInfo.find(pro => pro.name === p).description;
        } catch { description = ''}
        if (description!=''){
        sublinksRequests.push({
          "path": p,
          "title": description
        })}
        return {
          id: p,
          name: description,
          total: new Intl.NumberFormat('en-Us').format(limit),
          available: new Intl.NumberFormat('en-Us').format(rest),
          current: new Intl.NumberFormat('en-Us').format(current),
          average: `${percent || 0}%`,
        }
      })
      clearInterval(interval);
      setSublinks(sublinksRequests);
      // console.log(transactions);

      setProjectTx(transactions);
    } catch (error) {

      setSublinks([


      ]);

      setAllLoaded(false);
      console.error('ERROR GETTING INFO ', error)

    }
    clearInterval(interval);
    setLoadingTable(false);
    setCardsLoading(false);
  }

  const handleEdit = () => {
    setEditing(!editing);
  }

  const editPencil = (project) => {

    const editedProject = projectTx.find(p => p.id === project);
    setEditedProject({
      name: editedProject.name,
      id: editedProject.id,
      currentLimit: editedProject.total,
      currentConsumed: editedProject.current
    });

    handleEdit();
  }

  const projectRows = projectTx.map(p => (
    <Row className="border-bottom py-2">
      <Col>{p.name}</Col>
      <Col>{p.id}</Col>
      <Col className="text-center">{p.total}</Col>
      <Col className="text-center">{p.available}</Col>
      <Col className="text-center">{p.average}</Col>

      <Col className="text-right"><FontAwesomeIcon onClick={() => editPencil(p.id)} cursor="pointer" icon={faPencilAlt} size="1x" className="mr-1" /></Col>
    </Row>
  ))

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const toggleAccordion = (key: string) => {
    setActiveKey(activeKey === key ? null : key);

   // Actualizar el proyecto seleccionado cuando se abre un acordeón
   if (activeKey !== key) {
    const selectedProject = projects[key]; // Obtener el proyecto correspondiente al acordeón abierto
    setProject(selectedProject.name); // Establecer el proyecto como seleccionado
  }
  };

  const handleSelectProject = (e) => {
    setProject(e.target.value); // Actualiza el estado del proyecto seleccionado
  };

  const generateCards = projectTx.map((p, index) => {
    // debugger
    // setCardsLoading(false);
    return (
      <Accordion key={index} activeKey={activeKey} onSelect={(key) => setActiveKey(key)} className="mb-4">
        <Card>
          <Card.Header>

            <div className='d-flex justify-content-between' style={{ paddingLeft: "4rem", paddingRight: "4rem" }}>
              <div className='text-bold' style={{ fontSize: "1.1rem" }} >
                {p.name}
              </div>

              <div onClick={() => toggleAccordion(String(index))}>
              {activeKey === String(index) ? (
                <FontAwesomeIcon icon={faChevronUp} cursor="pointer"/>
              ) : (
                <FontAwesomeIcon icon={faChevronDown} cursor="pointer"/>
              )}
              </div>
            </div>

          </Card.Header>
          <Accordion.Collapse eventKey={String(index)}>
            <Card.Body className='pt-0'>
              <div className='d-flex flex-column' style={{ paddingLeft: "4rem", paddingRight: "4rem" }}>
                <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                  <div className='text-dark text-bold'>Project ID:</div>
                  <div>{p.id}</div>
                </div>

                <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                  <div className='text-dark text-bold'>Total Req:</div>
                  <div>{p.total}</div>
                </div>

                <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                  <div className='text-dark text-bold'>Available Req:</div>
                  <div>{p.available}</div>
                </div>

                <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                  <div className='text-dark text-bold'>Average Request:</div>
                  <div>{p.average}</div>
                </div>

                <div className='d-flex flex-row justify-content-end  py-3'>
                  <div className='mr-2'>
                    Edit
                  </div>
                  <div onClick={() => editPencil(p.id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={"15px"} height={"15px"} fill="#1F78B4" style={{ marginBottom: "6px", cursor: "pointer" }} viewBox="0 0 512 512"><path d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z" /></svg>
                  </div>
                </div>


                <div className='d-flex row'>
                  <div className='col-6'>
                    <Form.Control type="date" className='newStyleInputsDate' value={from} onChange={(e) => setFrom(e.target.value)} />
                  </div>
                  <div className='col-6'>
                    <Form.Control type="date" className='newStyleInputsDate' value={to} onChange={(e) => setTo(e.target.value)} >
                      </Form.Control>
                  </div>
                  <div className='col-6 pt-3'>
                    <Form.Control as="select" className='newStyleInputsSelect' onChange={handleSelectProject} custom value={currentProject}>
                    {projects.map((value) => (
                      <option key={value.name} value={value.name}>
                        {value.name}
                      </option>
                    
                    ))}
                    </Form.Control>
                  </div>
                  <div className='col-6 pt-3'>
                    <Button disabled={allLoaded} className="custom-button-blue w-100 textBold"  onClick={handleAddingTx}> Add More</Button>
                  </div>
                </div>

              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      // <Row className="border-bottom py-2">
      //   <Col>{p.name}</Col>
      //   <Col>{p.id}</Col>
      //   <Col className="text-center">{p.total}</Col>
      //   <Col className="text-center">{p.available}</Col>
      //    <Col className="text-center">{p.average}</Col>

      //   <Col className="text-right"><FontAwesomeIcon onClick={() => editPencil(p.id)} cursor="pointer" icon={faPencilAlt} size="1x" className="mr-1"/></Col>
      // </Row>

    )
  })


  const loadingSpinner = <Row className="my-3"><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;
  const noProjectsText = <Row>
    <Col className="mx-auto text-center">
      <span className="d-block">
        You have not yet registered any project in your account.
      </span>
      <span className="d-block">
        Press add to register and configure your projects.
      </span>
    </Col>
  </Row>;

  const whenClosing = () => {
    setAddingTx(false);
  }

  useEffect(() => {
    getInfo();
  }, []);

  const optionProjects = projects.map(value => <option value={value.name}>{value.name}</option>);

  return (
    <>
      <BrowserView>
    <BaseLayout sider={DashRouters} active={menuActive} sublinks={sublinks}>


      <Row>
        <Col md={9}>
          <h1>Requests</h1>
        </Col>
        {/* <Col md={3}>
          <Button disabled={allLoaded} className="btn-add obex-btn btn-add-green btn-block px-4" onClick={handleAddingTx}><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1"/> Add More</Button>
        </Col> */}
      </Row>
      {(projects.length == 0 && cardsLoading == false) &&
      <div>
      <><Row className="justify-content-center my-4">
          <Col xs="6" style={{display:'flex', justifyContent:'center'}}>
            <Image style={{width:'50%', marginTop:'60px', marginRight:'0'}} src={Logo} fluid />
          </Col>
          </Row>
          <Col style={{textAlign:'center', marginBottom:'60px', marginRight:'0'}}>
          <Row style={{marginRight:'0'}}><Col><h5>You don’t have data to show</h5></Col></Row>
          <Row style={{marginRight:'0'}}><Col>When you have data to show, you’ll find here.</Col></Row>
         {/*} <Row style={{marginRight:'293px'}}><Col>want to track, you'll find here. </Col></Row>*/}
          </Col>
        </>
        </div>
}
      <Row>
        <Col md={6} className="mh-100 overflow-auto" style={{ height: "75vh" }}>

          {/* <div>1</div>
        <div>2</div> */}
          {cardsLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            generateCards
          )}
        </Col>

        <Col md={6}>
          <TransactionsMediaChart from={from} to={to} project={currentProject} />
        </Col>

        {/*
        Old version
         <Col className="obex-table table-request">
          <Row className="table-thead py-2" style={{ 
              fontFamily: 'Regular Intro Bold',
              textAlign:'start',
              backgroundColor:'transparent',
              color:'black',
              borderWidth:'1px',
              borderRadius:'15px',
              borderStyle:'hidden'
             }}>
            <Col>Name</Col>
            <Col>Project ID</Col>
            <Col>Total Req</Col>
            <Col>Available Req</Col>
            <Col>Average Req</Col>
            <Col></Col>
          </Row>
          {!loadingTable && projectRows}
        </Col> */}
      </Row>


      
{/*       Old version
      {loadingTable && loadingSpinner}
      {(!loadingTable && projects.length <= 0) && noProjectsText}
      <Row>
        <Col md={3} className="my-3">
          <Button disabled={allLoaded} className="btn-add obex-btn btn-add-green btn-block px-4" onClick={handleAddingTx}><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1" /> Add More</Button>
        </Col>
      </Row>

      <Row>
       
        <Col>
          <Form.Control type="date" value={from} onChange={(e) => setFrom(e.target.value)} />
        </Col>
        <Col>
          <Form.Control type="date" value={to} onChange={(e) => setTo(e.target.value)} />
        </Col>
        <Col>
          
          <Form.Control as="select" onChange={(e) => setProject(e.target.value)} custom defaultValue={currentProject}>
            {optionProjects}
          </Form.Control>
        </Col>
      </Row>



      <Row className="stats-grafic">
        <Col>
          <TransactionsMediaChart from={from} to={to} project={currentProject} />
        </Col>
      </Row> */}
      <UpdateRateLimitModal
        project_name={editedProject.name}
        project_id={editedProject.id}
        shown={editing}
        currentLimit={editedProject.currentLimit}
        maxAllowedTx={globalTx.rest}
        currentConsumed={editedProject.currentConsumed}
        onCloseAction={handleEdit}
        onSuccessCreation={async () => {
          setEditing(false);
          await getInfo();
        }}
      />
      {addingTx && <AddTxSelModal shown={addingTx} onSuccess={whenClosing} onCloseAction={whenClosing} maxMonthLimit={maxTX} actualMonthLimit={actualTX} currentSuscription={0}/>} 
    </BaseLayout>
    </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
    )
}

export default TransactionsContent;