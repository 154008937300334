import React, { Fragment } from 'react';
import './assets/scss/App.scss'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { 
  RootRoute,
  PrivateRoute,
  publicRoutes,
  privateRoutes,
  adminRoutes
} from './configs/appRoutes';
import { withTranslation } from 'react-i18next';    

function App() {

//  useEffect(() => {
//
//  }, []);

  return (
    <Fragment>
      <Router>
        <Switch>        
          {/* 
            * El bloque de código de abajo se encarga de recorrer una a una las rutas definidas en
            * appRoutes.tsx de manera que cada vez que carguemos una ruta en ese fichero se hará de manera dinámica
            * sin "ensuciar" este ya que es el principal.
          */}
          {publicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
              render={route.render}
            />
          ))}
          {/* 
            * Este igual pero con las privadas
          */}
          {privateRoutes.map((route, index) => (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              component={route.component}
              render={route.render}
            />
          ))}
          {adminRoutes.map((route, index) => (
          <RootRoute
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
            render={route.render}
          />
          ))}
        </Switch>
      </Router>
    </Fragment>
  );
}

export default withTranslation('common')(App); 