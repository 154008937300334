import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { CodeOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const LoginSample = () => (
  <Fragment>
            <Typography>

      <Title level={2}>1. User Authentication with Login and Password</Title>
      <Paragraph>
        In this example, we demonstrate how to authenticate a user by checking the login credentials (username and password) against the database. The user provides their credentials via the query string, and the system verifies them using encrypted password validation.
      </Paragraph>

      <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
        <pre>
          <code>
            {`// Recover from query string and assign its value to local variables user and password
addParam(user, user)
addParam(password, password)

// Recover any active row in the 'users' database where the 'user' column is equal to the query string parameter
ormAccessSelect(*, users, \`user = \${user} and active = true\`, query_result)

// Check if the result is not empty
getListLen(query_result, concordances)

// If the result contains a row
if (concordances, 0, '>') {

    /* Recover user data from the database query result */
    // First, get the first item from the list recovered from the database query
    itemFromList(concordances, 0, user_data)
    // Then, recover the user id from the 'id' column
    variableFromJSON(user_data, id, user_id)

    // Recover the ciphered password for the user
    ormAccessSelect(*, passwords, \`userid = \${user_id}\`, ciphered_password)

    // Encode the received password from the query string
    encodeSHA256(password, proposed_password)

    // Check if both the real ciphered password stored in the database and the encoded password from the query string are equal
    if (proposed_password, ciphered_password, '=') {

        // Password is correct, return success message
        addResult(\`Login success for user \${user}\`)

    } else {
        // Password is incorrect, return an error message
        addResult(\`Incorrect password for user \${user}\`)
    }

} else {
    // Return user not found message
    addResult(\`User \${user} not found\`)
}`}
          </code>
        </pre>
      </div>

    </Typography>
  </Fragment>
);
export default LoginSample;
