import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, Button, Form, FormControl, Card, NavDropdown } from 'react-bootstrap';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/Disclaimer';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import '../../components/functionalComponents/Mobile_Layout/MobileLayout.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'
import virtualidadMb from '../../assets/img/01_ilustracion_virtualidad.svg'
import inteligenciaMb from '../../assets/img/01_ilustracion_AILenguage.svg'

import lenguajeMb from '../../assets/img/01_ilustracion_HightLevel.svg'

import lenguaje from '../../assets/img/01_ilustracion_HightLevel@2x.png'
import virtualidad from '../../assets/img/01_ilustracion_virtualidad@2x.png'
import inteligencia from '../../assets/img/01_ilustracion_AILenguage@2x.png'

import logo_framework_titulo from '../../assets/img/AVAP_Framework_Titulo.png'
import cabecera from '../../assets/img/cabecera.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';

import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo_2.png'//'../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_ga_activo_2.png'
import botonVspActivo from '../../assets/img/boton_vs_activo_2.png'


import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds_2.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_ga_2.png'
import botonVs from '../../assets/img/boton_vs_2.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'
import productividadMb from '../../assets/img/2_ilustracion_productivity.svg'
import rentabilidadMb from '../../assets/img/2_ilustracion_higherProfitability.svg'
import focoproyectosMb from '../../assets/img/2_ilustracion_maximize.svg'
import tiemposlanzamientoMb from '../../assets/img/2-ilustracion_fasterTime.svg'

import productividad from '../../assets/img/2_ilustracion_productivity@2x.png' 
import rentabilidad from '../../assets/img/2_ilustracion_higherProfitability@2x.png' 
import focoproyectos from '../../assets/img/2_ilustracion_maximize@2x.png' 
import tiemposlanzamiento from '../../assets/img/2-ilustracion_fasterTime@2x.png' 

import collaborative2 from '../../assets/img/collaborative2.png' 

import icon_collaborative from '../../assets/img/iconCollaborative.png' 
import connect from '../../assets/img/connect.png' 
import avapconect from '../../assets/img/AVAPConnect.png' 
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import { relative } from 'path';
import MobileLayout from '../../components/functionalComponents/Mobile_Layout/MobileLayout';

type DashProps = {
    sider: any
};



const NewLanding = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [ avapBut, setavapBut ] = useState(true);
    const [ vsBut, setvsBut ] = useState(false);
    const [ gBut, setgBut ] = useState(false);
    const [ dsBut, setdsBut ] = useState(false);
    const [ cloudBut, setcloudBut ] = useState(false);
    const [ CnBut, setcnBut ] = useState(false);

    const [ menu1, setmenu1 ] = useState(false);
    const [ menu2, setmenu2 ] = useState(false);
    const [ menu3, setmenu3 ] = useState(false);
    const [ menu4, setmenu4 ] = useState(false);
    const [ menu5, setmenu5 ] = useState(false);


    const cleanAll = ()=>{
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = ()=>{
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com'){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });}

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario
    
        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
      };



    return (
        <>
            <BrowserView>

        <div className='d-flex flex-column' style={{ backgroundColor:'#fafafa' }}>

        <Navbar className='navbar' style={{ position:'fixed', width:'100%' }}>
            <Container className="">
                <Navbar.Brand href="/framework" style={{}}>
                    <Image src={logo_framework} style={{ marginBottom: '0px', width:'317px'}} height="33px" width="317px" alt="Logo" />
                </Navbar.Brand>
                <Nav style={{ width: '80%' }}>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu1(!menu1)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Products <div hidden={!menu1} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu2(!menu2)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Services <div hidden={!menu2} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{history.push('/avapid');cleanMenuAll()}} style={{ marginRight:'10px', marginLeft:'10px' }}>AVAP ID <div hidden={!menu3} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu4(!menu4)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Resources<div hidden={!menu4} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu5(!menu5)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Company  <div hidden={!menu5} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                </Nav>
                <button 
                    style={{ padding:'0px', width:'350px', height:'30px' }}
                    className='custom-button-green textBold'
                    onClick={()=>{history.push('/login')}}
                >
                        Sign in with your AVAP ID
                </button>
            </Container>

{ menu1 &&
            <><div style={{ 
                width: '80%',
                 marginLeft: '10%', 
                 marginRight: 'auto', 
                 marginTop:'52px', 
                 position: 'absolute', 
                 height:'2px', 
                 backgroundColor:'#ebebeb'
                }}></div>
                <div className='fade-in-image'  style={{
                        marginTop: '374px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Build Virtual APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/avap')}}>
                                    AVAP (Advanced Virtual API Programming)
                                </div>
                                <div onClick = {()=>{history.push('/vsc-extensions')}}
                                	style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP API Extensions for Microsoft Visual Code
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/devstudio')}}>
                                    AVAP Dev Studio
                                </div>



                            </div>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Test & Deliver APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div onClick = {()=>{history.push('/version-control')}}  
                                    style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP API Version Control
                                </div>
                                <div onClick = {()=>{history.push('/api-publisher')}}  
                                    style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP API Publisher
                                </div>

                            </div>


                            <div style={{
                                width: '33%'
                            }}>

                                
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Run APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

{/*}
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Virtual Server
                                </div>
                                */}



                            {/*}    <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Community Edition
                                </div>*/}
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/avs')}}>
                                    AVAP Virtual Server
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/avap')}}>
                                    AVAP Interpreter & Language Server
                                </div>
                            </div>



                        </div>

                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Power Up APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>


                                <div onClick={()=>{history.push('/avap-marketplace')}}  
                                    style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Marketplace
                                </div>
                                <div onClick={()=>{history.push('/avap-connectors')}}  
                                    style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer',
                                    marginBottom: '34px',
                                }}
                                
                                >
                                    AVAP Connectors
                                </div>
                                {/*}    <div style={{
                paddingLeft:'20px',
                paddingTop:'10px',
                color:'#969696',
                marginBottom:'34px',
                cursor:'pointer'
            }}>
            AVAP Virtual Machine
        </div>*/}



                            </div>
                            {/*}
                    <div style={{
                        width:'33%'
                    }}>
                        <div className='landingFont3'
                            style={{
                                paddingLeft:'20px',
                                paddingTop:'30px'
                            }}
                        >
                            Extend APIs
                        </div>
                        <div style={{
                            height:'1px',
                            backgroundColor:'#d28e22',
                            marginLeft:'20px',
                            marginRight:'20px',
                                cursor:'pointer'
                        }}>
                        </div>
    
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                cursor:'pointer'
                            }}>
                            Plug Ins
                        </div>
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                marginBottom:'34px',
                                cursor:'pointer'
                            }}>
                            Connectors
                        </div>
    
    
                    </div>
    
    
                */}



                        </div>


                    </div></>
}

{menu2 &&
    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '215px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Professional
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}
                                    onClick={()=>{history.push('/collaborative')}}
                                    >
                                        AVAP Collaborative Network
                                    </div>
                                                                    	<div onClick = {()=>{history.push('/cloud')}}
                                		style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }}>
                                        AVAP Cloud Professional
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Monolith to Microservices
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Power OpenAI Applications
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer',
            marginBottom:'35px'
        }}>
        Mobile Application API Development
    </div>
*/}


                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Business
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div onClick = {()=>{history.push('/cloud')}}
                                        style = {{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        AVAP Cloud Business
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Secure and Govern APIs
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Gateway for Istio
    </div>
*/}
                                </div>


                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Corporate
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div onClick = {()=>{history.push('/cloud')}}
                                        style = {{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        AVAP Cloud Enterprise
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Decentralized Load Balancing
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Zero Trust Security
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Observability
    </div>
    */}
                                </div>

                            </div>
                        </div></>
}

{menu3 &&

                    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '148px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*} Partner Ecosystem*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        display: 'none',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>



                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*Find a Partner*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        display: 'none',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>



                                </div>


                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*Become a partner*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        display: 'none',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>


                                </div>
                            </div>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*Partner Login*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: 'transparent',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer',
                                        marginBottom: '35px'
                                    }}>
                                    </div>
                                </div>
                            </div>
                        </div></>
}

{ menu4 &&
    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '216px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Developer Support
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}
                                    onClick={()=>{history.push('/default')}}
                                    >
                                        AVAP Developer Portal
                                    </div>
                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }}
                                    onClick={()=>{window.open('https://speed-computing-1656.my.site.com/communities/s/','_blank')}}
                                    >
                                        AVAP Communities
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Case Studies
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Blog
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Content Library
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Learning Center
    </div>

*/}

                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Knowledge Center
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}
                                    onClick={()=>{window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US')}}
                                    >
                                        AVAP Alexandria
                                    </div>
                                    <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => { window.open('http://blog.avapframework.com') }}
                                            >
                                                AVAP Blog
                                            </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Mesh
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP AI Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Insomnia
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Plugin Hub
    </div>
*/}
                                </div>
                                <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    API Products
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>


                                <div onClick={()=>{history.push('/avap-marketplace')}} 
                                    style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Marketplace
                                </div>
                            </div>
                                {/*}
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Community
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                About Us
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Join the Community
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Livestreams & Events
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Champions
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Discuss
            </div>
        </div>
    
        </div>
    
        <div style={{
            width:'100%',
            display:'flex'
            }}>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Developer
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Contributor Program
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer',
                    marginBottom:'34px',
                }}>
                GitHub
            </div>
    
        </div>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px'
                }}
            >
                Academy
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Certification
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    marginBottom:'34px',
                    cursor:'pointer'
                }}>
                Education
            </div>
        </div> */}
                            </div>

                        </div></>
}
{ menu5 &&
    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '246px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%',
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Company
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer',
                                    }}>
                                    </div>

                                    <div onClick={()=>{history.push('/about')}} 
                                        style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        About Us
                                    </div>
                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }} onClick={()=>{window.open('mailto: info@101obex.com', '_self')}}> 
                                        Contact Us
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Press Room
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Investors
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Contact Us
    </div>
*/}

                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Legal
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div onClick={()=>{window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US')}} 
                                        style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        Terms & Conditions AVAP Collaborative Networks
                                    </div>
                                    <div onClick={()=>{window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-cloud-professional-business-terms-conditions-MCOYQYTA5VQNDZDAS5UGXSU3GEV4?language=en_US')}} 
                                        style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        Terms & Conditions AVAP Cloud
                                    </div>
                                     <div onClick={()=>{window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/privacy-policy-MCSYZARSDENBDAVITQIIO6OWFYLI?language=en_US') }}
                                        style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom:'34px'
                                    }}>
                                        Privacy Policy
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Documentation
    </div>
    

</div>


<div style={{
    width:'33%'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px',
            cursor:'pointer'
        }}
    >
        Events
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Summit by AVAP
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Upcomming Events
    </div>
    
</div>

</div>

<div style={{
    width:'100%',
    display:'flex'
    }}>

<div style={{
    width:'33%'
}}>
    

</div>

<div style={{
    width:'33%'
}}>

</div>

<div style={{
    width:'33%',
    marginTop:'-100px',
    marginBottom:'35px'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px'
        }}
    >
        Security
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Trust and Compilance
    </div>
    
    */}

                                </div>

                            </div>
                        </div></>
}

        </Navbar>



      <div onClick={()=>{cleanMenuAll()}}>

            <div onClick={()=>{cleanMenuAll()}} className="d-flex flex-column align-items-center">


                <div className='d-flex flex-column  min-vh-100 align-items-center'>

                <Image src={cabecera} style={{ marginTop:'40px' }} width="" alt="Logo" />
                <Image src={logo_framework_titulo} style={{ 
                    marginTop:'100px', 
                    position:'absolute', 
                    width:'600px'
                    }} width="" alt="Logo" />

                <div
                    className='landingFont0'
                    style={{
                        marginTop:'220px', 
                        position:'absolute', 
                        color:'black'
                    }}                
                >The future of API technology</div>

                
                <div
                    className='landingFont0'
                    style={{
                        marginTop:'280px', 
                        position:'absolute', 
                        color:'black'
                    }}                
                ><span className='textBold'>Complete</span> lifecycle <span className='textBold'>control.</span></div>

                <div
                    className='landingFont3'
                    style={{
                        marginTop:'360px', 
                        position:'absolute', 
                        color:'black'
                    }}                
                >Virtuality and Artificial Intelligence at its CORE</div>
                
                </div>
            </div>

            <div style={{
                backgroundColor: '#fafafa',
                height: '200px',
                textAlign:'center',
                paddingTop:'30px'
            }}>
                <div className='landingFont2 textBold'>
                Ready to transform API development?
                </div>
                <div className='landingFont3b textBold'>
                Register at AVAP ID
                </div>
                <button 
                onClick={()=>{history.push('/Register')}}
                    className='custom-button-green textBold'
                    style={{ marginTop:'20px' }}
                >
                    Create my AVAP ID
                </button>

            </div>
            <div style={{
                backgroundColor: '#ffffff',
                height: '920px',
                textAlign:'center',
                paddingTop:'30px'
            }}>
                <div 
                    className='landingFont1'
                >
                    The total API solution
                </div>
                <div 
                    className='landingFont1'
                >
                   
                </div>
                <div 
                    className='landingFont3'
                    style={{ 
                        marginTop:'20px',
                        width:'33%', 
                        marginLeft:'auto', 
                        marginRight:'auto' 
                    }}
                >
                    Next-gen technology with AI embedded in its DNA, offering scalability and profitability through its Virtuality.
                </div>


                    <div 
                        id='bloque de columnas'
                        style={{
                            width:'50%',
                            marginLeft:'auto',
                            marginRight:'auto',
                            paddingTop:'80px',
                        }}
                    >
                        <div style={{ display:'flex' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={lenguaje} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>High-Level Programming Language</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Its primary focus is on API creation, implementing fundamental processes like language commands and managing input and output data structures as native data structures.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={virtualidad} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>Virtuality</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>AVAP’s virtuality comes from its dynamic definitions hosted in the cloud. Therefore, during development, the language server only reads commands, retrieves these definitions, and composes a Virtual Code that the virtual machine (parser) executes.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={inteligencia} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>Intelligent Language                            </div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Artificial intelligence at its core: the developer presents a problem and obtains the solution through specific commands and natural language.</div>
                            </div>
                        </div>

                        {/*}
                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={lenguaje} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Lenguaje Alto Nivel</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Foco en las necesidades de creación de APIs, implementando procesos básicos como comandos del lenguaje y gestionando las estructuras de datos de entrada y salida como estructuras de datos nativas.</div>
                        </div>

                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={virtualidad} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Virtualidad</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>El atributo de virtualidad del lenguaje AVAP se da ya que sus definiciones dinámicas se alojan en la nube. Por lo que, para el desarrollo, el servidor de lenguaje únicamente lee los comandos, recupera estas definiciones y compone un Virtual Code que ejecuta la máquina virtual (parseador)</div>
                        </div>


                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={inteligencia} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Inteligencia</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Al es parte de nuestro ADN, integrándose en todas las funciones y capacidades de AVAP, desde la asistencia, desarrollo y hasta el enrutamiento de servidores.</div>
                        </div>
*/}



                    </div>
                    

            </div>
            <div style={{ backgroundColor:'white', height:'80px' }}>

            </div>
            <div id='Productividad'
                style={{
                width:'100%',
                height: '100%',
                overflow:'hidden',
            }}>

            <Image src={cabecera2} style={{ position:'relative', marginTop:'00px' }} alt="Productividad" />
            <div style={{ marginTop:'0px', marginBottom:'0px', width:'50%', marginLeft:'auto', marginRight:'auto' }}>

                <div style={{ width:'50%', marginLeft:'0', marginRight:'auto', position:'absolute' }}>
                        
                        <div
                            style={{ 
                                textAlign:'start',
                                position:'absolute',
                                marginTop:'-90%',
                                width:'50%',
                                maxHeight:'80%',
                                lineHeight:'50px'
                            }} 
                            className='landingFont0'>
                            Enhance <span className='textBold'>productivity</span> and maximize <span className='textBold'>ROI</span> for every project
                            <div className='landingFont3' style={{ lineHeight:'28px', paddingTop:'15px' }}>
                            Efficient, intelligent, and agile API management for your team and operations.
                            </div>
                            {/*}
                            <button 
                                className='custom-button-green textBold'
                                style={{ marginTop:'20px' }}
                            >
                                Crear mi AVAP ID
                            </button>*/}
                        </div>
                        
                </div>

            </div>

            </div>

            <div id='Productos y servicios'
                style={{
                    backgroundColor: '#fafafa',
                    height: '100%',
                    textAlign:'center',
                    paddingTop:'30px'
                }}
            >
                <div className='landingFont1'>
                Products and Services
                </div>

                <div className='landingFont3' 
                    style={{ 
                        marginTop:'30px',
                        width:'30%',
                        marginLeft:'auto',
                        marginRight:'auto'
                     }}>
                    AVAP Technology: Complete Lifecycle Management for Your APIs – Creation, Publication, Utilization, and Maintenance.
                </div>

                <div 
                    style={{
                        marginLeft:'auto',
                        marginRight:'auto',
                        width:'60%',
                        backgroundColor:'white',
                        marginTop:'30px',
                        minHeight:'620px',
                        height:'620px',
                        borderRightColor:'#ebebeb',
                        borderRightStyle:'solid'
                    }}>
                <div>
                        <Image src={logo_framework_producto} style={{ marginBottom: '20px', marginTop: '20px' }} width="" alt="Logo" />
                </div>
                <div>
                <div style={{ backgroundColor:'#FFFFFF', width:'100%', height:'4px' }}></div>
                <Image onClick={()=>{cleanAll(); setavapBut(true)}} src={avapBut ? botonAvapActivo : botonAvap} style={{ cursor:'pointer', width:'16.2%', marginRight:'0.5%' }}/>
                <Image onClick={()=>{cleanAll(); setdsBut(true)}} src={dsBut ? botonDsActivo : botonDs} style={{ cursor:'pointer', width:'16.2%', marginRight:'0.5%' }}/>
                <Image onClick={()=>{cleanAll(); setvsBut(true)}} src={vsBut ? botonVspActivo : botonVs} style={{ cursor:'pointer', width:'16.2%', marginRight:'0.5%' }}/>
                <Image onClick={()=>{cleanAll(); setgBut(true)}} src={gBut ? botonGActivo : botonG} style={{ cursor:'pointer', width:'16.2%', marginRight:'0.5%' }}/>
                <Image onClick={()=>{cleanAll(); setcloudBut(true)}} src={cloudBut ? botonCloudActivo : botonCloud} style={{ cursor:'pointer', width:'16.2%', marginRight:'0.5%' }}/>
                <Image onClick={()=>{cleanAll(); setcnBut(true)}} src={CnBut ? botonCnpActivo : botonCnp } style={{ cursor:'pointer', width:'16.2%' }}/>

                </div>
                {
                    avapBut &&
                    <div style={{ justifyContent:'start', textAlign:'start' }}>
                    <div>
                        <Image src={AVAPLanguage} style={{ height:'56px', marginLeft: '80px', marginTop: '40px' }} />    
                    </div>
                    <div style={{paddingLeft:'80px', paddingRight:'80px', paddingTop:'20px' , height:'120px'}}>
                    AVAP (Advanced Virtual API Programming) is a high-level virtual programming language designed and created for API development. Its virtual nature enables continuous code improvement, making it highly scalable and cost-effective.
                    AVAP, including its language specifications, interpreter, and language server, marks the beginning of a new generation of programming languages where artificial intelligence becomes a fundamental part of the language, allowing developers to focus on problem-solving tasks.
                    </div>    
                    <div style={{ marginLeft:'80px', marginTop:'43px' }}>
                    <button onClick={()=>{ history.push('/avap')}} className='custom-button-orange-2'>Learn More</button>
                    </div>
                    </div>
                }
                                  {
                    dsBut &&
                    <div style={{ justifyContent:'start', textAlign:'start' }}>
                    <div>
                        <Image src={AVAPDS} style={{ height:'56px', marginLeft: '80px', marginTop: '40px' }} />    
                    </div>
                    <div style={{paddingLeft:'80px', paddingRight:'80px', paddingTop:'20px', height:'120px'}}>
                    AVAP Dev Studio is an IDE that offers complete project management, API development, code maintenance, and API publication capabilities. It enhances the development process with AI-powered coding through extensions, facilitating a collaborative experience through team management with co-coding and chat management features. 

AVAP Dev Studio allows direct execution of AVAP code through integration with a language server, maximizing the benefits of AVAP Framework with its powerful tools and functionalities.

                    </div>    
                    <div style={{ marginLeft:'80px', marginTop:'43px' }}>
                        <button onClick={()=>{ history.push('/devstudio')}} className='custom-button-green'>Learn More</button>
                    </div>
                    </div>
                }
                  {
                    vsBut &&
                    <div style={{ justifyContent:'start', textAlign:'start' }}>
                    <div>
                        <Image src={AVAPVirtualServer} style={{ height:'56px', marginLeft: '80px', marginTop: '40px' }} />    
                    </div>
                    <div style={{paddingLeft:'80px', paddingRight:'80px', paddingTop:'20px', height:'120px'}}>
                    Manage and publish your APIs in both development and production environments using AVS (AVAP Virtual Server). 

Integrated with the AVAP code repository in the cloud, AVS can manage multiple versions of each API code developed in AVAP, and conduct necessary tests for deployment and publication across diverse environments.
AVS is compatible with all language and API servers. 

                    </div>    
                    <div style={{ marginLeft:'80px', marginTop:'43px' }}>
                        <button onClick={()=>{ history.push('/avs')}} className='custom-button-red'>Learn More</button>
                    </div>
                    </div>
                }
                  {
                    gBut &&
                    <div style={{ justifyContent:'start', textAlign:'start' }}>
                    <div>
                        <Image src={AVAPGateway} style={{ height:'56px', marginLeft: '80px', marginTop: '40px' }} />    
                    </div>
                    <div style={{paddingLeft:'80px', paddingRight:'80px', paddingTop:'20px', height:'120px'}}>
                    More powerful than a proxy, it assesses consumption history, scenarios, and established conditions to swiftly and efficiently redirect to different servers. Lua programming drives its functionality.

AVAP Gateway Advanced is both universal and powerful, boasting compatibility with any language server.

                    </div>    
                    <div style={{ marginLeft:'80px', marginTop:'43px' }}>
                        <button onClick={()=>{ history.push('/gateway')}} className='custom-button-purple'>Learn More</button>
                    </div>
                    </div>
                }

                  {
                    cloudBut &&
                    <div style={{ justifyContent:'start', textAlign:'start' }}>
                    <div>
                        <Image src={AVAPCloudLogo} style={{ height:'56px', marginLeft: '80px', marginTop: '40px' }} />    
                    </div>
                    <div style={{paddingLeft:'80px', paddingRight:'80px', paddingTop:'20px', height:'120px'}}>
                    Cloud platform designed for users to leverage the full potential of AVAP Framework products and services. Gain flexible and scalable access to advanced technological solutions without the need for local infrastructure.

AVAP Cloud is a universal and powerful service that enhances your AVAP experience while also being compatible with other programming environments and language servers.

                    </div>    
                    <div style={{ marginLeft:'80px', marginTop:'43px' }}>
                        <button onClick={()=>{ history.push('/cloud')}} className='custom-button-blue'>Learn More</button>
                    </div>
                    </div>
                }
                  {
                    CnBut &&
                    <div style={{ justifyContent:'start', textAlign:'start' }}>
                    <div>
                        <Image src={AVAPCollaborative} style={{ height:'56px', marginLeft: '80px', marginTop: '40px' }} />    
                    </div>
                    <div style={{ paddingLeft:'80px', paddingRight:'80px', paddingTop:'20px', height:'120px' }}>
                    A peer-to-peer platform with exponential growth capacity, allowing developers to harness a complete deployment of AVS (AVAP Virtual Server) and AVAP Interpreter & Server Language for API development and publication. 

AVAP Collaborative Network can assess each API request for direct processing or redirection to another server with lower response time, utilizing dedicated channels for AVAP Virtual Code transmission to amplify virtual machine capacity across language servers.

                    </div>    
                    <div style={{ marginLeft:'80px', marginTop:'43px' }}>
                        <button onClick={()=>{ history.push('/collaborative')}} className='custom-button-emerald'>Learn More</button>
                    </div>
                    </div>
                }
                </div>
                    
                <div style={{
                    marginLeft:'auto',
                    marginRight:'auto',
                    width:'60%',
                    backgroundColor:'rgb(240, 240, 240)',
                    marginTop:'0px',
                    marginBottom:'80px',    
                    minHeight:'170px',
                    height:'170px',
                    borderRightColor:'#ebebeb',
                    borderRightStyle:'solid',
                    borderBottomColor:'#ebebeb',
                    borderBottomStyle:'solid'
            }}>
                <div className='landingFont3b textBold' style={{ paddingTop:'40px', textAlign:'end', marginRight:'80px' }}>Explore AVAP Framework,</div>
                <div className='landingFont3b textBold'style={{ textAlign:'end', marginRight:'80px' }}>register at AVAP ID</div>
                <div style={{ textAlign:'end', marginTop:'20px', marginRight:'80px' }}>
                    <button onClick={()=>{history.push('/Register')}} className='custom-button-green textBold'>Create my AVAP ID</button>
                </div>
            </div>

            </div>

            <div id='Virtualidad'
                style={{
                width:'100%',
                height: '100%',
                overflow:'hidden',
                marginTop:'0px',
                position:'relative',
                display:'flex'
            }}>

            <Image src={Virtualizacion} style={{ position:'relative', marginTop:'0px' }} alt="Virtualidad" />
            <div className='landingFont1' style={{ width:'25%', marginLeft:'50%', marginRight:'30%', textAlign:'end', position:'absolute', marginTop:'60px', zIndex:999999999, lineHeight:'50px' }}>Uncover the reality of API  <span className='textBold'>virtualization</span> </div>
            <div className='landingFont3' style={{ width:'25%', marginLeft:'50%', marginRight:'30%', textAlign:'end', position:'absolute', marginTop:'220px', zIndex:999999999 }}>AVAP’s language virtualization feature enables you to create virtual APIs right from the start.</div>
            </div>

            <div className='landingFont1' style={{backgroundColor:'f6f6f6', marginBottom:'80px', paddingTop:'80px', textAlign:'center', width:'50%', marginLeft:'25%'}}>
            AVAP Framework: A complete API solution that transforms processes and boosts efficiency.
            </div>

            <div id='VirtualidadDetalle'
                style={{
                width:'100%',
                height: '100%',
                overflow:'hidden',
                marginTop:'0px',
                position:'relative',
                
                justifyContent:'center'
            }}>
                <div style={{ width:'50%', marginLeft:'auto', marginRight:'auto', display:'flex' }}>
                    <div style={{ width:'50%', marginLeft:'auto', marginRight:'auto' }}>

                        <Image src={productividad} style={{ width:'80px', marginTop:'10px' }} alt="productividad" />
                        <div style={{ paddingTop:'20px', paddingLeft:'20px' }} className='landingFont3b textBold'>Boost productivity</div>
                        <div style={{ paddingTop:'20px', paddingLeft:'20px'}} className='landingFont4'>Let your development team focus on solutions while we handle the technical details.                        </div>

                    </div>
                    <div style={{ width:'50%', marginLeft:'auto', marginRight:'auto' }}>
                            
                        <Image src={focoproyectos} style={{ width:'80px', marginTop:'10px' }} alt="foco en proyectos" />
                        <div style={{ paddingTop:'20px', paddingLeft:'20px' }} className='landingFont3b textBold'>Maximize project focus</div>
                        <div style={{ paddingTop:'20px', paddingLeft:'20px' }} className='landingFont4'>Forget about deployment details, let us manage the logistics so you can concentrate on making your projects a success.</div>
                    </div>
                </div>
                <div style={{ width:'50%', marginLeft:'auto', marginRight:'auto', display:'flex' }}>
                    <div style={{ width:'50%', marginLeft:'auto', marginRight:'auto' }}>

                        <Image src={tiemposlanzamiento} style={{ width:'80px', marginTop:'60px' }} alt="productividad" />
                        <div style={{ paddingTop:'20px' }} className='landingFont3b textBold'>Faster time to market</div>
                        <div  style={{ paddingTop:'20px' }}className='landingFont4'>AVAP's language and comprehensive suite of products and services ensure a seamless and efficient API lifecycle.</div>
                    </div>

                    <div style={{ width:'50%', marginLeft:'auto', marginRight:'auto' }}>

                        <Image src={rentabilidad} style={{ width:'80px', marginTop:'60px' }} alt="productividad" />
                        <div style={{ paddingTop:'20px' }} className='landingFont3b textBold'>Higher profitability</div>
                        <div style={{ paddingTop:'20px' }} className='landingFont4'>Optimize development investment with specialized infrastructure and resources for API deployment and maintenance.</div>
                    </div>
                </div>

                <div style={{ marginBottom:'80px', justifyContent:'center', marginLeft:'auto', marginRight:'auto', textAlign:'center', marginTop:'80px' }}>
                <button onClick={()=>{window.open('mailto:sales@101obex.com', '_self')}} className='custom-button-blue textBold'>Contact Sales</button>
                </div>
            </div>

            <div id='Collaborative Network'
                style={{
                width:'100%',
                height: '100%',
                overflow:'hidden',
                marginTop:'0px',
                position:'relative',
                display:'flex',
                backgroundColor:'#fafafa'
            }}>

            <Image src={collaborative2} style={{ position:'relative', marginTop:'0px' }} alt="Virtualidad" />
            <div className='landingFont1' style={{ width: '60%', marginLeft: '20%', marginRight: '20%', textAlign:'center', position:'absolute', marginTop:'20px', zIndex:999999999 }}>
            <Image src={AVAPCollaborative} style={{ marginTop:'40px', width:'70%' }} alt="Virtualidad" />
            </div>
            <div className='landingFont1' style={{ width:'60%', marginLeft:'20%', marginRight:'20%', textAlign:'center', position:'absolute', marginTop:'150px', zIndex:999999999, lineHeight:'50px' }}><div>Welcome to the first democratic computing and API exploitation network.</div><div>AVAP Collaborative Network</div></div>
            </div>


            <div style={{ paddingBottom:'50px', backgroundColor:'#fafafa', paddingTop:'80px', marginLeft:'auto', marginRight:'auto', width:'60%', justifyContent:'center', textAlign:'center' }}>
            <div className='landingFont2 textBold'>Peer-to-Peer Collaborative Servers</div>
            {/*<Image src={icon_collaborative} style={{ marginTop:'0px' }} alt="Virtualidad" />*/}
            <div style={{ marginBottom:'00px', marginTop:'20px', paddingLeft:'20px', paddingRight:'20px' }} className='landingFont3b'>AVAP Collaborative Network is a cloud-based peer-to-peer service that enables developers to share servers, AVS (AVAP Virtual Servers), and AI&LS (AVAP Interpreter and Language Servers). It integrates AVAP language and infrastructure, optimizing code interpretation and processing through advanced evaluation of each request. </div>
                    
                    <div style={{ marginLeft:'0px', marginTop:'40px' }}>
                        <button 
                        onClick={()=>{history.push('/collaborative')}}
                        className='custom-button-emerald textBold'>Learn More</button>
                    </div>
            
                <div style={{ marginTop:'60px', backgroundColor:'white', 
                    borderBottomColor:'#ebebeb',
                    borderBottomStyle:'solid',
                    borderRightColor:'#ebebeb',
                    borderRightStyle:'solid'
                 }}>
                <Image src={connect} style={{ position:'relative', marginTop:'0px' }} alt="AVAP Connect" />
                <Image src={avapconect} style={{ width:'30%', position:'relative', marginTop:'40px' }} alt="AVAP Connect" />
                <div style={{ paddingTop:'20px', paddingLeft:'0%', paddingRight:'0%', lineHeight:'50px' }} className='landingFont1'>Learn about all the benefits of AVAP Connect</div>
                <div style={{ paddingTop:'10px' }} className='landingFont2 textBold'>Join the Early Access program</div>

                <div style={{ marginLeft:'10%', height:'2px', backgroundColor:'#ebebeb', width:'80%', marginTop:'50px', marginBottom:'50px' }}></div>

                <div className='landingFont4' style={{width:'60%', marginLeft:'20%', marginRight:'20%'}}>
                Become an AVAP Framework strategic partner and provide the ultimate comprehensive solution for APIs. AVAP Connect is our global program designed for business partners, offering a unified infrastructure with complete sales tools, support, services, and pre-sale opportunities to drive your business growth.
                </div>
                <div style={{ paddingBottom:'40px', marginLeft:'0px', marginTop:'30px' }}>
                        <button 
                        onClick={()=>{window.open('mailto:partners@101obex.com', '_self')}}
                        className='custom-button-purple textBold'>Join Now</button>
                    </div>
                </div>

            </div>



            <div>
                <FooterMenuAccess green={true}/>
            </div>

        </div>
        </div>

        </BrowserView>
            <MobileView>


                    <div className='d-flex flex-column' style={{ backgroundColor: '#fafafa' }}>

                    {/*}
                        <Navbar fixed='top' bg='dark' variant='dark' style={{ top: '0px', height: '118px', justifyContent: 'center' }} >
                            <Form inline style={{ marginTop: '40px', width: '90%' }}>
                                <FormControl type="text" className="mr-sm-2" style={{ borderRadius: '25px', backgroundColor: '#5E6367', border: 'none' }} />
                            </Form>
                        </Navbar>
                        */}
                    <Navbar id='frameworkNav' collapseOnSelect expand="lg" bg="light" variant="light" className='fixed-top' style={{ width: '100%' }}>
                        <Container>
                            <Navbar.Brand href="/framework">
                                <Image src={logo_framework} style={{ marginTop: '-10px', paddingLeft: '15px' }} alt="Logo" className="mb-1" />
                            </Navbar.Brand>
                            <Navbar.Toggle aria-controls="responsive-navbar-nav" className='mr-2' >
                                <div className='hamburguer' style={{ marginTop: '-10px' }}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Navbar.Toggle>
                            <Navbar.Collapse id="responsive-navbar-nav" style={{ backgroundColor: 'white', marginTop: '-20px' }} className="w-100 justify-content-between align-items-center">
                                <div className="d-flex flex-wrap">
                                    <Nav.Link onClick={() => { cleanMenuAll(); setmenu1(!menu1); }} className='hideLink'>
                                        Products
                                        <div hidden={!menu1} className="menu-highlight"></div>
                                    </Nav.Link>
                                    <NavDropdown title="Products" id="DropdownProducts" className='showLink'>

                                        <div className="section-header">Build Virtual APIs</div>
                                        <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                            AVAP (Advanced Virtual API Programming)
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/vsc-extensions'); }}>
                                            AVAP API Extensions for Microsoft Visual Code
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/devstudio'); }}>
                                            AVAP Dev Studio
                                        </div>


                                        <NavDropdown.Divider />
                                        <div className="section-header">Test & Deliver APIs</div>
                                        <div className="section-content" onClick={() => { history.push('/version-control'); }}>
                                            AVAP API Version Control
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/api-publisher'); }}>
                                            AVAP API Publisher
                                        </div>


                                        <NavDropdown.Divider />
                                        <div className="section-header">Run APIs</div>
                                        <div className="section-content" onClick={() => { history.push('/avs'); }}>
                                            AVAP Virtual Server
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                            AVAP Interpreter & Language Server
                                        </div>

                                    </NavDropdown>



                                    <Nav.Link onClick={() => { cleanMenuAll(); setmenu2(!menu2); }} className='hideLink'>
                                        Services
                                        <div hidden={!menu2} className="menu-highlight"></div>
                                    </Nav.Link>
                                    <NavDropdown title="Services" id="DropdownProducts" className='showLink'>
                                        <div className="section-header">Professional</div>
                                        <div className="section-content" onClick={() => { history.push('/collaborative'); }}>
                                            AVAP Collaborative Network
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                            AVAP Cloud Professional
                                        </div>
                                        <NavDropdown.Divider />
                                        <div className="section-header">Business</div>
                                        <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                            AVAP Cloud Business
                                        </div>


                                        <NavDropdown.Divider />
                                        <div className="section-header">Corporate</div>
                                        <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                            AVAP Cloud Enterprise
                                        </div>

                                    </NavDropdown>






                                    <Nav.Link onClick={() => { history.push('/avapid'); cleanMenuAll(); }}>
                                        AVAP ID
                                        <div hidden={!menu3} className="menu-highlight"></div>
                                    </Nav.Link>

                                    <Nav.Link onClick={() => { cleanMenuAll(); setmenu4(!menu4); }} className='hideLink'>
                                        Resources
                                        <div hidden={!menu4} className="menu-highlight"></div>
                                    </Nav.Link>
                                    <NavDropdown title="Resources" id="DropdownProducts" className='showLink'>
                                        <div className="section-header">Developer Support</div>
                                        {/* <div className="section-content" onClick={() => { history.push('/default'); }}>
                                        AVAP Developer Portal
                                    </div> */}
                                        <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'); }}>
                                            AVAP Communities
                                        </div>
                                        <NavDropdown.Divider />
                                        <div className="section-header">Knowledge Center</div>
                                        <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US'); }}>
                                            AVAP Alexandria
                                        </div>
                                        <div className="section-content" onClick={() => { window.open('http://blog.avapframework.com'); }}>
                                            AVAP Blog
                                        </div>
                                        <NavDropdown.Divider />
                                        {/* <div className="section-header">API Products</div>
                                   {/* <div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
                                        AVAP Marketplace
                                    </div>*/} 
                                    </NavDropdown>


                                    <Nav.Link onClick={() => { cleanMenuAll(); setmenu5(!menu5); }} className='hideLink'>
                                        Company
                                        <div hidden={!menu5} className="menu-highlight"></div>
                                    </Nav.Link>
                                    <NavDropdown title="Company" id="DropdownProducts" className='showLink'>
                                        <div className="section-content" onClick={() => { history.push('/about'); }}>
                                            About Us
                                        </div>
                                        <div className="section-content" onClick={() => { window.open('mailto:info@101obex.com', '_self'); }}>
                                            Contact Us
                                        </div>
                                    </NavDropdown>
                                </div>
                                {/*<button
                                    className="greenbutton"
                                    onClick={() => { history.push('/login'); }}
                                >
                                    Sign in with your AVAP ID
                                </button>*/}
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>


                    {menu1 && (
                        <>

                            <div className="menu-dropdown ">
                                <div className="d-flex">
                                    <div className="section">
                                        <div className="section-header">Build Virtual APIs</div>
                                        <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                            AVAP (Advanced Virtual API Programming)
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/vsc-extensions'); }}>
                                            AVAP API Extensions for Microsoft Visual Code
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/devstudio'); }}>
                                            AVAP Dev Studio
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">Test & Deliver APIs</div>
                                        <div className="section-content" onClick={() => { history.push('/version-control'); }}>
                                            AVAP API Version Control
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/api-publisher'); }}>
                                            AVAP API Publisher
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">Run APIs</div>
                                        <div className="section-content" onClick={() => { history.push('/avs'); }}>
                                            AVAP Virtual Server
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                            AVAP Interpreter & Language Server
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </>
                    )}

                    {menu2 && (
                        <>
                            <div className="menu-dropdown">
                                <div className="d-flex">
                                    <div className="section">
                                        <div className="section-header">Professional</div>
                                        <div className="section-content" onClick={() => { history.push('/collaborative'); }}>
                                            AVAP Collaborative Network
                                        </div>
                                        <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                            AVAP Cloud Professional
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">Business</div>
                                        <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                            AVAP Cloud Business
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">Corporate</div>
                                        <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                            AVAP Cloud Enterprise
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </>
                    )}

                    {menu3 && (
                        <>
                            <div className="menu-dropdown">
                                <div className="d-flex">
                                    <div className="section">
                                        <div className="section-header">Partner Ecosystem</div>
                                        <div className="section-content" onClick={() => { /* Acción para esta sección */ }}>
                                            Find a Partner
                                        </div>
                                        <div className="section-content" onClick={() => { /* Acción para esta sección */ }}>
                                            Become a Partner
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {menu4 && (
                        <>
                            <div className="menu-dropdown">
                                <div className="d-flex">
                                    <div className="section">
                                        <div className="section-header">Developer Support</div>
                                        <div className="section-content" onClick={() => { history.push('/default'); }}>
                                            AVAP Developer Portal
                                        </div>
                                        <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'); }}>
                                            AVAP Communities
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">Knowledge Center</div>
                                        <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US'); }}>
                                            AVAP Alexandria
                                        </div>
                                        <div className="section-content" onClick={() => { window.open('http://blog.avapframework.com'); }}>
                                            AVAP Blog
                                        </div>
                                    </div>
                                    <div className="section">
                                        <div className="section-header">API Products</div>
                                        {/*<div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
                                            AVAP Marketplace
                                        </div>*/}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    {menu5 && (
                        <>
                            <div className="menu-dropdown">
                                <div className="d-flex">
                                    <div className="section">
                                        <div className="section-header">Company</div>
                                        <div className="section-content" onClick={() => { history.push('/about'); }}>
                                            About Us
                                        </div>
                                        <div className="section-content" onClick={() => { window.open('mailto:info@101obex.com', '_self'); }}>
                                            Contact Us
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}

                    <div className=''>
                        <div style={{ position: 'relative', overflow: 'scroll', height: '100%' }} onClick={() => { cleanMenuAll() }}>

                            {/* <div onClick={() => { cleanMenuAll() }} className="d-flex flex-column align-items-center"> */}


                            <div id='First section' className='d-flex flex-column mobileSectionLimit align-items-center'>
                                <div style={{ width: '100%', height: '750px', objectFit: 'cover' }}>
                                    <Image id='firstImage' src={cabecera} style={{ marginTop: '40px', objectFit: 'cover' }} alt="Logo" height={'100%'} />

                                </div>

                                <div
                                    className='landingFont0Mobile landingFontColor'
                                    style={{
                                        marginTop: '20%',
                                        position: 'absolute',
                                        color: '#333031',
                                        textAlign: 'center',
                                        width: '80%',
                                        lineHeight: '1'

                                    }}
                                >The future of API technology <span className='textBold'>Complete</span> lifecycle <span className='textBold'>control.</span></div>




                                <div
                                    className='landingFont3Mobile landingFontColor textBold'
                                    style={{
                                        marginTop: '45%',
                                        position: 'absolute',
                                        // color: '#333031',
                                        textAlign: 'center',
                                        width: '70%',
                                        lineHeight: '1'

                                    }}
                                >Virtuality and Artificial Intelligence at its CORE</div>


                                {/* d-flex estaba en las clases */}
                                <div className='flex-column align-items-center justify-content-center d-none' style={{
                                    backgroundColor: '#fafafa',
                                    height: '250px',
                                    // textAlign: 'center',
                                    // paddingTop: '30px',
                                    width: '100%',
                                }}>
                                    <div className='landingFont4Mobile landingFontColor'
                                        style={{
                                            color: '#333031',
                                            textAlign: 'center',
                                            width: '70%',
                                            lineHeight: '1'

                                        }}>
                                        Ready to transform API development?
                                    </div>
                                    <div className='landingFont4Mobile landingFontColor textBold'
                                        style={{
                                            color: '#333031',
                                            textAlign: 'center',
                                            width: '70%',
                                            marginTop: '20px'
                                        }}
                                    >
                                        Register at AVAP ID
                                    </div>
                                    <button
                                        onClick={() => { history.push('/Register') }}
                                        className='custom-button-green textBold'
                                        style={{ marginTop: '20px' }}
                                    >
                                        Create my AVAP ID
                                    </button>

                                </div>
                            </div>

                            <div id='SecondSection' className='mobileSectionLimit d-flex flex-column justify-content-center' style={{
                                backgroundColor: '#ffffff',
                                 height: '920px',
                                textAlign: 'center',
                                marginTop: '100px',
                                 paddingTop: '10%',
                                 paddingBottom: '10%'
                            }}>
                                <div
                                    className='landingFont1Mobile landingFontColor landingFontColor'
                                >
                                    The total  <span className='textBold'>API solution</span>
                                </div>
                                <div
                                    className='landingFont1Mobile landingFontColor'
                                >

                                </div>
                                <div
                                    className='landingFont3Mobile landingFontColor'
                                    style={{
                                        marginTop: '5%',
                                        width: '87%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        lineHeight: '1.1'
                                    }}
                                >
                                    Next-gen technology with AI embedded in its DNA, offering scalability and profitability through its Virtuality.
                                </div>


                                <div className='d-flex flex-column align-items-center'
                                    id='bloque de columnas'
                                    style={{
                                        width: '87%',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        paddingTop: '5%',

                                        // paddingLeft: '20px',
                                        // paddingRight: '20px'
                                    }}
                                >
                                    <div className='d-flex flex-column align-items-center'>
                                        <div style={{ width: "20%" }}>
                                            <Image src={lenguajeMb} style={{ width: "100%" }} />
                                        </div>
                                        <div className='d-flex flex-column align-items-center'>
                                            <div style={{ marginTop: '0px', textAlign: 'center' }} className='landingFont3Mobile landingFontColor textBold'>High-Level Programming Language</div>
                                            <div style={{ marginTop: '2%', textAlign: 'center', lineHeight: '1' }} className='landingFont4Mobile landingFontColor'>Its primary focus is on API creation, implementing fundamental processes like language commands and managing input and output data structures as native data structures.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center' style={{ paddingTop: '5%' }}>
                                        <div style={{ width: "20%" }} >
                                            <Image src={virtualidadMb} style={{ width: "100%" }} />
                                        </div>
                                        <div >
                                            <div style={{ marginTop: '0px', textAlign: 'center' }} className='landingFont3Mobile landingFontColor textBold'>Virtuality</div>
                                            <div style={{ marginTop: '2%', textAlign: 'center', lineHeight: '1' }} className='landingFont4Mobile landingFontColor'>AVAP’s virtuality comes from its dynamic definitions hosted in the cloud. Therefore, during development, the language server only reads commands, retrieves these definitions, and composes a Virtual Code that the virtual machine (parser) executes.</div>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center' style={{ paddingTop: '5%' }}>
                                        <div style={{ width: "20%" }}>
                                            <Image src={inteligenciaMb} style={{ width: "100%" }} />
                                        </div>
                                        <div >
                                            <div style={{ marginTop: '0px', textAlign: 'center' }} className='landingFont3Mobile landingFontColor textBold'>Intelligent Language                            </div>
                                            <div style={{ marginTop: '2%', textAlign: 'center', lineHeight: '1' }} className='landingFont4Mobile landingFontColor'>Artificial intelligence at its core: the developer presents a problem and obtains the solution through specific commands and natural language.</div>
                                        </div>
                                    </div>




                                </div>


                            </div>
                            {/* <div style={{ backgroundColor: 'white', height: '150px' }}> </div> */}
                            <div id='Productividad'
                                style={{
                                    width: '100%',
                                    // height: '100%',
                                    // overflow: 'hidden',
                                }}>
                                <div id='Third Section' className='d-flex flex-column mobileSectionLimit align-items-center' style={{ backgroundColor: '#fafafa' }}>

                                    <div style={{ width: '100%', height: '70vh', background: `url(${cabecera2}) no-repeat`, backgroundSize: 'cover', backgroundPosition: '40% 50%' }}>
                                        {/* <Image src={cabecera2} style={{ position: 'relative', marginTop: '00px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: '35% 50%' }} alt="Productividad" /> */}
                                    </div>
                                    <div style={{ marginTop: '0px', marginBottom: '0px', width: '87%', marginLeft: 'auto', marginRight: 'auto', position: 'absolute', paddingTop: '4vh' }}>

                                        {/* <div style={{ width: '50%', marginLeft: '0', marginRight: 'auto', position: 'absolute' }}> */}

                                        <div
                                            style={{
                                                textAlign: 'start',
                                                // position: 'absolute',
                                                // marginTop: '-420px',
                                                // marginLeft: '-80px',
                                                width: '80%',
                                                maxHeight: '80%',
                                                lineHeight: '1',
                                                fontWeight: 300
                                            }}
                                            className='landingFont0Mobile landingFontColor'>
                                            Enhance <span className='textBold'>productivity</span> and maximize <span className='textBold'>ROI</span> for every project
                                            <div className='landingFont3Mobile landingFontColor textBold' style={{ lineHeight: '1', paddingTop: '15px', width: '70%' }}>
                                                Efficient, intelligent, and agile API management.
                                            </div>

                                        </div>

                                        {/* </div> */}

                                    </div>
                                    <div id='Productos y servicios' className='d-flex flex-column'
                                        style={{
                                            height: 'auto',
                                            textAlign: 'center',
                                            paddingTop: '3vh',
                                            width: '87%'
                                        }}
                                    >
                                        <div className='landingFont1Mobile landingFontColor'>
                                            Products and Services
                                        </div>

                                        <div className='landingFont2Mobile landingFontColor'
                                            style={{
                                                marginTop: '10%',
                                                lineHeight: '1.1',
                                                fontWeight: 400
                                            }}>
                                            AVAP Technology: Complete Lifecycle Management for Your APIs – Creation, Publication, Utilization, and Maintenance.
                                        </div>
                                    </div>
                                </div>
                                <div id='Fourth Section' className='d-flex flex-column mobileSectionLimit align-items-center'
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        width: '87%',
                                        // backgroundColor: 'white',
                                        // marginTop: '30px',
                                        // minHeight: '620px',
                                        // height: '620px',
                                        overflow: 'hidden'

                                    }}
                                >

                                    <div style={{

                                        width: '70%',
                                    }}>

                                        <Image src={logo_framework_producto} style={{ marginBottom: '20%', marginTop: '20%' }} width="" alt="Logo" />
                                    </div>
                                    <div>
                                        <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '4px' }}></div>
                                        <Image onClick={() => { cleanAll(); setavapBut(true) }} src={avapBut ? botonAvapActivo : botonAvap} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                        <Image onClick={() => { cleanAll(); setdsBut(true) }} src={dsBut ? botonDsActivo : botonDs} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                        <Image onClick={() => { cleanAll(); setvsBut(true) }} src={vsBut ? botonVspActivo : botonVs} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                        <Image onClick={() => { cleanAll(); setgBut(true) }} src={gBut ? botonGActivo : botonG} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                        <Image onClick={() => { cleanAll(); setcloudBut(true) }} src={cloudBut ? botonCloudActivo : botonCloud} style={{ cursor: 'pointer', width: '16.2%', marginRight: '0.5%' }} />
                                        <Image onClick={() => { cleanAll(); setcnBut(true) }} src={CnBut ? botonCnpActivo : botonCnp} style={{ cursor: 'pointer', width: '16.2%' }} />

                                    </div>
                                    {
                                        avapBut &&
                                        <>
                                            <div style={{ justifyContent: 'start', textAlign: 'start', height: '60vh' }}>
                                                <div>
                                                    <Image src={AVAPLanguage} style={{ height: '32px', marginLeft: '10px', marginTop: '40px' }} />
                                                </div>
                                                <div className='landingFont3bMobile landingFontColor' style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '20px', lineHeight: '1.1' }}>
                                                    AVAP (Advanced Virtual API Programming) is a high-level virtual programming language designed and created for API development. Its virtual nature enables continuous code improvement, making it highly scalable and cost-effective.
                                                    AVAP, including its language specifications, interpreter, and language server, marks the beginning of a new generation of programming languages where artificial intelligence becomes a fundamental part of the language, allowing developers to focus on problem-solving tasks.
                                                </div>

                                            </div>
                                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end', width: '100%' }}>
                                                <button onClick={() => { history.push('/avap') }} className='custom-button-orange-2'>Learn More</button>
                                            </div>
                                        </>
                                    }
                                    {
                                        vsBut &&
                                        <>
                                            <div style={{ justifyContent: 'start', textAlign: 'start', height: '60vh' }}>
                                                <div>
                                                    <Image src={AVAPVirtualServer} style={{ height: '32px', marginLeft: '10px', marginTop: '40px' }} />
                                                </div>
                                                <div className='landingFont3bMobile landingFontColor' style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '20px', lineHeight: '1.1' }}>
                                                    Manage and publish your APIs in both development and production environments using AVS (AVAP Virtual Server).

                                                    Integrated with the AVAP code repository in the cloud, AVS can manage multiple versions of each API code developed in AVAP, and conduct necessary tests for deployment and publication across diverse environments.
                                                    AVS is compatible with all language and API servers.

                                                </div>

                                            </div>
                                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end', width: '100%' }}>
                                                <button onClick={() => { history.push('/avs') }} className='custom-button-red'>Learn More</button>
                                            </div>
                                        </>
                                    }
                                    {
                                        gBut &&
                                        <>
                                            <div style={{ justifyContent: 'start', textAlign: 'start', height: '60vh' }}>
                                                <div>
                                                    <Image src={AVAPGateway} style={{ height: '32px', marginLeft: '10px', marginTop: '40px' }} />
                                                </div>
                                                <div className='landingFont3bMobile landingFontColor' style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '20px', lineHeight: '1.1' }}>
                                                    More powerful than a proxy, it assesses consumption history, scenarios, and established conditions to swiftly and efficiently redirect to different servers. Lua programming drives its functionality.

                                                    AVAP Gateway Advanced is both universal and powerful, boasting compatibility with any language server.

                                                </div>

                                            </div>
                                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end', width: '100%' }}>
                                                <button onClick={() => { history.push('/gateway') }} className='custom-button-purple'>Learn More</button>
                                            </div>
                                        </>

                                    }
                                    {
                                        dsBut &&
                                        <>
                                            <div style={{ justifyContent: 'start', textAlign: 'start', height: '60vh' }}>
                                                <div>
                                                    <Image src={AVAPDS} style={{ height: '32px', marginLeft: '10px', marginTop: '40px' }} />
                                                </div>
                                                <div className='landingFont3bMobile landingFontColor' style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '20px', lineHeight: '1.1' }}>
                                                    AVAP Dev Studio is an IDE that offers complete project management, API development, code maintenance, and API publication capabilities. It enhances the development process with AI-powered coding through extensions, facilitating a collaborative experience through team management with co-coding and chat management features.

                                                    AVAP Dev Studio allows direct execution of AVAP code through integration with a language server, maximizing the benefits of AVAP Framework with its powerful tools and functionalities.

                                                </div>

                                            </div>
                                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end', width: '100%' }}>
                                                <button onClick={() => { history.push('/devstudio') }} className='custom-button-green'>Learn More</button>
                                            </div>
                                        </>
                                    }
                                    {
                                        cloudBut &&
                                        <>
                                            <div style={{ justifyContent: 'start', textAlign: 'start', height: '60vh' }}>
                                                <div>
                                                    <Image src={AVAPCloudLogo} style={{ height: '32px', marginLeft: '10px', marginTop: '40px' }} />
                                                </div>
                                                <div className='landingFont3bMobile landingFontColor' style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '20px', lineHeight: '1.1' }}>
                                                    Cloud platform designed for users to leverage the full potential of AVAP Framework products and services. Gain flexible and scalable access to advanced technological solutions without the need for local infrastructure.

                                                    AVAP Cloud is a universal and powerful service that enhances your AVAP experience while also being compatible with other programming environments and language servers.

                                                </div>

                                            </div>
                                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end', width: '100%' }}>
                                                <button onClick={() => { history.push('/cloud') }} className='custom-button-blue'>Learn More</button>
                                            </div>
                                        </>
                                    }
                                    {
                                        CnBut &&
                                        <>
                                            <div style={{ justifyContent: 'start', textAlign: 'start', height: '60vh' }}>
                                                <div>
                                                    <Image src={AVAPCollaborative} style={{ height: '32px', marginLeft: '10px', marginTop: '40px' }} />
                                                </div>
                                                <div className='landingFont3bMobile landingFontColor' style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '20px', lineHeight: '1.1' }}>
                                                    A peer-to-peer platform with exponential growth capacity, allowing developers to harness a complete deployment of AVS (AVAP Virtual Server) and AVAP Interpreter & Server Language for API development and publication.

                                                    AVAP Collaborative Network can assess each API request for direct processing or redirection to another server with lower response time, utilizing dedicated channels for AVAP Virtual Code transmission to amplify virtual machine capacity across language servers.

                                                </div>

                                            </div>
                                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'end', width: '100%' }}>
                                                <button onClick={() => { history.push('/collaborative') }} className='custom-button-emerald'>Learn More</button>
                                            </div>
                                        </>
                                    }

                                </div>




                            </div>



                            <div id='Virtualidad'
                                style={{
                                    backgroundColor: '#F5F5F5',
                                    width: '100%',
                                    // height: '100%',
                                    // overflow: 'hidden',
                                    // marginTop: '0px',
                                    // position: 'relative',
                                    // display: 'flex'
                                }}>
                                <div id='Fifth section' className='d-flex flex-column mobileSectionLimit align-items-center'>
                                    <div style={{ width: '100%', height: '660px', background: `url(${Virtualizacion}) no-repeat`, backgroundSize: 'cover', backgroundPosition: '50% 50%' }}>
                                        {/* <Image src={Virtualizacion} style={{ position: 'relative', marginTop: '0px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: '50% 50%' }} alt="Virtualidad" /> */}

                                    </div>
                                    <div id='Virtualization img text' className='d-flex flex-column align-items-end' style={{ position: 'absolute', width: '87%', paddingTop: '4vh' }}>
                                        <div className='landingFont1Mobile landingFontColor' style={{ width: '70%', textAlign: 'end', lineHeight: '1' }}>Uncover the reality of API  <span className='textBold'>virtualization</span> </div>
                                        <div className='landingFont3Mobile landingFontColor' style={{ width: '80%', textAlign: 'end', lineHeight: '1' }}>AVAP’s language virtualization feature enables you to create virtual APIs right from the start.</div>

                                    </div>

                                    <div className='landingFont1Mobile landingFontColor' style={{ marginBottom: '10px', paddingTop: '6vh', textAlign: 'center', width: '87%', lineHeight: '1.1' }}>
                                        AVAP Framework: A complete API solution that transforms processes and boosts efficiency.
                                    </div>

                                </div>
                                <div id='Sixth section' className='d-flex flex-column mobileSectionLimit align-items-center'
                                    style={{

                                        width: '100%',
                                        justifyContent: 'space-evenly'
                                        // height: '100%',
                                        // overflow: 'hidden',
                                        // marginTop: '0px',
                                        // position: 'relative',

                                        // justifyContent: 'center'
                                    }}>

                                    <div className='d-flex flex-column align-items-center' style={{ width: '87%', marginLeft: 'auto', marginRight: 'auto' }}>

                                        <Image src={productividadMb} style={{ width: '20%', marginTop: '5%' }} alt="productividad" />
                                        <div style={{ paddingTop: '8px', textAlign: 'center', lineHeight: '1' }} className='landingFont3Mobile landingFontColor textBold'>Boost productivity</div>
                                        <div style={{ paddingTop: '8px', textAlign: 'center', lineHeight: '1' }} className='landingFont4Mobile landingFontColor'>Let your development team focus on solutions while we handle the technical details.                        </div>

                                    </div>
                                    <div className='d-flex flex-column align-items-center' style={{ width: '87%', marginLeft: 'auto', marginRight: 'auto' }}>

                                        <Image src={focoproyectosMb} style={{ width: '20%', marginTop: '5%' }} alt="foco en proyectos" />
                                        <div style={{ paddingTop: '8px', textAlign: 'center', lineHeight: '1' }} className='landingFont3Mobile landingFontColor textBold'>Maximize project focus</div>
                                        <div style={{ paddingTop: '8px', textAlign: 'center', lineHeight: '1' }} className='landingFont4Mobile landingFontColor'>Forget about deployment details, let us manage the logistics so you can concentrate on making your projects a success.</div>
                                    </div>

                                    <div className='d-flex flex-column align-items-center' style={{ width: '87%', marginLeft: 'auto', marginRight: 'auto' }}>

                                        <Image src={tiemposlanzamientoMb} style={{ width: '20%', marginTop: '5%' }} alt="productividad" />
                                        <div style={{ paddingTop: '8px', textAlign: 'center', lineHeight: '1' }} className='landingFont3Mobile landingFontColor textBold'>Faster time to market</div>
                                        <div style={{ paddingTop: '8px', textAlign: 'center', lineHeight: '1' }} className='landingFont4Mobile landingFontColor'>AVAP's language and comprehensive suite of products and services ensure a seamless and efficient API lifecycle.</div>
                                    </div>

                                    <div className='d-flex flex-column align-items-center' style={{ width: '87%', marginLeft: 'auto', marginRight: 'auto' }}>

                                        <Image src={rentabilidadMb} style={{ width: '20%', marginTop: '5%' }} alt="productividad" />
                                        <div style={{ paddingTop: '8px', textAlign: 'center', lineHeight: '1' }} className='landingFont3Mobile landingFontColor textBold'>Higher profitability</div>
                                        <div style={{ paddingTop: '8px', textAlign: 'center', lineHeight: '1' }} className='landingFont4Mobile landingFontColor'>Optimize development investment with specialized infrastructure and resources for API deployment and maintenance.</div>
                                    </div>

                                    <div style={{ marginBottom: '5%', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', marginTop: '5%' }}>
                                        <button onClick={() => { window.open('mailto:sales@101obex.com', '_self') }} className='custom-button-blue textBold'>Contact Sales</button>
                                    </div>

                                </div>




                            </div>



                            <div id='Collaborative Network'
                                style={{
                                    width: '100%',
                                    // height: '100%',
                                    // overflow: 'hidden',
                                    // marginTop: '0px',
                                    // position: 'relative',
                                    // display: 'flex',
                                    // backgroundColor: '#fafafa'
                                }}>
                                <div id='Seventh section' className='d-flex flex-column mobileSectionLimit align-items-center'>
                                    <div style={{ width: '100%', height: '600px' }}>
                                        <Image src={collaborative2} style={{ objectFit: 'cover', objectPosition: '50% 50%', width: '100%' }} alt="Logo" height={'100%'} />
                                        {/* <Image src={collaborative2} style={{ position: 'relative', marginTop: '0px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: '50% 50%', maxHeight '100%' }} alt="collaborative2" /> */}

                                    </div>

                                    <div className='d-flex flex-column align-items-center' style={{ position: 'absolute', marginTop: '1vh' }}>
                                        <div className='landingFont5Mobile landingFontColor' style={{ width: '60%', textAlign: 'center', marginTop: '10px' }}>
                                            <Image src={AVAPCollaborative} style={{ width: '100%' }} alt="AVAPCollaborative" />
                                        </div>
                                        <div className='landingFont5Mobile landingFontColor' style={{ width: '87%', textAlign: 'center', marginTop: '5px', lineHeight: '1', fontWeight: 300 }}><div>Welcome to the first democratic computing and API exploitation network.</div><div>AVAP Collaborative Network</div></div>

                                    </div>
                                    <div className='landingFont2Mobile landingFontColor textBold' style={{ textAlign: 'center', marginTop: '2vh', width: '87%' }}>Peer-to-Peer Collaborative Servers</div>
                                    {/*<Image src={icon_collaborative} style={{ marginTop:'0px' }} alt="Virtualidad" />*/}
                                    <div style={{ marginBottom: '00px', marginTop: '20px', textAlign: 'center', lineHeight: '1.1', width: '87%' }} className='landingFont3Mobile landingFontColor'>AVAP Collaborative Network is a cloud-based peer-to-peer service that enables developers to share servers, AVS (AVAP Virtual Servers), and AI&LS (AVAP Interpreter and Language Servers). It integrates AVAP language and infrastructure, optimizing code interpretation and processing through advanced evaluation of each request. </div>

                                    <div style={{ marginLeft: '0px', marginTop: '20px' }}>
                                        <button
                                            onClick={() => { history.push('/collaborative') }}
                                            className='custom-button-emerald textBold'>Try It</button>
                                    </div>
                                </div>
                                <div id='Eighth section' className='d-flex flex-column mobileSectionLimit align-items-center justify-content-center'>
                                    <div className='d-flex flex-column align-items-center' style={{ marginLeft: 'auto', marginRight: 'auto', width: '87%' }}>


                                        <Card className="text-center">
                                            <Card.Img variant="top" src={connect} />
                                            <Card.Body>
                                                <Card.Title style={{ textAlign: 'center' }}>
                                                    <Image src={avapconect} style={{ width: '80%', position: 'relative', marginTop: '20px', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }} alt="AVAP Connect" />


                                                </Card.Title>
                                                <Card.Text>
                                                    <div className='d-flex flex-column align-items-center'>
                                                        <div style={{ paddingTop: '20px', paddingLeft: '0%', paddingRight: '0%', lineHeight: '1', textAlign: 'center' }} className='landingFont1Mobile'>Learn about all the benefits of AVAP Connect</div>
                                                        <div style={{ paddingTop: '20px', lineHeight: '1', textAlign: 'center' }} className='landingFont6Mobile landingFontColor textBold'>Join the Early Access program</div>

                                                        <div style={{ marginLeft: '10%', height: '2px', backgroundColor: '#ebebeb', width: '90%', marginTop: '2vh', marginBottom: '2vh' }}></div>

                                                        <div className='landingFont4Mobile landingFontColor' style={{ width: '90%', lineHeight: '1.1', textAlign: 'center' }}>
                                                            Become an AVAP Framework strategic partner and provide the ultimate comprehensive solution for APIs. AVAP Connect is our global program designed for business partners, offering a unified infrastructure with complete sales tools, support, services, and pre-sale opportunities to drive your business growth.
                                                        </div>
                                                    </div>

                                                </Card.Text>
                                                <Button onClick={() => { window.open('https://speed-computing-1656.my.site.com/connect/s/', '_blank') }} className='custom-button-purple textBold' style={{ margin: '1vh' }}>Join Now</Button>
                                            </Card.Body>
                                        </Card>

                                    </div>

                                </div>





                            </div>




                        </div>

                    </div>




                </div>





                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>
            </MobileView >
        </>
    )
}

export default NewLanding;