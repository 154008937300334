import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Table, Button, Spinner, Accordion, Card, Form, Image } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddProjectModalSel from '../Modals/addProjectModalSelect';
import AddProjectModal from '../Modals/addProjectModal';
import { faChevronDown, faChevronUp, faPencilAlt, faPlusCircle  } from '@fortawesome/free-solid-svg-icons';
import { amountToEur } from '../../services/AmountService';
import ConsumptionProjectChart from './GraphComponents/ConsumptionProjectChart';
import UpgradePlanModal from '../Modals/UpgradePlanModal';

import editicon from '../../../src/assets/img/icon_edit.png';
import Logo from '../../assets/img/ilustracion_noInfo2.png'
import SessionService from '../../services/SessionsService';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';


type ProjectsProps = {

};


const ProjectsContent:FunctionComponent<ProjectsProps> = (props:ProjectsProps) => {
  const menuActive = 'projects';

  const [slotsInfo, setSlots] = useState({
      allowProjectsBySubscription: 0,
      maxAllowProjecs: 0,
      maxExtraProjects: 0,
      extraPurchasedProjects: 0,
      posibleSlotsToBuy: 0,
      activeProjects: 0,
      totalSlots: 0,
      availableSlots: 0
  });
  const {} = props
  const [loadingTable, setLoadingTable] = useState(false);
  const [possibleSlotsToBuy, setSlotsToBuy] = useState(0);
  const [availableSlots, setavailableSlots] = useState(0);
  const [projects, setProjects] = useState([]);
  const [addingProjects, setAddingProjects] = useState(false);
  const handleAddProjects = () => {
    setAddingProjects(!addingProjects);
  }
  const [availableAdd, setAvailableAdd] = useState(true);
  const [projectSelected, setProjectSelected] = useState('')
  const [allLoaded, setAllLoaded] = useState(true);
  const [currentSuscripcion, setCurrentSusbscription] = useState(0);
  const [sublinks, setSublinks] = useState([

  ]);
  const [activeKey, setActiveKey] = useState('0');
  const [cardsLoading, setCardsLoading] = useState(true);

  const getProjects = async () => {
    try {
      const projectsex = []
      setProjects([]);
      setLoadingTable(true);
      const projects = (await ObexRequestHandler.get(`/projects?withbalances=true&organization_id=${SessionService.ORGANIZATION}`, {}, true)).data || [];
      const projectsT = [];
      projects.forEach(async(element) => {
        if (element.isCreating) projectsT.push(element);
        const productionProject = (await ObexRequestHandler.get(`/is_in_production?project_id=${element.id}`, {}, true)).data || {};
        element.production = productionProject.production;
        projectsex.push(element);
      });

      const slots = (await ObexRequestHandler.get('/consumption/slots', {}, true)).data || {};
      const licencia = (await ObexRequestHandler.get('/current_subscription', {}, true)).data || {};

      //console.log(slots);
      //console.log(licencia);


      setavailableSlots(slots.availableSlots);
      setCurrentSusbscription(licencia.subscription_id);
      const SelectedProject = projectsT.length === 0 ? '' : projectsT[0].name;
      setProjectSelected(SelectedProject);
      setProjects(projectsex);
      //console.log(projectsex)
      setSlots(slots);

      setSlotsToBuy(slots.maxExtraProjects - slots.extraPurchasedProjects);

      
      // console.log('PROJECT_SELECTERD');
      // console.log(projects[0].name);

      projectsLoaded(SelectedProject);

      setAllLoaded(false);
      setCardsLoading(false);
    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);
      setAllLoaded(false);
      setSublinks([
  
      ]);

    }
    setLoadingTable(false);
    setAllLoaded(false);
  }

  const whenClosing = () => {
    setAddingProjects(false);
    getProjects();
  }

  const whenCancel = () => {
    setAddingProjects(false);
    
  }

  const projectsLoaded = (projectSelecteds) =>{

    setSublinks([]);
    // console.log('*-**');
    // console.log(projectSelecteds);
    if (projectSelecteds!=''){
    setSublinks([
     
    ]);
  }
    

  }

  const history = useHistory();
  const routerLink = (url) => {
    const pushData = projects 
    ? { state: { projects } } 
    : {};
    history.push(url, pushData);
  }

  const loadingSpinner = <Row><Col md={1} className="mx-auto"><Spinner animation="border" /></Col></Row>;
  const noProjectsText = <Row>
                          <Col className="mx-auto text-center">
                            <span className="d-block">
                              You have not yet registered any project in your account. 
                            </span>
                            <span className="d-block">
                              Press add to register and configure your projects.
                            </span>
                          </Col>
                         </Row>;

const toggleAccordion = (key: string) => {
  setActiveKey(activeKey === key ? null : key);

 // Actualizar el proyecto seleccionado cuando se abre un acordeón
//  if (activeKey !== key) {
//   const selectedProject = projects[key]; // Obtener el proyecto correspondiente al acordeón abierto
//   setProject(selectedProject.name); // Establecer el proyecto como seleccionado
// }
};

const generateCards = projects.map((p, index) => {
  // debugger
  
  return (
    
    <Row key={index} /*activeKey={activeKey}*/ onSelect={(key) => setActiveKey(key)} className="mb-4">
      <Card style={{width:'80%'}}>
        <Card.Header>

          <div className='d-flex justify-content-between' style={{ paddingLeft: '4rem', paddingRight: '4rem' }}>
            <div className='text-bold' style={{ fontSize: '1.1rem' }} >
              {p.description}
            </div>

            <div style={{display:'flex'}} onClick={() => {
              
              toggleAccordion(String(index))
              console.log(index)

            }}>
              <a className="edit-link"  onClick={() => routerLink(`/projects/${p.name}`)}><Image src={editicon}  style={{ marginBottom:'' }} width="20px" alt="Logo" /></a> 
              
             {/*} 
            {activeKey === String(index) ? (
              <div style={{marginLeft:'20px', marginRight:'-20px'}}><FontAwesomeIcon icon={faChevronUp} cursor="pointer"/></div>
            ) : (
              <div style={{marginLeft:'20px', marginRight:'-20px'}}><FontAwesomeIcon icon={faChevronDown} cursor="pointer"/></div>
            )} 
            */}
            </div>
          </div>

        </Card.Header>
        {/*<Accordion.Collapse eventKey={String(index)}>*/}
        <Row>
          <Card.Body className='pt-0'>
            <div className='d-flex flex-column' style={{ paddingLeft: '4rem', paddingRight: '4rem' }}>
              <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                <div className='text-dark text-bold'>Project ID:</div>
                <div>{p.name}</div>
              </div>

              <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                <div className='text-dark text-bold'>Total Request (month):</div>
                <div>{p.tx.current}</div>
              </div>

{/*}
              <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                <div className='text-dark text-bold'>Balance:</div>
                <div>{amountToEur(p.balance)}</div>
              </div>

              <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                <div className='text-dark text-bold'>Benefits:</div>
                <div>{amountToEur(p.benefits)}</div>
              </div>
*/}
              <div className='d-flex flex-row justify-content-between py-3'>
                <div className='text-dark text-bold'>Creation date:</div>
                <div>{p.creation_date.toString().split(' ')[0]}</div>
              </div>
{/*}
              <div className='d-flex flex-row justify-content-between border-bottom py-3'>
                <div className='text-dark text-bold'>Production:</div>
                <div>{p.production ? 'true':'false'}</div>
              </div>
*/}

{/*}
              <div className='d-flex flex-row justify-content-end  py-3'>
                <div className='mr-2'>
                  Edit
                </div>
                <div>
                {!p.isCreating ?
                  <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>
                  :
                  <a className="edit-link"  onClick={() => routerLink(`/projects/${p.name}`)}><FontAwesomeIcon icon={faPencilAlt} size="1x" className="mr-1"/></a> 
                }
                </div>
               
              </div>

*/}


{/*}


              <div className='d-flex row'>
                <div className='col-6'>
                  <Form.Control type="date" className='newStyleInputsDate' 
                  // value={from} 
                  // onChange={(e) => 
                  // setFrom(e.target.value)} 
                  />
                </div>
                <div className='col-6'>
                  <Form.Control type="date" className='newStyleInputsDate' 
                  // value={to} 
                  // onChange={(e) => setTo(e.target.value)} 
                  >
                    </Form.Control>
                </div>






                
                {/* <div className='col-6 pt-3'>
                  <Form.Control as="select" className='newStyleInputsSelect' onChange={handleSelectProject} custom value={currentProject}>
                  {projects.map((value) => (
                    <option key={value.name} value={value.name}>
                      {value.name}
                    </option>
                  
                  ))}
                  </Form.Control>
                </div> */}
{/*}
                <div className='col-6 pt-3'>
                <Button disabled={allLoaded} className="custom-button-blue w-80 textBold px-4" onClick={handleAddProjects}><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1"/> Add New</Button>
                </div>
*/}
                {/* <div className='col-6 pt-3'>
                  <Button disabled={allLoaded} className=" obex-btn btn-add-green btn-block newStyleInputs" style={{color:"#000"}} onClick={handleAddingTx}> Add More</Button>
                </div> */}
{/*}              </div>



*/}




            </div>
          </Card.Body>
          </Row>
        {/*</Accordion.Collapse>*/}
      </Card>
    </Row>

  )
})


  
    useEffect(() => {
      window.scrollTo(0, 0);
      getProjects();
    }, []);



  
  return (
    <>
      <BrowserView>

     <DashLayout sider={DashRouters} active={menuActive} sublinks= {sublinks}> 
    
    <div style={{marginTop:'5px', marginLeft:'-17px', marginRight:'0'}}>
      <Row style={{marginBottom:'2rem'}}> 
        <Col style={{}} className='textBold'>
          <h2 >API Projects</h2>
        </Col>
         <Col style={{ width:'20%', textAlign:'end' }}>
          <Button disabled={allLoaded} className="custom-button-blue w-100 textBold px-4" style={{maxWidth:'225px', marginRight:'20px'}} onClick={handleAddProjects}>Add New </Button>
        </Col>
      </Row>
      <Row>
        <Col>
        <p style={{ marginTop:'-1rem' }} className='fontText3 colorBlack marginBottom2rem'>Manage the lifecycle of your API projects from creation and review to control and optimization.</p> 
        </Col>
       
        
      </Row>
      {(projects.length == 0 && cardsLoading == false) &&
      <div>
      <><Row className="justify-content-center my-4">
          <Col xs="6" style={{display:'flex', justifyContent:'center'}}>
            <Image style={{width:'50%', marginTop:'60px', marginRight:'0'}} src={Logo} fluid />
          </Col>
          </Row>
          <Col style={{textAlign:'center', marginBottom:'60px', marginRight:'0'}}>
          <Row style={{marginRight:'0'}}><Col><h5>You don’t have data to show</h5></Col></Row>
          <Row style={{marginRight:'0'}}><Col>When you have data to show, you’ll find here.</Col></Row>
         {/*} <Row style={{marginRight:'293px'}}><Col>want to track, you'll find here. </Col></Row>*/}
          </Col>
        </>
        </div>
}
      <Row style={{ fontWeight: 'bold' }}> 
        <Col md={6} className="mh-100 overflow-auto" style={{ height: "75vh" }}>
        {cardsLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          ) : (
            generateCards
          )}
        </Col>
        <Col md={6}>
          <div>
          {loadingTable || projectSelected == '' ? (loadingTable && loadingSpinner): 
          
          (projects.map((project) => {return (
            <div style={{height:'265px'}}>
          <ConsumptionProjectChart title={project.name} projects={projects.filter(rr => rr.isCreating)}/>
            </div>
          )}) 
        )
          
          }

          </div>
        </Col>
      </Row>
      
      {/*}
      <Row>
        <Col>
        <Table responsive className="obex-projects-table">
          <thead style={{ 
              fontFamily: 'Regular Intro Bold',
              textAlign:'start',
              backgroundColor:'transparent',
              color:'black',
              borderWidth:'1px',
              borderRadius:'15px',
              borderStyle:'hidden'
             }}>
            <tr>
               <th className='textBold'>Project Name</th>
               <th className='textBold'>Project ID</th>
               <th className='textBold'>Requests</th>
               <th className='textBold'>Balance</th>
               <th className='textBold'>Benefits</th>
               <th className='textBold'>Date</th>
               <th className='textBold'>Production</th>
               <th className='textBold'></th>
            </tr>
          </thead>
          <tbody>
            {projects.map(project =>  (
              <tr key={'Projects'+project.id+Math.random()}>
                <td>{project.description}</td>
                <td>{project.name}</td>
                <td>{project.tx.current}</td>
                <td>{amountToEur(project.balance)}</td>
                <td>{amountToEur(project.benefits)}</td>
                <td>{project.creation_date}</td>
                <td>{project.production ? 'true':'false'}</td>
                <td className="text-right">

                {!project.isCreating ?
                  <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>
                  :
                  <a className="edit-link"  onClick={() => routerLink(`/projects/${project.name}`)}><FontAwesomeIcon icon={faPencilAlt} size="1x" className="mr-1"/></a> 
                }
                  </td>
              </tr>
            ))}
          </tbody>
          </Table>
        </Col>
      </Row>
*/}


{/*}
      {loadingTable && loadingSpinner && false}
      {((!loadingTable && projects.length <= 0)) && noProjectsText}
      <Row className="mb-3">
        <Col md={2}>
          <Button disabled={allLoaded} className="btn-add obex-btn btn-add-green btn-block px-4" onClick={handleAddProjects}><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1"/> Add New</Button>
        </Col>
      </Row>
      <Row className="stats-grafic">
        <Col>
        {loadingTable || projectSelected == '' ? (loadingTable && loadingSpinner): <ConsumptionProjectChart title={projectSelected} projects={projects.filter(rr => rr.isCreating)}/>}
        </Col>
        
      </Row>
      */}
      <UpgradePlanModal 
          showModal={ addingProjects && availableSlots == 0 && possibleSlotsToBuy == 0 } 
          text={'In order to add a new project you need to upgrade your plan.'} 
          onCloseModal={whenClosing} 
          onCancelModal={whenCancel}
          currentSuscription={currentSuscripcion}
          onSuccess={whenClosing}
          />

        
      {(addingProjects && availableSlots > 0) 
        ? <AddProjectModal 
          shown={addingProjects && availableSlots > 0} 
          onSuccessCreation = {whenClosing} 
          onCloseAction={whenClosing}
          setAvailable={setAvailableAdd}/>
        : <AddProjectModalSel possibleProjectsToBuy={possibleSlotsToBuy} shown={addingProjects && possibleSlotsToBuy>0} onCloseAction={whenClosing}/> 
        
      }


</div>

    </DashLayout>
    </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
    )
  }

  // TODO possibleProjectsToBuy rescartar la info
  export default ProjectsContent;