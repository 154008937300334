import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import lenguaje from '../../assets/img/lenguaje.png'
import virtualidad from '../../assets/img/virtualidad.png'
import inteligencia from '../../assets/img/inteligencia.png'

import ga_1 from '../../assets/img/ga_1.png'
import ga_2 from '../../assets/img/ga_2.png'
import ga_3 from '../../assets/img/ga_3.png'
import ga_4 from '../../assets/img/ga_4.png'
import ga_5 from '../../assets/img/ga_5.png'
import ga_6 from '../../assets/img/ga_6.png'


import logo_framework_titulo from '../../assets/img/AVAP_Gateway_P.png'
import cabecera from '../../assets/img/foto_GA.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';

import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png' 
import rentabilidad from '../../assets/img/rentabilidad.png' 
import focoproyectos from '../../assets/img/focoproyectos.png' 
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png' 

import collaborative2 from '../../assets/img/collaborative2.png' 

import icon_collaborative from '../../assets/img/iconCollaborative.png' 
import connect from '../../assets/img/connect.png' 
import avapconect from '../../assets/img/AVAPConnect.png' 
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'

import vineta_CN from '../../assets/img/vineta_GA.png'
import gafico_cn_i from '../../assets/img/grafico_GA_i.png'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/Disclaimer';

type DashProps = {
    sider: any
};



const NewLandingCloud = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [ avapBut, setavapBut ] = useState(true);
    const [ vsBut, setvsBut ] = useState(false);
    const [ gBut, setgBut ] = useState(false);
    const [ dsBut, setdsBut ] = useState(false);
    const [ cloudBut, setcloudBut ] = useState(false);
    const [ CnBut, setcnBut ] = useState(false);

    const [ menu1, setmenu1 ] = useState(false);
    const [ menu2, setmenu2 ] = useState(false);
    const [ menu3, setmenu3 ] = useState(false);
    const [ menu4, setmenu4 ] = useState(false);
    const [ menu5, setmenu5 ] = useState(false);


    const cleanAll = ()=>{
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = ()=>{
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com'){
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });}

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario
    
        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
      };



    return (
        <>
            <BrowserView>

        <div className='d-flex flex-column' style={{ backgroundColor:'#ffffff' }}>

        <Navbar className='navbar' style={{ position:'fixed', width:'100%' }}>
            <Container className="">
                <Navbar.Brand href="/framework" style={{}}>
                    <Image src={logo_framework} style={{ marginBottom: '0px', width:'317px'}} height="33px" width="317px" alt="Logo" />
                </Navbar.Brand>
                <Nav style={{ width: '80%' }}>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu1(!menu1)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Products <div hidden={!menu1} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu2(!menu2)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Services <div hidden={!menu2} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{history.push('/avapid');cleanMenuAll()}} style={{ marginRight:'10px', marginLeft:'10px' }}>AVAP ID <div hidden={!menu3} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu4(!menu4)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Resources<div hidden={!menu4} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                <Nav.Link onClick={()=>{cleanMenuAll(); setmenu5(!menu5)}} style={{ marginRight:'10px', marginLeft:'10px' }}>Company  <div hidden={!menu5} style={{ position:'absolute', backgroundColor:'#d28e22', height:'4px', width:'70px', marginTop:'12px' }}></div></Nav.Link>
                </Nav>
                <button 
                    style={{ padding:'0px', width:'350px', height:'30px' }}
                    className='custom-button-green textBold'
                    onClick={()=>{history.push('/login')}}
                >
                        Sign in with your AVAP ID
                </button>
            </Container>

{ menu1 &&
            <><div style={{ 
                width: '80%',
                 marginLeft: '10%', 
                 marginRight: 'auto', 
                 marginTop:'52px', 
                 position: 'absolute', 
                 height:'2px', 
                 backgroundColor:'#ebebeb'
                }}></div>
                <div className='fade-in-image'  style={{
                        marginTop: '374px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Build Virtual APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/avap')}}>
                                    AVAP (Advanced Virtual API Programming)
                                </div>
                                <div onClick = {()=>{history.push('/vsc-extensions')}}
                                	style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP API Extensions for Microsoft Visual Code
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/devstudio')}}>
                                    AVAP Dev Studio
                                </div>



                            </div>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Test & Deliver APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>

                                <div onClick = {()=>{history.push('/version-control')}}  
                                    style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP API Version Control
                                </div>
                                <div onClick = {()=>{history.push('/api-publisher')}}  
                                    style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP API Publisher
                                </div>

                            </div>


                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Run APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>
{/*}
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Virtual Server
                                </div> */}
                            {/*}    <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Community Edition
                                </div>*/}
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/avs')}}>
                                    AVAP Virtual Server
                                </div>
                                <div style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}onClick={()=>{history.push('/avap')}}>
                                    AVAP Interpreter & Language Server
                                </div>
                            </div>



                        </div>

                        <div style={{
                            width: '100%',
                            display: 'flex'
                        }}>

                            <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    Power Up APIs
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>


                                <div onClick={()=>{history.push('/avap-marketplace')}}  
                                    style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Marketplace
                                </div>
                                <div onClick={()=>{history.push('/avap-connectors')}}  
                                    style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer',
                                    marginBottom: '34px',
                                }}
                                
                                >
                                    AVAP Connectors
                                </div>
                                {/*}    <div style={{
                paddingLeft:'20px',
                paddingTop:'10px',
                color:'#969696',
                marginBottom:'34px',
                cursor:'pointer'
            }}>
            AVAP Virtual Machine
        </div>*/}



                            </div>
                            {/*}
                    <div style={{
                        width:'33%'
                    }}>
                        <div className='landingFont3'
                            style={{
                                paddingLeft:'20px',
                                paddingTop:'30px'
                            }}
                        >
                            Extend APIs
                        </div>
                        <div style={{
                            height:'1px',
                            backgroundColor:'#d28e22',
                            marginLeft:'20px',
                            marginRight:'20px',
                                cursor:'pointer'
                        }}>
                        </div>
    
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                cursor:'pointer'
                            }}>
                            Plug Ins
                        </div>
                        <div style={{
                                paddingLeft:'20px',
                                paddingTop:'10px',
                                color:'#969696',
                                marginBottom:'34px',
                                cursor:'pointer'
                            }}>
                            Connectors
                        </div>
    
    
                    </div>
    
    
                */}



                        </div>


                    </div></>
}

{menu2 &&
    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '215px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Professional
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}
                                    onClick={()=>{history.push('/collaborative')}}
                                    >
                                        AVAP Collaborative Network
                                    </div>
                                                                    	<div onClick = {()=>{history.push('/cloud')}}
                                		style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }}>
                                        AVAP Cloud Professional
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Monolith to Microservices
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Power OpenAI Applications
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer',
            marginBottom:'35px'
        }}>
        Mobile Application API Development
    </div>
*/}


                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Business
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div onClick = {()=>{history.push('/cloud')}}
                                        style = {{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        AVAP Cloud Business
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Secure and Govern APIs
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Gateway for Istio
    </div>
*/}
                                </div>


                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Corporate
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div onClick = {()=>{history.push('/cloud')}}
                                        style = {{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        AVAP Cloud Enterprise
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Decentralized Load Balancing
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Zero Trust Security
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Observability
    </div>
    */}
                                </div>

                            </div>
                        </div></>
}

{menu3 &&

                    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '148px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*} Partner Ecosystem*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        display: 'none',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>



                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*Find a Partner*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        display: 'none',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>



                                </div>


                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*Become a partner*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        display: 'none',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>


                                </div>
                            </div>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        {/*Partner Login*/}
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: 'transparent',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer',
                                        marginBottom: '35px'
                                    }}>
                                    </div>
                                </div>
                            </div>
                        </div></>
}

{ menu4 &&
    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '216px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Developer Support
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}
                                    onClick={()=>{history.push('/default')}}
                                    >
                                        AVAP Developer Portal
                                    </div>
                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }}
                                    onClick={()=>{window.open('https://speed-computing-1656.my.site.com/communities/s/','_blank')}}
                                    >
                                        AVAP Communities
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Case Studies
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Blog
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Content Library
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Learning Center
    </div>

*/}

                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Knowledge Center
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}
                                    onClick={()=>{window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US')}}
                                    >
                                        AVAP Alexandria
                                    </div>
                                    <div style={{
                                                paddingLeft: '20px',
                                                paddingTop: '10px',
                                                color: '#969696',
                                                cursor: 'pointer'
                                            }}
                                                onClick={() => { window.open('http://blog.avapframework.com') }}
                                            >
                                                AVAP Blog
                                            </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Mesh
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP AI Gateway
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Insomnia
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        AVAP Plugin Hub
    </div>
*/}
                                </div>
                                <div style={{
                                width: '33%'
                            }}>
                                <div className='landingFont3'
                                    style={{
                                        paddingLeft: '20px',
                                        paddingTop: '30px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    API Products
                                </div>
                                <div style={{
                                    height: '1px',
                                    backgroundColor: '#d28e22',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    cursor: 'pointer'
                                }}>
                                </div>


                                <div onClick={()=>{history.push('/avap-marketplace')}} 
                                    style={{
                                    paddingLeft: '20px',
                                    paddingTop: '10px',
                                    color: '#969696',
                                    cursor: 'pointer'
                                }}>
                                    AVAP Marketplace
                                </div>
                            </div>
                                {/*}
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Community
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                About Us
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Join the Community
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Livestreams & Events
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Champions
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Discuss
            </div>
        </div>
    
        </div>
    
        <div style={{
            width:'100%',
            display:'flex'
            }}>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px',
                    cursor:'pointer'
                }}
            >
                Developer
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Contributor Program
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer',
                    marginBottom:'34px',
                }}>
                GitHub
            </div>
    
        </div>
    
        <div style={{
            width:'33%'
        }}>
            <div className='landingFont3'
                style={{
                    paddingLeft:'20px',
                    paddingTop:'30px'
                }}
            >
                Academy
            </div>
            <div style={{
                height:'1px',
                backgroundColor:'#d28e22',
                marginLeft:'20px',
                marginRight:'20px',
                    cursor:'pointer'
            }}>
            </div>
    
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    cursor:'pointer'
                }}>
                Certification
            </div>
            <div style={{
                    paddingLeft:'20px',
                    paddingTop:'10px',
                    color:'#969696',
                    marginBottom:'34px',
                    cursor:'pointer'
                }}>
                Education
            </div>
        </div> */}
                            </div>

                        </div></>
}
{ menu5 &&
    <><div style={{
                        width: '80%',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        marginTop: '52px',
                        position: 'absolute',
                        height: '2px',
                        backgroundColor: '#ebebeb'
                    }}></div><div className='fade-in-image' style={{
                        marginTop: '246px',
                        marginLeft: '10%',
                        marginRight: 'auto',
                        position: 'absolute',
                        width: '80%',
                        backgroundColor: 'white',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }}>


                            <div style={{
                                width: '100%',
                                display: 'flex'
                            }}>

                                <div style={{
                                    width: '33%',
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Company
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer',
                                    }}>
                                    </div>

                                    <div onClick={()=>{history.push('/about')}} 
                                        style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        About Us
                                    </div>
                                    <div style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom: '34px'
                                    }} onClick={()=>{window.open('mailto: info@101obex.com','_self')}}> 
                                        Contact Us
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Press Room
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Investors
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Contact Us
    </div>
*/}

                                </div>

                                <div style={{
                                    width: '33%'
                                }}>
                                    <div className='landingFont3'
                                        style={{
                                            paddingLeft: '20px',
                                            paddingTop: '30px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        Legal
                                    </div>
                                    <div style={{
                                        height: '1px',
                                        backgroundColor: '#d28e22',
                                        marginLeft: '20px',
                                        marginRight: '20px',
                                        cursor: 'pointer'
                                    }}>
                                    </div>

                                    <div onClick={()=>{window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US')}} 
                                        style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        Terms & Conditions AVAP Collaborative Networks
                                    </div>
                                    <div onClick={()=>{window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-cloud-professional-business-terms-conditions-MCOYQYTA5VQNDZDAS5UGXSU3GEV4?language=en_US')}} 
                                        style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer'
                                    }}>
                                        Terms & Conditions AVAP Cloud
                                    </div>
                                     <div onClick={()=>{window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/privacy-policy-MCSYZARSDENBDAVITQIIO6OWFYLI?language=en_US') }}
                                        style={{
                                        paddingLeft: '20px',
                                        paddingTop: '10px',
                                        color: '#969696',
                                        cursor: 'pointer',
                                        marginBottom:'34px'
                                    }}>
                                        Privacy Policy
                                    </div>
                                    {/*}
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Documentation
    </div>
    

</div>


<div style={{
    width:'33%'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px',
            cursor:'pointer'
        }}
    >
        Events
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        API Summit by AVAP
    </div>
    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Upcomming Events
    </div>
    
</div>

</div>

<div style={{
    width:'100%',
    display:'flex'
    }}>

<div style={{
    width:'33%'
}}>
    

</div>

<div style={{
    width:'33%'
}}>

</div>

<div style={{
    width:'33%',
    marginTop:'-100px',
    marginBottom:'35px'
}}>
    <div className='landingFont3'
        style={{
            paddingLeft:'20px',
            paddingTop:'30px'
        }}
    >
        Security
    </div>
    <div style={{
        height:'1px',
        backgroundColor:'#d28e22',
        marginLeft:'20px',
        marginRight:'20px',
            cursor:'pointer'
    }}>
    </div>

    <div style={{
            paddingLeft:'20px',
            paddingTop:'10px',
            color:'#969696',
            cursor:'pointer'
        }}>
        Trust and Compilance
    </div>
    
    */}

                                </div>

                            </div>
                        </div></>
}

        </Navbar>



      <div onClick={()=>{cleanMenuAll()}}>

            <div onClick={()=>{cleanMenuAll()}} className="d-flex flex-column align-items-center">


                <div style={{paddingBottom:'80px'}} className='d-flex flex-column  min-vh-100 align-items-center'>

                <Image src={cabecera} style={{ marginTop:'40px' }} width="" alt="Logo" />
                <Image src={logo_framework_titulo} style={{ 
                    marginTop:'80px', 
                    position:'relative', 
                    width:'500px'
                    }} width="" alt="Logo" />
{/*AVAP Gateway Advanced: a real and intelligent gateway for your API consumption traffic*/}
                <div
                    className='landingFont0'
                    style={{
                        marginTop:'80px', 
                        position:'relative', 
                        color:'black',
                        lineHeight:'50px'
                    }}                
                >AVAP Gateway Advanced:<span className='textBold'> a real and</span></div>

                
                <div
                    className='landingFont0'
                    style={{
                        marginTop:'0px', 
                        position:'relative', 
                        color:'black',
                        lineHeight:'50px'
                    }}                
                ><span className='textBold'>intelligent</span> gateway for your API <span className='textBold'></span></div>
<div className='landingFont0' style={{ color:'black' }}>consumption traffic</div>
                <div
                    className='landingFont3'
                    style={{
                        marginTop:'80px', 
                        position:'relative', 
                        color:'black',
                        marginLeft:'25%',
                        marginRight:'25%',
                        textAlign:'center'
                    }}                
                >More powerful than a proxy, it assesses consumption history, scenarios, and established conditions to swiftly and efficiently redirect to different servers. LUA programming drives its functionality.
AVAP Gateway Advanced is both universal and powerful, boasting compatibility with any language server.</div>
                
                </div>
            </div>
         
            <div style={{
                backgroundColor: '#ffffff',
                height: '100%',
                textAlign:'center',
                paddingTop:'30px'
            }}>
                {/*}
                <div className='landingFont2 textBold'>
                Ready to transform API development?
                </div>
                <div className='landingFont3b textBold'>
                Register at AVAP ID
                </div>

            
                <button 
                onClick={()=>{history.push('/Register')}}
                    className='custom-button-emerald textBold'
                    style={{ marginTop:'20px' }}
                >
                    Create my AVAP ID
                </button>
*/}
            </div>
            <div style={{
                backgroundColor: '#fafafa',
                height: '100%',
                textAlign:'center',
                paddingTop:'30px',
                paddingBottom:'80px'
            }}>

                {/*}
                <div 
                    className='landingFont1'
                >
                    The total API solution
                </div>
                <div 
                    className='landingFont1'
                >
                   
                </div>
                <div 
                    className='landingFont3'
                    style={{ 
                        marginTop:'20px',
                        width:'33%', 
                        marginLeft:'auto', 
                        marginRight:'auto' 
                    }}
                >
                    Next-gen technology with AI embedded in its DNA, offering scalability and profitability through its Virtuality.
                </div>

*/}
                    <div 
                        id='bloque de columnas'
                        style={{
                            width:'50%',
                            marginLeft:'auto',
                            marginRight:'auto',
                            paddingTop:'80px',
                        }}
                    >
                        <div style={{ display:'flex' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={ga_1} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>High level of optimization</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>A more powerful and much smarter proxy, customized through LUA programming scripts.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={ga_2} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>Intelligent routing</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>AVAP Gateway Advanced acts as an intelligent proxy, analyzing consumption patterns, scenarios, and parameters set by the administrator.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={ga_3} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>Flexible routing composition</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Allows nesting multiple instances of AVAP Gateway Advanced to manage redirections as complex as required by the administrator.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={ga_4} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>Scalability</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>AVAP Gateway Advanced is provided dockerized, enabling simple and straightforward scalability.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={ga_5} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>Docker Platform</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>A server that automatically scales and adjusts its resources based on demand to accommodate a higher number of processes.</div>
                            </div>
                        </div>
                        <div style={{ display:'flex', paddingTop:'50px' }}>
                            <div style={{ width:'20%' }}>
                            <Image src={ga_6} style={{ width:'50%' }} width="" />
                            </div>
                            <div style={{ width:'80%' }}>
                            <div style={{ marginTop:'0px', textAlign: 'start' }} className='landingFont2 textBold'>High-level security</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Implements TLS 1.3 security standards for redirection operations.</div>
                            </div>
                        </div>

                        {/*}
                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={lenguaje} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Lenguaje Alto Nivel</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Foco en las necesidades de creación de APIs, implementando procesos básicos como comandos del lenguaje y gestionando las estructuras de datos de entrada y salida como estructuras de datos nativas.</div>
                        </div>

                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={virtualidad} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Virtualidad</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>El atributo de virtualidad del lenguaje AVAP se da ya que sus definiciones dinámicas se alojan en la nube. Por lo que, para el desarrollo, el servidor de lenguaje únicamente lee los comandos, recupera estas definiciones y compone un Virtual Code que ejecuta la máquina virtual (parseador)</div>
                        </div>


                        <div 
                            style={{
                                width:'33%',
                                paddingLeft:'10px',
                                paddingRight:'10px'
                            }}>
                            <Image src={inteligencia} style={{ width:'70%' }} width="" />
                            <div style={{ marginTop:'20px' }} className='landingFont2 textBold'>Inteligencia</div>
                            <div style={{ marginTop:'20px', textAlign: 'start' }} className='landingFont3b'>Al es parte de nuestro ADN, integrándose en todas las funciones y capacidades de AVAP, desde la asistencia, desarrollo y hasta el enrutamiento de servidores.</div>
                        </div>
*/}



                    </div>
                    

            </div>




 <div style={{ backgroundColor:'#ffffff', height:'100%',
                                        paddingTop:'80px',
                                        paddingBottom:'80px',
                                        textAlign:'start'
             }}>
                <div                    className='landingFont0'
                    style={{
                        marginTop:'0px', 

                        position:'relative', 
                        color:'black',
                        lineHeight:'50px',
                        textAlign:'center'
                    }}                
                >Top AVAP Gateway</div> 
                                <div                    className='landingFont0'
                    style={{
                        marginTop:'0px', 

                        position:'relative', 
                        color:'black',
                        lineHeight:'50px',
                        textAlign:'center'
                    }}                
                >Advanced<span className='textBold'>Benefits</span></div>
                <div                    className='landingFont0'
                    style={{
                        marginTop:'0px', 

                        position:'relative', 
                        color:'black',
                        lineHeight:'50px',
                        textAlign:'center'
                    }}                
                ></div>

                    <div style={{ paddingTop:'40px', width:'100%', display:'flex'}}>

                        <div style={{ width:'50%' }}>
                            <div className='landingFont3b' style={{ marginTop:'20px', width:'80%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Optimization in infrastructure investment for maximum performance.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'80%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Intelligent traffic management, transparent and efficient.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'80%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Low energy consumption.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'80%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Easy installation.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'80%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Deployment setting made easy.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'80%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>High-level security protection.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'80%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Al-managed routing prediction.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'80%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Efficiency, quick and flexible solution.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'80%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Advanced monitoring and real-time analytics to optimize API performance and availability.</div>

                            <div className='landingFont3b' style={{ marginTop:'10px', width:'80%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Scalability to handle sudden traffic and demand spikes.</div>
    {/*<div className='landingFont3b' style={{ marginTop:'10px', width:'60%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Ensure security and control over data and API access management.</div>

    <div className='landingFont3b' style={{ marginTop:'10px', width:'60%', marginRight:'10px', marginLeft:'auto' }}><Image style={{marginRight:'10px', width:'15px'}} src={vineta_CN}></Image>Access detailed documentation and educational resources to facilitate integration and use of AVAP Framework.</div>*/}
                        </div>
                    <div style={{ width:'50%' }}>
                        <div style={{marginTop:'0px', paddingLeft:'10px'}} className='landingFont2 textBold'>Productivity and Cost Efficiency</div>
                            <Image src={gafico_cn_i} style={{ width:'80%', marginLeft:'10px', marginTop:'20px', marginRight:'auto' }} width="" />

                    </div>

            </div>

        </div>
    



            <div>
                <FooterMenuAccess green={true}/>
            </div>

        </div>
        </div>
        </BrowserView>
            <MobileView>
                <Navbar id='frameworkNav' collapseOnSelect expand="lg" bg="light" variant="light" className='fixed-top' style={{ width: '100%' }}>
                    <Container>
                        <Navbar.Brand href="/framework">
                            <Image src={logo_framework} style={{marginTop:'-10px', paddingLeft:'15px'}} alt="Logo" className="mb-1" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='mr-2' >
                            <div className='hamburguer' style={{marginTop:'-10px'}}>
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Navbar.Toggle>
                        <Navbar.Collapse id="responsive-navbar-nav"  style={{backgroundColor:'white', marginTop:'-20px'}}  className="w-100 justify-content-between align-items-center">
                            <div className="d-flex flex-wrap">
                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu1(!menu1); }} className='hideLink'>
                                    Products
                                    <div hidden={!menu1} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Products" id="DropdownProducts" className='showLink'>

                                    <div className="section-header">Build Virtual APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                        AVAP (Advanced Virtual API Programming)
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/vsc-extensions'); }}>
                                        AVAP API Extensions for Microsoft Visual Code
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/devstudio'); }}>
                                        AVAP Dev Studio
                                    </div>


                                    <NavDropdown.Divider />
                                    <div className="section-header">Test & Deliver APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/version-control'); }}>
                                        AVAP API Version Control
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/api-publisher'); }}>
                                        AVAP API Publisher
                                    </div>


                                    <NavDropdown.Divider />
                                    <div className="section-header">Run APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/avs'); }}>
                                        AVAP Virtual Server
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                        AVAP Interpreter & Language Server
                                    </div>

                                </NavDropdown>



                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu2(!menu2); }} className='hideLink'>
                                    Services
                                    <div hidden={!menu2} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Services" id="DropdownProducts" className='showLink'>
                                    <div className="section-header">Professional</div>
                                    <div className="section-content" onClick={() => { history.push('/collaborative'); }}>
                                        AVAP Collaborative Network
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Professional
                                    </div>
                                    <NavDropdown.Divider />
                                    <div className="section-header">Business</div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Business
                                    </div>


                                    <NavDropdown.Divider />
                                    <div className="section-header">Corporate</div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Enterprise
                                    </div>

                                </NavDropdown>






                                <Nav.Link onClick={() => { history.push('/avapid'); cleanMenuAll(); }}>
                                    AVAP ID
                                    <div hidden={!menu3} className="menu-highlight"></div>
                                </Nav.Link>

                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu4(!menu4); }} className='hideLink'>
                                    Resources
                                    <div hidden={!menu4} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Resources" id="DropdownProducts" className='showLink'>
                                    <div className="section-header">Developer Support</div>
                                    {/*<div className="section-content" onClick={() => { history.push('/default'); }}>
                                        AVAP Developer Portal
                                    </div>*/}
                                    <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'); }}>
                                        AVAP Communities
                                    </div>
                                    <NavDropdown.Divider />
                                    <div className="section-header">Knowledge Center</div>
                                    <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US'); }}>
                                        AVAP Alexandria
                                    </div>
                                    <div className="section-content" onClick={() => { window.open('http://blog.avapframework.com'); }}>
                                        AVAP Blog
                                    </div>
                                    <NavDropdown.Divider />
                                    {/*<div className="section-header">API Products</div>
                                   {/* <div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
                                        AVAP Marketplace
                                    </div>*/}*/}
                                </NavDropdown>


                                <Nav.Link onClick={() => { cleanMenuAll(); setmenu5(!menu5); }} className='hideLink'>
                                    Company
                                    <div hidden={!menu5} className="menu-highlight"></div>
                                </Nav.Link>
                                <NavDropdown title="Company" id="DropdownProducts" className='showLink'>
                                    <div className="section-content" onClick={() => { history.push('/about'); }}>
                                        About Us
                                    </div>
                                    <div className="section-content" onClick={() => { window.open('mailto:info@101obex.com', '_self'); }}>
                                        Contact Us
                                    </div>
                                </NavDropdown>
                            </div>
                            {/*<button
                                className="greenbutton"
                                onClick={() => { history.push('/login'); }}
                            >
                                Sign in with your AVAP ID
                            </button>*/}
                        </Navbar.Collapse>
                    </Container>
                </Navbar>


                {menu1 && (
                    <>

                        <div className="menu-dropdown ">
                            <div className="d-flex">
                                <div className="section">
                                    <div className="section-header">Build Virtual APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                        AVAP (Advanced Virtual API Programming)
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/vsc-extensions'); }}>
                                        AVAP API Extensions for Microsoft Visual Code
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/devstudio'); }}>
                                        AVAP Dev Studio
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">Test & Deliver APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/version-control'); }}>
                                        AVAP API Version Control
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/api-publisher'); }}>
                                        AVAP API Publisher
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">Run APIs</div>
                                    <div className="section-content" onClick={() => { history.push('/avs'); }}>
                                        AVAP Virtual Server
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/avap'); }}>
                                        AVAP Interpreter & Language Server
                                    </div>
                                </div>
                            </div>
                        </div>


                    </>
                )}

                {menu2 && (
                    <>
                        <div className="menu-dropdown">
                            <div className="d-flex">
                                <div className="section">
                                    <div className="section-header">Professional</div>
                                    <div className="section-content" onClick={() => { history.push('/collaborative'); }}>
                                        AVAP Collaborative Network
                                    </div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Professional
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">Business</div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Business
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">Corporate</div>
                                    <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                                        AVAP Cloud Enterprise
                                    </div>
                                </div>
                            </div>
                        </div>


                    </>
                )}

                {menu3 && (
                    <>
                        <div className="menu-dropdown">
                            <div className="d-flex">
                                <div className="section">
                                    <div className="section-header">Partner Ecosystem</div>
                                    <div className="section-content" onClick={() => { /* Acción para esta sección */ }}>
                                        Find a Partner
                                    </div>
                                    <div className="section-content" onClick={() => { /* Acción para esta sección */ }}>
                                        Become a Partner
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {menu4 && (
                    <>
                        <div className="menu-dropdown">
                            <div className="d-flex">
                                <div className="section">
                                    <div className="section-header">Developer Support</div>
                                    {/*<div className="section-content" onClick={() => { history.push('/default'); }}>
                                        AVAP Developer Portal
                                    </div>*/}
                                    <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'); }}>
                                        AVAP Communities
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">Knowledge Center</div>
                                    <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US'); }}>
                                        AVAP Alexandria
                                    </div>
                                    <div className="section-content" onClick={() => { window.open('http://blog.avapframework.com'); }}>
                                        AVAP Blog
                                    </div>
                                </div>
                                <div className="section">
                                    <div className="section-header">API Products</div>
                                   {/* <div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
                                        AVAP Marketplace
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </>
                )}

                {menu5 && (
                    <>
                        <div className="menu-dropdown">
                            <div className="d-flex">
                                <div className="section">
                                    <div className="section-header">Company</div>
                                    <div className="section-content" onClick={() => { history.push('/about'); }}>
                                        About Us
                                    </div>
                                    <div className="section-content" onClick={() => { window.open('mailto:info@101obex.com', '_self'); }}>
                                        Contact Us
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}


                {/* <Disclaimer /> */}

                <div id='mobileGateway' className="wrapper" >

                    <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabecera})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='logos' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                Gateway Advanced: a real and intelligent gateway for your API consumption traffic.
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5  mt-5'>
                                Manage and publish your APIs in both development and production environments using AVS (AVAP Virtual Server).
                                Integrated with the AVAP code repository in the cloud, AVS can manage multiple versions of each API code developed in AVAP,
                                 and conduct necessary tests for deployment and publication across diverse environments. AVS is compatible with all language and API servers. Maximize the benefits of AVAP technology without sacrificing your existing APIs.
                                </p>

                                {/* <button onClick={() => { history.push('/Register') }} className='greenbutton m-auto d-table'> Try it</button> */}
                            </div>
                        </div>

                    </section>
                    <section id='items' className='w-100 bg-division mt-5'>
                        <div className="container">
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image className='mt-5' src={ga_1} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold  title'>
                                        High level of optimization</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Manage the creation of AVAP code repositories and oversee the publication of API versions across multiple environments.
                                        Simplify the management and control of unit testing for deployment in these environments.
                                    </div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={ga_2} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Intelligent routing</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        AVAP Gateway Advanced acts as an intelligent proxy, analyzing consumption patterns, scenarios,
                                        and parameters set by the administrator.
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={ga_3} style={{ width: '50%' }} width="" /></div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                        Flexible routing composition
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        Allows nesting multiple instances of AVAP Gateway Advanced to manage redirections as complex
                                        as required by the administrator.
                                    </div>

                                </div>
                            </div>
                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={ga_4} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>Scalability</div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                        AVAP Gateway Advanced is provided dockerized, enabling simple and straightforward scalability.
                                    </div>

                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-md-2">
                                    <Image src={ga_5} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                    Docker Platform
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                    A server that automatically scales and adjusts its resources based on demand to accommodate a higher number of processes.
                                    </div>

                                </div>
                            </div>
                            <div className="row pb-7">
                                <div className="col-md-2">
                                    <Image src={ga_6} style={{ width: '50%' }} width="" />
                                </div>
                                <div className="col-md-10">
                                    <div style={{ marginTop: '0px', textAlign: 'start' }} className='landingFont2 textBold title'>
                                    High-level security
                                    </div>
                                    <div style={{ marginTop: '20px', textAlign: 'start' }} className='landingFont3b paragraph'>
                                    Implements TLS 1.3 security standards for redirection operations.
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>

                    <section id="beneficts" className='container mt-5 pb-5'>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='hero-title text-center'>
                                Top AVAP Gateway
                                Advanced<span className=' textBold'>Benefits</span>
                                </h1>
                            </div>
                            <div className="col-md-12">
                                <p className='title'>Productivity and Cost Efficiency</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <Image src={gafico_cn_i} style={{ width: '100%', padding: "10px" }} width="" />
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Optimization in infrastructure investment for maximum performance.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Intelligent traffic management, transparent and efficient.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Low energy consumption.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Easy installation.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Deployment setting made easy.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    High-level security protection.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Al-managed routing prediction.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Efficiency, quick and flexible solution.
                                </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Advanced monitoring and real-time analytics to optimize API performance and availability.
                                </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Scalability to handle sudden traffic and demand spikes.
                                </div>

                            </div>
                        </div>
                    </section>




                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>
        </>
    )
}

export default NewLandingCloud;