import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';


type addCreditCardProps = {
  show: boolean,
  handleClose: () => void,
  isGreen?: boolean,
  spanish: boolean
}
const addCreditCardModal:FunctionComponent<addCreditCardProps> = (props) => {

  const [cardNumber, setCardNumber] = useState(0);
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvv, setCVV] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [entrada, setEntrada] = useState(true);


  const yearInputRef = useRef(null);
  const cvvInputRef = useRef(null);

  const changeCardNumber = (e) => {
    var newCardNumber = e.target.value.split(" ").join(""); // remove hyphens
    setCardNumber(newCardNumber);
    if (newCardNumber.length > 0) {
      newCardNumber = newCardNumber.match(new RegExp('.{1,4}', 'g')).join(" ");
      e.target.value = newCardNumber;
    }
  }

  const changeMonth = (e) => {
    const newMonth = e.target.value;
    setMonth(newMonth);
    if (newMonth.toString().length == e.target.maxLength){
      yearInputRef.current.focus();
    }
  }

  const changeYear = (e) => {
    const newYear = e.target.value;
    setYear(newYear);
    if (newYear.toString().length == e.target.maxLength){
      cvvInputRef.current.focus();
    }
  }

  const changeCardholder = (e) => {
    const newCardholder = e.target.value;
    setCardholder(newCardholder);
  }

  const changeCVV = (e) => {
    const newCVV = e.target.value;
    setCVV(newCVV);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    setError('');// Limpiamos los posibles errores
    let monthe = month;
    if (monthe.length==1) monthe = `0${monthe}`
    const payload = {
      card_number: cardNumber,
      month_exp: monthe,
      year_exp: year,
      cvv: cvv,
      card_name: cardholder
    }
    let result
    
    try{
      result = await ObexRequestHandler.post('/cards', payload);
    

    const { success, data, message } = result;
    if (success){

      setLoading(false);
      setSuccess(data);
        setTimeout(function() { 
          setSuccess('');
          setSubmitDisabled(true);
          console.log("AÑADIDA");
          props.handleClose();
          }.bind(this), 1000)
    } else {
      setError(props.spanish ? 'No podemos añadir tu tarjeta, por favor revisa los datos indicados':'Can\'t add your Card, pleas review it');
      setLoading(false);

      setTimeout(function() { 
        setError('');
        }.bind(this), 5000)


    }

  } catch(error){
    console.log(error)
    setError(error);
    setError(props.spanish ? 'No podemos añadir tu tarjeta, por favor revisa los datos indicados':'Can\'t add your Card, pleas review it');
  }

  };

  useEffect(() => {
    //setError('');
    if (entrada) {document.getElementById('entrada-cardholder').focus();setEntrada(false);}
    setSuccess('');
    if (cardNumber && month && year && cvv && cardholder){
      setSubmitDisabled(false);

    }
  })
  return(
    <Modal style={{ marginTop:'7rem' }} dialogClassName="addons-dialog obex-dialog"
      show={props.show} 
      onHide={props.handleClose}
      animation={false}
      scrollable>
      <Modal.Header  closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Col md="12" className="addons-title">
            <span>{props.spanish ? 'Añade un método de pago':'Add Payment Method'}</span>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="10" className="mx-auto">
            <Form className="obex-form payment-method-form" onSubmit={handleSubmit}>
              {/* <Form.Row className="py-2">
                <Form.Label column sm="12" className="obex-form-label">
                  How would you like to pay?
                </Form.Label>
                <Col md="1" className="text-right py-1 pr-5">
                  <Form.Check
                    type="radio"
                    value="CreditCard"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                  />
                </Col>
                <Col className="type-payment-method py-1 pl-3">
                  <span><FontAwesomeIcon icon={faCreditCard}/> Credit Card</span>
                </Col>
              </Form.Row>
              <Form.Row className="py-2 mb-3">
                <Col md="1" className="text-right py-1 pr-5">
                  <Form.Check
                    type="radio"
                    value="PayPal"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios2"
                  />
                </Col>
                <Col className="type-payment-method py-1 pl-3">
                  <span><FontAwesomeIcon icon={faPaypal}/> Paypal</span>
                </Col>
              </Form.Row> */}
              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  {props.spanish ? "Nombre del titular":"Cardholder's name*"}
                </Form.Label>
                <Form.Control id='entrada-cardholder' required type="text" placeholder={props.spanish ? "Nombre del titular":"Cardholder's name"} onChange={changeCardholder}/>
              </Form.Row>
              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  {props.spanish ? "Número de la tarjeta":"Credit card number*"}
                </Form.Label>
                <Col md="12">
                  <Form.Control required type="text" maxLength={19} placeholder={props.spanish ? "Número de la tarjeta" : "Card Number"} onChange={changeCardNumber}/>
                </Col>
              </Form.Row>
              <Form.Row className="mb-3">
                <Col md="4" className="ml-auto">
                  <Row>
                    <Form.Label column sm="12" className="obex-form-label">
                      {props.spanish ? 'Válida hasta*':'Expiration date*'}
                    </Form.Label>
                    <Col md="6">
                      <Form.Control required type="text" maxLength={2} placeholder={props.spanish ? "Mes":"Month"} onChange={changeMonth}/>
                    </Col>
                    <Col md="6" className="pl-0">
                      <Form.Control required type="text" maxLength={4} placeholder={props.spanish ? "Año":"Year"} onChange={changeYear}/>
                    </Col>
                  </Row>
                </Col>
                <Col md="3">
                  <Form.Label column sm="12" className="obex-form-label">
                    CVV/CVV2*
                  </Form.Label>
                  <Form.Control required type="text" maxLength={4} placeholder="CVV" onChange={changeCVV}/>
                </Col>
              </Form.Row>
              
              <Form.Row className="my-5" style={{ marginTop:'20px' }}>
              <Col md="4" className="ml-auto" style={{ position:'absolute' }}>
                  <button onClick={ props.handleClose } style={{ marginLeft: '0px', paddingLeft:'0px', paddingRight:'0px'}} className={`custom-button-${props.isGreen?'orange':'orange'}  w-100 textBold `}>
                      {props.spanish ? 'Atrás' : 'Go Back'}
                  </button>
                </Col>
                <Col md="4" className="ml-auto">
                  <button style={{ paddingLeft:'0px', paddingRight:'0px' }} disabled={submitDisabled} type="submit" className={`custom-button-${props.isGreen?'green':'blue'}  w-100 textBold `}>
                      {props.spanish ? 'Añadir Pago':'Add Payment'} {success && <FontAwesomeIcon icon={faCheck}/>}
                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                  </button>
                </Col>
              </Form.Row>
              {error && <Alert variant="danger">
              {`${error}`}
            </Alert>}
            </Form>

          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default addCreditCardModal;