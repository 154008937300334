
import React, { FunctionComponent, useEffect, useRef, useState, Fragment } from 'react';
import { Modal, Navbar, Row, Col, Image, Button, Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import SessionService from '../../services/SessionsService';
import ilustra_cierre from '../../assets/img/Ilustracion_SessionExpired_blue.png';

type inactivityProps = {
  show: boolean,
  closeSession: (e) => void,
  renewSession: (e) => void
}

const InactivityModal:FunctionComponent<inactivityProps> = (props: inactivityProps) => {

  const { show, closeSession, renewSession } = props;

  const history = useHistory();

  const [secondsLeft, setSecondsLeft] = useState(200);

  useEffect(() => {
    let interval;
    let seconds = secondsLeft;
    if (show) {
      interval = setInterval(() => {
        seconds--;        
          setSecondsLeft(seconds);
          if (seconds <= 0) { 
            clearInterval(interval);
            SessionService.dispatchSessionExpiredEvent();
            return; 
          }
      }, 1000);
    }
    return () => {
      clearInterval(interval); // El return en el use effect ejectuta la función dada cuando el componente se desmonta  }
      setSecondsLeft(20);
    }
  }, [show]);

  return(
    <Modal dialogClassName="addons-dialog obex-dialog w-50"
      show={show}
      animation={false}>
      <Modal.Header className="border-bottom-0">
        <Modal.Title className="text-center w-100">
          <Row>
            <Col className="addons-title text-center">
              <span>Inactivity detected</span>
            </Col>
          </Row>
          <Row>
            <Col className="text-center dialog-subtitle my-4">
              <span>By security reasons we are going to close your session in:</span>
            </Col>
          </Row>

          <Row>
          <Col md="5" className="my-3 mx-auto">
            <Image src={ilustra_cierre} fluid />
          </Col>
          </Row>          


          <Row>
            <Col className="text-center dialog-subtitle my-4">
              <span><b>{secondsLeft}</b></span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header> 
      <Modal.Body>
        <Row className="mb-3">
          <Col md="3" className="ml-auto">
            <button style={{borderRadius:'0.5rem', height:'34px', padding:'0px'}}  className="btn-orange custom-button-orange textBold btn-block" onClick={closeSession}>Close Session</button>
          </Col>
          <Col md="3" className="mr-auto">
            <button style={{padding:'1px', height:'34px', borderRadius:'8px'}}disabled={secondsLeft <= 0}className="custom-button-blue textBold btn-block" onClick={renewSession}>Renew Session</button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default InactivityModal;