import React from "react";
import DisplayLanguage from "../../newViews/Wiki/1_GetStarted/1_DisplayLanguage/DisplayLanguage";
import Themes from "../../newViews/Wiki/1_GetStarted/2_Themes/Themes";
import KeyBindings from "../../newViews/Wiki/1_GetStarted/3_KeyBindings/KeyBindings";
import Settings from "../../newViews/Wiki/1_GetStarted/4_Settings/Settings";
import GETSTARTED_Telemetry from "../../newViews/Docs/30_Get_Started/16_GETSTARTED_Telemetry/GETSTARTED_Telemetry";
import GET_STARTED_Tips_and_tricks from "../../newViews/Docs/30_Get_Started/12_GET_STARTED_Tips_and_tricks/GET_STARTED_Tips_and_tricks";
import OVERVIEW from "../../newViews/Docs/49_overview/OVERVIEW";
import SETUP_Additionalcomponentsandtools from "../../newViews/Docs/69_set/SETUP_Additional components and tools";
import SETUP_Enterprise from "../../newViews/Docs/69_set/SETUP_Enterprise";
import SETUP_Linux from "../../newViews/Docs/69_set/SETUP_Linux";
import SETUP_MacOS from "../../newViews/Docs/69_set/SETUP_MacOS";
import SETUP_Network from "../../newViews/Docs/69_set/SETUP_Network";
import SETUP_Overview from "../../newViews/Docs/69_set/SETUP_Overview";
import SETUP_RaspberryPi from "../../newViews/Docs/69_set/SETUP_RaspberryPi";
import SETUP_Uninstall from "../../newViews/Docs/69_set/SETUP_Uninstall";
import SETUP_Windows from "../../newViews/Docs/69_set/SETUP_Windows";
import SOURCE_CONTROL_CollaborateOnGitHub from "../../newViews/Docs/71_SOURCE/SOURCE_CONTROL_CollaborateOnGitHub";
import SOURCE_CONTROL_FAQ from "../../newViews/Docs/71_SOURCE/SOURCE_CONTROL_FAQ";
import SOURCE_CONTROL_IntroductionToGit from "../../newViews/Docs/71_SOURCE/SOURCE_CONTROL_IntroductionToGit";
import SOURCE_CONTROL_Overview from "../../newViews/Docs/71_SOURCE/SOURCE_CONTROL_Overview";
import TERMINAL_Advanced from "../../newViews/Docs/75_Terminal/TERMINAL_Advanced";
import TERMINAL_Appearance from "../../newViews/Docs/75_Terminal/TERMINAL_Appearance";
import TERMINAL_Shell_Integration from "../../newViews/Docs/75_Terminal/TERMINAL_Shell_Integration";
import TERMINAL_TerminalBasics from "../../newViews/Docs/75_Terminal/TERMINAL_TerminalBasics";
import TERMINAL_TerminalProfiles from "../../newViews/Docs/75_Terminal/TERMINAL_TerminalProfiles";
import USERGUIDE_BasicEditing from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE-BasicEditing";
import USER_GUIDE_Accesibility from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_Accesibility";
import USER_GUIDE_AVAPforTheWeb from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_AVAPforTheWeb";
import USER_GUIDE_CodeNavigation from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_CodeNavigation";
import USER_GUIDE_CommandLineInterface from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_CommandLineInterface";
import USER_GUIDE_CustomLayout from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_CustomLayout";
import USER_GUIDE_Debugging from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_Debugging";
import USER_GUIDE_Emmet from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_Emmet";
import USER_GUIDE_ExtensionMarketplace from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_ExtensionMarketplace";
import USER_GUIDE_MultiRootWorkspaces from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_MultiRootWorkspaces";
import USER_GUIDE_IntelliSense from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_IntelliSense";
import USER_GUIDE_Profiles from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_Profiles";
import USER_GUIDE_Refactoring from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_Refactoring";
import USER_GUIDE_SettingsSync from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_SettingsSync";
import USER_GUIDE_Snippets from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_Snippets";
import USER_GUIDE_Tasks from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_Tasks";
import USER_GUIDE_WorkspaceTrust from "../../newViews/Docs/79_UserGUIDE/USER_GUIDE_WorkspaceTrust";
import Cli from "../../newViews/Wiki/1_Cli/cli";
import Software_Downloads from "../../newViews/Docs/d2_software_downloads";
import AVAP_ID from "../../newViews/Docs/d3_gs_avap_id";
import AVAP_Organizations from "../../newViews/Docs/d4_gs_organizations";
import AVAP_Teams from "../../newViews/Docs/d5_gs_teams";
import AVAP_Ambients from "../../newViews/Docs/d6_gs_ambients";
import AVAP_API_Products from "../../newViews/Docs/d7_gs_api_products";
import AVAP_API_Projects from "../../newViews/Docs/d8_gs_api_projects";
import My_First_API from "../../newViews/Docs/d9_gs_my_first_api";
import Developer_Token from "../../newViews/Docs/d10_gs_developer_token";
import MSVC_Extensions from "../../newViews/Docs/d11_gs_msvc_extensions";
import Collaborative_Network from "../../newViews/Docs/d12_gs_collaborative";
import AVAP_Cloud from "../../newViews/Docs/d13_gs_cloud";
import AVAP_CLI from "../../newViews/Docs/d15_gs_avapcli";
import Infrastructure_AVS from "../../newViews/Docs/d14_gs_infrastructure_avs";
import AVAP_AVS from "../../newViews/Docs/d16_gs_avapavs";
import LoginSample from "../../newViews/Docs/d17_gs_samples-login";
import HelloSample from "../../newViews/Docs/d17_gs_samples-hello";
const Currencies_And_Fx_Exchange = React.lazy(() => import("../../newViews/Docs/3_Currencies_And_Fx_Exchange"));




const  routerWiki =
{
    "showFirst":{
        "id": "Currencies_And_Fx_Exchange",
        "links": [
            {
                "path": "Currencies_And_Fx_Exchange",
                "title": "Currencies_And_Fx_Exchange",
                "view":<AVAP_ID/>
            }
        ],
        "title": "Getting Started with AVAP ID"
    },
    "menus": [

        {
            "id": "over_view_id",
            "path": "GET STARTED_DisplayLanguage_index_nf",
            "title": "AVAP ID",
            
            "links":[
                {   "path": "GET STARTED_DisplayLanguage",
                    "title": "Getting Started",
                    "view":<AVAP_ID/>,}
            ]
        },

        {
            "id": "over_view_cloud",
            "path": "GET STARTED_DisplayLanguage_index_nf",
            "title": "AVAP Cloud",
            
            "links":[
                {            "path": "GET STARTED_Cloud",
                    "title": "Getting Started",
                    "view":<AVAP_Cloud/>,},
                {            
                    "path": "GET STARTED_Themes",
                    "title": "AVAP Organizations",
                    "view":<AVAP_Organizations/>,},
                    {            
                        "path": "GET STARTED_Teams",
                        "title": "AVAP Developer Teams",
                        "view":<AVAP_Teams/>,},
                        {            
                            "path": "GET STARTED_Ambients",
                            "title": "AVAP Ambients",
                            "view":<AVAP_Ambients/>,},
                            {            
                                "path": "GET STARTED_Projects",
                                "title": "AVAP API Projects",
                                "view":<AVAP_API_Projects/>,
                            },
                            {            
                                "path": "GET STARTED_Products",
                                "title": "AVAP API Products",
                                "view":<AVAP_API_Products/>,},
                              {            
                                    "path": "GET STARTED_My_1st_API",
                                    "title": "Creating my first API",
                                    "view":<My_First_API/>,}


            ]
        },

        {
            "id": "over_view_cn",
            "path": "GET STARTED_DisplayLanguage_index_nf",
            "title": "AVAP Collaborative Network",
            
            "links":[
                {   "path": "GET STARTED_DisplayLanguage",
                    "title": "Getting Started",
                    "view":<Collaborative_Network/>,}
            ]
        },

        {
            "id": "over_view_aefmvsc",
            "path": "GET STARTED_DisplayLanguage_index_nf",
            "title": "AVAP API Extensions for MVSC",
            
            "links":[
                {   "path": "GET STARTED_DisplayLanguage",
                    "title": "Getting Started",
                    "view":<MSVC_Extensions/>,}
            ]
        },

        {
            "id": "over_view_aefmvsc_cli",
            "path": "GET STARTED_DisplayLanguage_index_nf_cli",
            "title": "AVAP CLI",
            
            "links":[
                {   
                    "path": "GET STARTED_Dev_Cli",
                    "title": "Getting Started with AVAP CLI",
                    "view":<AVAP_CLI/>,
                },
                {   
                    "path": "GET STARTED_Dev_Token",
                    "title": "Create Your Developer Token",
                    "view":<Developer_Token/>,
                }
            ]
        },










 


 

        {
            "id": "over_view12",
            "path": "GET STARTED_Downloads_index",
            "title": "Software Downloads",
            
            "links":[{            "path": "GET STARTED_Downloads",
                "title": "How to Download AVAP Products?",
                "view":<Software_Downloads/>,}]
        },
        {
            "id": "over_view13",
            "path": "GET STARTED_AVS_index",
            "title": "AVS (AVAP Virtual Server)",
            
            "links":[
                {            
                    "path": "GET STARTED_AVS",
                    "title": "Getting Started",
                    "view":<AVAP_AVS/>,},
                {            
                "path": "GET STARTED_AVS_ARC",
                "title": "Network Architecture Types",
                "view":<Infrastructure_AVS/>,}]
        },
        {
            "id": "over_view13a",
            "path": "GET STARTED_AVS_index_samples",
            "title": "Code Samples",
            
            "links":[
                {            
                    "path": "GET STARTED_AVS_SAMPLE",
                    "title": "Hello World",
                    "view":<HelloSample/>,},
                    {            
                        "path": "GET STARTED_AVS_SAMPLE",
                        "title": "Authentication with user and password",
                        "view":<LoginSample/>,}
                ]
        },
        


        /*
        {
            "id": "getstarted",
            "links": [ 
                {
                    "path": "GET STARTED_DisplayLanguage",
                    "title": "AVAP ID",
                    "view":<AVAP_ID/>
                },
                {
                    "path": "GET STARTED_Themes",
                    "title": "AVAP Organizations",
                    "view":<AVAP_Organizations/>
                },
                {
                    "path": "GET STARTED_Teams",
                    "title": "AVAP Teams",
                    "view":<AVAP_Teams/>
                },
                {
                    "path": "GET STARTED_Ambients",
                    "title": "AVAP Ambients",
                    "view":<AVAP_Ambients/>
                },
                {
                    "path": "GET STARTED_Products",
                    "title": "AVAP API Products",
                    "view":<AVAP_API_Products/>
                },
                {
                    "path": "GET STARTED_Projects",
                    "title": "AVAP API Projects",
                    "view":<AVAP_API_Projects/>
                },
                {
                    "path": "GET STARTED_My_1st_API",
                    "title": "Creating my first API",
                    "view":<My_First_API/>
                },
                {
                    "path": "GET STARTED_Dev_Token",
                    "title": "Create Your Developer Token",
                    "view":<Developer_Token/>
                },
                {
                    "path": "GET STARTED_MSVC_Extensions",
                    "title": "AVAP Extensions for MVSC",
                    "view":<MSVC_Extensions/>
                },
                {
                    "path": "GET STARTED_Collaborative",
                    "title": "AVAP Collaborative Network",
                    "view":<Collaborative_Network/>
                },
                {
                    "path": "GET STARTED_Cloud",
                    "title": "AVAP Cloud",
                    "view":<AVAP_Cloud/>
                },
                {
                    "path": "GET STARTED_Downloads",
                    "title": "Software Downloads",
                    "view":<Software_Downloads/>
                },
                {
                    "path": "GET STARTED_AVS",
                    "title": "AVS (AVAP Virtual Server)",
                    "view":<Infrastructure_AVS/>
                }
            ],
            "title": "GET STARTED"
        },
        */

        /*
        {
            "id": "over_view",
            "links": [
                {
                    "path": "OVERVIEW",
                    "title": "Software Downloads",
                    "view": <Software_Downloads/>
                }
            ],
            "title": "Software Downloads"
        },
        {
            "id": "over_view2",
            "links": [
                {
                    "path": "AVS",
                    "title": "AVS (AVAP Virtual Server)",
                    "view": <Infrastructure_AVS/>
                }
            ],
            "title": "Network Architecture Types"
        },




        
        {
            "id": "setup",
            "links": [
                {
                    "path": "SETUP_Additional components and tools",
                    "title": "Additional Components and tools",
                    "view": <SETUP_Additionalcomponentsandtools/>
                },
                {
                    "path": "SETUP_Enterprise",
                    "title": "Enterprise",
                    "view": <SETUP_Enterprise/>
                },
                {
                    "path": "SETUP_Linux",
                    "title": "Linux",
                    "view": <SETUP_Linux/>
                },
                {
                    "path": "SETUP_MacOS",
                    "title": "macOS",
                    "view": <SETUP_MacOS/>
                },
                {
                    "path": "SETUP_Network",
                    "title": "NetWork",
                    "view":<SETUP_Network/>
                },
                {
                    "path": "SETUP_Overview",
                    "title": "Overview",
                    "view": <SETUP_Overview/>
                },
                {
                    "path": "SETUP_RaspberryPi",
                    "title": "RaspberryPi",
                    "view": <SETUP_RaspberryPi/>
                },
                {
                    "path": "SETUP_Uninstall",
                    "title": "Unistall",
                    "view": <SETUP_Uninstall/>
                },
                {
                    "path": "SETUP_Windows",
                    "title": "Windows",
                    "view": <SETUP_Windows/>
                }
            ],
            "title": "SetUP"
        },
        {
            "id": "sourcecontrol",
            "links": [
                {
                    "path": "SOURCE_CONTROL_CollaborateOnGitHub",
                    "title": "Collaborate on Github",
                    "view":<SOURCE_CONTROL_CollaborateOnGitHub/>
                },
                {
                    "path": "SOURCE_CONTROL_FAQ",
                    "title": "FAQ",
                    "view":<SOURCE_CONTROL_FAQ/>
                },
                {
                    "path": "SOURCE_CONTROL_IntroductionToGit",
                    "title": "Introduction To Git",
                    "view":<SOURCE_CONTROL_IntroductionToGit/>
                },
                {
                    "path": "SOURCE_CONTROL_Overview",
                    "title": "OverView",
                    "view":<SOURCE_CONTROL_Overview/>
                }
            ],
            "title": "Source Control"
        },
        {
            "id": "terminal",
            "links": [
                {
                    "path": "TERMINAL_Advanced",
                    "title": "Advanced",
                    "view":<TERMINAL_Advanced/>
                },
                {
                    "path": "TERMINAL_Appearance",
                    "title": "Appearance",
                    "view":<TERMINAL_Appearance/>
                },
                {
                    "path": "TERMINAL_Shell_Integration",
                    "title": "Shell Integration",
                    "view":<TERMINAL_Shell_Integration/>
                },
                {
                    "path": "TERMINAL_TerminalBasics",
                    "title": "Terminal Basics",
                    "view":<TERMINAL_TerminalBasics/>
                },
                {
                    "path": "TERMINAL_TerminalProfiles",
                    "title": "Terminal Profiles",
                    "view":<TERMINAL_TerminalProfiles/>
                }
            ],
            "title": "Terminal"
        },
        {
            "id": "userguide",
            "links": [
                {
                    "path": "USER GUIDE-BasicEditing",
                    "title": "Basic Editing",
                    "view":<USERGUIDE_BasicEditing/>
                },
                {
                    "path": "USER_GUIDE_Accesibility",
                    "title": "Accesibility",
                    "view":<USER_GUIDE_Accesibility/>
                },
                {
                    "path": "USER_GUIDE_AVAPforTheWeb",
                    "title": "AVAP for The Web",
                    "view":<USER_GUIDE_AVAPforTheWeb/>
                },
                {
                    "path": "USER_GUIDE_CodeNavigation",
                    "title": "Code Navegation",
                    "view":<USER_GUIDE_CodeNavigation/>
                },
                {
                    "path": "USER_GUIDE_CommandLineInterface",
                    "title": "Command Line Interface",
                    "view":<USER_GUIDE_CommandLineInterface/>
                },
                {
                    "path": "USER_GUIDE_CustomLayout",
                    "title": "Custom Layout",
                    "view":<USER_GUIDE_CustomLayout/>
                },
                {
                    "path": "USER_GUIDE_Debugging",
                    "title": "Debugging",
                    "view":<USER_GUIDE_Debugging/>
                },
                {
                    "path": "USER_GUIDE_Emmet",
                    "title": "Emmet",
                    "view":<USER_GUIDE_Emmet/>
                },
                {
                    "path": "USER_GUIDE_ExtensionMarketplace",
                    "title": "Extension Market Place",
                    "view":<USER_GUIDE_ExtensionMarketplace/>
                },
                {
                    "path": "USER_GUIDE_IntelliSense",
                    "title": "Intelli Sense",
                    "view":<USER_GUIDE_IntelliSense/>
                },
                {
                    "path": "USER_GUIDE_MultiRootWorkspaces",
                    "title": "Multi Root Work Spaces",
                    "view":<USER_GUIDE_MultiRootWorkspaces/>
                },
                {
                    "path": "USER_GUIDE_Profiles",
                    "title": "Profiles",
                    "view":<USER_GUIDE_Profiles/>
                },
                {
                    "path": "USER_GUIDE_Refactoring",
                    "title": "Refactoring",
                    "view":<USER_GUIDE_Refactoring/>
                },
                {
                    "path": "USER_GUIDE_SettingsSync",
                    "title": "Settings Sync",
                    "view":<USER_GUIDE_SettingsSync/>
                },
                {
                    "path": "USER_GUIDE_Snippets",
                    "title": "Snippets",
                    "view":<USER_GUIDE_Snippets/>
                },
                {
                    "path": "USER_GUIDE_Tasks",
                    "title": "Tasks",
                    "view":<USER_GUIDE_Tasks/>
                },
                {
                    "path": "USER_GUIDE_WorkspaceTrust",
                    "title": "Work Space",
                    "view":<USER_GUIDE_WorkspaceTrust/>
                }
            ],
            "title": "User Guide"
        },
        {
            "id": "Avapcli",
            "links": [
                {
                    "path": "Cli",
                    "title": "Cli",
                    "view":<Cli/>
                }
            ],
            "title": "Avapcli"
        },*/
    ],
    "basePath": "wiki/",
    "titleNav": "TITULO QUE TU QUIERAS"
}

export default routerWiki;

