import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';


type addTeamProps = {
  show: boolean,
  organization: number,
  handleClose: () => void
}
const AddTeamModal:FunctionComponent<addTeamProps> = (props) => {

  const [cardNumber, setCardNumber] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvv, setCVV] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [yaFoco, setyaFoco] = useState(false);

  const [errorNombre, setErrorNombre] = useState(false);

  const [validName, setValidName ] = useState(false);
  const [validTarget, setValidTarget ] = useState(false);
  const [validEndpoint, setValidEndpoint ] = useState(false);
  // const [errorNombre, setErrorNombre] = useState(false);
  const [errorTarget, setErrorTarget] = useState(false);
  const [errorEndpoint, setErrorEndpoint] = useState(false);




  const yearInputRef = useRef(null);
  const cvvInputRef = useRef(null);

  const changeCardNumber = (e) => {
    const newCardNumber = e.target.value; // remove hyphens
    setCardNumber(newCardNumber);
    //if (newCardNumber.length>3 && cardholder.length>3 && errorNombre == false) setSubmitDisabled(false);
    validateTarget(newCardNumber)
  
  }

  const changeMonth = (e) => {
    const newMonth = e.target.value;
    setMonth(newMonth);
    if (newMonth.toString().length == e.target.maxLength){
      yearInputRef.current.focus();
    }
  }

  const changeYear = (e) => {
    const newYear = e.target.value;
    setYear(newYear);
    if (newYear.toString().length == e.target.maxLength){
      cvvInputRef.current.focus();
    }
  }

  const changeCardholder = (e) => {
    const newCardholder = e.target.value;
    setCardholder(newCardholder);

    if (newCardholder.length>3 && cardNumber.length>3) setSubmitDisabled(false);
    

    validateName(newCardholder);
    //if (newCardholder.length>3 && cardNumber.length>3) setSubmitDisabled(false);
    

  }


  const validateName = (email) => {
    const emailFormat = /[a-zA-Z]{1,18}$/;
    if (email.match(emailFormat)) {
      setValidName(true); 
      setErrorNombre(false)
      
      if (validTarget) {
        setSubmitDisabled(false);
        //setErrorNombre(true)
      }
    }
      else {
        setValidName(false);
        setSubmitDisabled(true);
        setErrorNombre(true);
      }
  }


  const validateTarget = (email) => {
    const emailFormat = /[a-zA-Z]{1,18}$/;
    if (email.match(emailFormat)) {
      setValidTarget(true); 
      setErrorTarget(false)
      
      if (validName) {
        setSubmitDisabled(false);
        //setErrorTarget(true)
      }
    }
      else {
        setValidTarget(false);
        setSubmitDisabled(true);
        setErrorTarget(true);
      }
  }



  const changeCVV = (e) => {
    const newCVV = e.target.value;
    setCVV(newCVV);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    setError('');// Limpiamos los posibles errores
    const payload = {
      card_number: cardNumber,
      month_exp: month,
      year_exp: year,
      cvv: cvv,
      card_name: cardholder
    }
    const result = await ObexRequestHandler.get(`/create_dev_team?organization=${props.organization}&team_name=${cardholder}&team_description=${cardNumber}` , {}, true );
    const { success, data, message } = result;
    if (success){
      setLoading(false);
      setSuccess(data);
        setTimeout(function() { 
          setSuccess('');
          setSubmitDisabled(true);
          props.handleClose();
          }.bind(this), 1000)
    } else {
      setError(message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!yaFoco) {document.getElementById('entrada-teams').focus();
      setyaFoco(true);
    }
    if (cardNumber && month && year && cvv && cardholder){
      setSubmitDisabled(false);
    }
  })
  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={props.show} 
      onHide={props.handleClose}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Col md="12" className="addons-title">
            <span>Add Developer Team</span>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="10" className="mx-auto">
            <Form className="obex-form payment-method-form" onSubmit={handleSubmit}>
              {/* <Form.Row className="py-2">
                <Form.Label column sm="12" className="obex-form-label">
                  How would you like to pay?
                </Form.Label>
                <Col md="1" className="text-right py-1 pr-5">
                  <Form.Check
                    type="radio"
                    value="CreditCard"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios1"
                  />
                </Col>
                <Col className="type-payment-method py-1 pl-3">
                  <span><FontAwesomeIcon icon={faCreditCard}/> Credit Card</span>
                </Col>
              </Form.Row>
              <Form.Row className="py-2 mb-3">
                <Col md="1" className="text-right py-1 pr-5">
                  <Form.Check
                    type="radio"
                    value="PayPal"
                    name="formHorizontalRadios"
                    id="formHorizontalRadios2"
                  />
                </Col>
                <Col className="type-payment-method py-1 pl-3">
                  <span><FontAwesomeIcon icon={faPaypal}/> Paypal</span>
                </Col>
              </Form.Row> */}
              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  Developer Team name
                </Form.Label>
                <Form.Control 
                isValid={!errorNombre && cardholder.length>0} isInvalid={errorNombre} maxLength={18}
                id='entrada-teams' required type="text" placeholder="Name" onChange={changeCardholder}/>
              </Form.Row>
              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  Developer Team Description
                </Form.Label>
                
                  <Form.Control 
                  isValid={!errorTarget && cardNumber.length>0} isInvalid={errorTarget} 
                  required type="text" maxLength={200} placeholder="Description" onChange={changeCardNumber}/>
                
              </Form.Row>

              
              <Form.Row className="my-3">
                <Col md="4" className="ml-auto">
                  <button disabled={submitDisabled} type="submit" className="custom-button-blue textBold" style={{paddingLeft:'18px', paddingRight:'18px'}}>
                      Add Developer Team {success && <FontAwesomeIcon icon={faCheck}/>}
                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                  </button>
                </Col>
              </Form.Row>
            </Form>
            {error && <Alert variant="danger">
              {error}
            </Alert>}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default AddTeamModal;