import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col, CardGroup, CardDeck, Card, Button, Image } from 'react-bootstrap';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import PlanCard from './PlanCard';
import LogoIconoes from '../../assets/img/marcas1.png';
import compareicon from '../../assets/img/flechasC.png';
import FondoUpgrade from '../../assets/img/legos_fondologin.svg';
import IconoArch from '../../assets/img/archA.png';
import IconoSettings from '../../assets/img/rjustes.png';
import IconoFaq from '../../assets/img/faq.png';
import '../../assets/css/plansPricings.css';
import AddonsModal from './AddonModal';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import { useHistory } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type PlansProps = {
  planID?: number
}

const UpdatePlan:FunctionComponent<PlansProps> = (props: PlansProps) => {

  const [subscriptions, setSubscriptions] = useState([]);
  const [show, setShow] = useState(false);
  const [loadplan2, setPlan2] = useState(0);
  const [PlanID, setPlanID] = useState(0);
  const [actualPlan, setActualPlan] = useState(0);
  const history = useHistory();
  const getSubscriptions = async () => {
    const result = await ObexRequestHandler.get('/subscriptions');
    const { success, data } = result;
    if (success) {
      setSubscriptions(data);
    } else {
        console.error('ALGO HA IDO MAL')
    }
  }

  const getSuscription = async () => {

    const result = await ObexRequestHandler.get('/purchased_services');
      const { success, data } = result;
      if (success){
        setActualPlan(data.subscription.subscription_id);
        if ((!props.planID)) setPlanID(data.subscription.subscription_id);
      }
  
    }

  useEffect(() => {
    getSuscription();
    getSubscriptions();
  }, []);
    
  return (
<>
            <BrowserView>
    <DashLayout sider={DashRouters} active={'plan_subcription'}>
    {(loadplan2 > 0) && 
      <AddonsModal 
        show={show}
        handleClose={()=>{setShow(false);setPlan2(0);}}
        nameProject={subscriptions[loadplan2-1].name}
        planID={loadplan2}
        priceMonthly={subscriptions[loadplan2-1].monthly_price}
        priceYear={subscriptions[loadplan2-1].annual_price}
        upgrade={true}
      />}

      <Container fluid className="plans_pricings_update" style={{ backgroundImage: `url(${FondoUpgrade})`, backgroundSize: 'contain', backgroundRepeat: 'repeat-y'}}>
        <Row className="justify-content-center" style={{ backgroundImage: FondoUpgrade }}>
        
          <Col md={10} className="text-center">
            <h1 className="title-page my-5">Find a <span style={{ color:'#7abcb0' }}>plan</span> that works for you</h1>
          </Col>
          <Col md={10} className="text-center" style={{paddingTop:'20px', paddingBottom:'20px'}}>
            <p>From independent developers to Fortune 500 companies, 101 sales for any organizations, </p>
            <p>Get building on any plan for free.</p>
          </Col>
        </Row>
        <Row style={{paddingBottom:'50px'}}>
          <div className="pricing-box mx-auto">
            <CardGroup >
              {subscriptions.map(data => { 
                return (<PlanCard 
                    key={'PlancardUpdate'+data.id+Math.random()}
                    data={data} 
                    planSelected={0} 
                    activated={(data.id>PlanID)} 
                    upgrade={true}/>
                    );
                  }
                )
              }
            </CardGroup>
          </div>
        </Row>
        <Row  style={{padding:'20px',backgroundColor: '#f0f0f0', borderRadius: '0px'}} >

        <Row style={{  width: '100vw', padding: '20px', paddingBottom:'70px' }}>
          <Col md={12} className="text-center">
            <p style={{ fontWeight: 'bold', paddingLeft:'20%', paddingRight:'20%' }}>Join our partners, customers, and thousands of developers who are already building a wide range of fintech solutions such as Wallets, Open Banking, or Bank as a Service amongst others with 101OBeX.</p>
          </Col>
          <Col md={12} className="text-center" style={{ paddingTop: '30px' }}>
            <Image style={{ width: '90%' }} src={LogoIconoes} />
          </Col>
        </Row>


            {/*}
            <Col md={3}>

            <Image src={FondoUpgrade} style={{ zIndex:999, width:'100%', position:'absolute' }}/> Esto es para el fondo 
            <Image  style={{ width:'90%', marginTop:'52px', marginLeft:'5%' }} src={LogoIconoes}/> imagenes marcas
            
            <p className="plan-descrip-upgrade active">1 active slot project of 1 project max.</p>git 
                <p className="plan-descrip-upgrade active">100 requests of 3.100 max.</p>
                <p className="plan-descrip-upgrade active">25Mb storage of 25Mb max.</p>
                <p className="plan-descrip-upgrade active">Sandbox project.</p>
                <p className="plan-descrip-upgrade inactive">Cipher your projects.</p>
                <p className="plan-descrip-upgrade inactive">Access to proffesional support.</p>
                <p className="plan-descrip-upgrade inactive">Work with staging projects.</p>
                <p className="plan-descrip-upgrade inactive">Work with your own database.</p>



              </Col>
              <Col md={3}>
              <p className="plan-descrip-upgrade active">1 active slot project of 2 project max.</p>
                <p className="plan-descrip-upgrade active">5,000 requests of 50,000 max.</p>
                <p className="plan-descrip-upgrade active">50Mb storage of 500Mb max.</p>
                <p className="plan-descrip-upgrade active">Sandbox project.</p>
                <p className="plan-descrip-upgrade active">Cipher your projects.</p>
                <p className="plan-descrip-upgrade active">Access to proffesional support.</p>
                <p className="plan-descrip-upgrade inactive">Work with staging projects.</p>
                <p className="plan-descrip-upgrade inactive">Work with your own database.</p>


              </Col>
              <Col md={3}>
              <p className="plan-descrip-upgrade active">2 active slot project of 5 project max.</p>
                <p className="plan-descrip-upgrade active">50,000 requests of 500,000 max.</p>
                <p className="plan-descrip-upgrade active">150Mb storage of 1Gb max.</p>
                <p className="plan-descrip-upgrade active">Sandbox project.</p>
                <p className="plan-descrip-upgrade active">Cipher your projects.</p>
                <p className="plan-descrip-upgrade active">Access to proffesional support.</p>
                <p className="plan-descrip-upgrade active">Work with staging projects.</p>
                <p className="plan-descrip-upgrade active">Work with your own database (Postgres).</p>


              </Col>
              <Col md={3}>
                <p className="plan-descrip-upgrade active">Unlimited project.</p>
                <p className="plan-descrip-upgrade active">Unlimited requests.</p>
                <p className="plan-descrip-upgrade active">Unlimited storage.</p>
                <p className="plan-descrip-upgrade active">Sandbox project.</p>
                <p className="plan-descrip-upgrade active">Cipher your projects.</p>
                <p className="plan-descrip-upgrade active">Access to proffesional support.</p>
                <p className="plan-descrip-upgrade active">Work with staging projects.</p>
                <p className="plan-descrip-upgrade active">Work with your own database (Postgres and others).</p>

              </Col>
    */}
              
        </Row>
      </Container>
      <Container fluid className="mb-4" style={{ backgroundImage: `url(${FondoUpgrade})`, backgroundSize: 'contain', backgroundRepeat: 'repeat-y', paddingTop:'50px'}}>
        <Row className="pricing-box mx-auto">
          <Col >
            <Row>
            <Col style={{ maxWidth:'80px' }}>
            <Image src={compareicon} style={{ width:'50px' }}/>
            </Col>
            <Col>
            <h1 style={{ paddingTop:'10px' }}>Compare features</h1>
            </Col>
            </Row>
          </Col>
        </Row>

<Row style={{ borderStyle:'solid', borderColor:'#dfdfdf', borderWidth:'1px', borderRadius:'30px' }}>

        <Row className="pricing-box mx-auto" >
          <Col className="py-4">
            <CardDeck className="table-comparison">
              <Card className="mx-1">
                <Card.Body className="px-2 d-flex flex-column column-features">
                  <Card.Title className="text-center column-title" style={{ height:'60px', borderBottomStyle:'solid', borderBottomColor:'#dfdfdf', borderBottomWidth:'1px' }}></Card.Title>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Number of Projects</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Max # of Projects</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Number of Reqests</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Max # of Reqests</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">API Products</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Access to add-ons</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Access to plug-ins</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Access to Encryption</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Staging Projects</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Database Location</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Community Support</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Status Page</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Support 101OBeX Team</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Audit Logs</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Sandbox</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Ehtereum Module</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Uniswap Integration</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Firebase Integration</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Geolocalization Module</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">WIX Integration</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Time control</Card.Text>
                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2 d-flex flex-column">
                  <Card.Title className="column-title" style={{ height:'60px', color:'#c6e5df', borderBottomStyle:'solid', borderBottomColor:'#dfdfdf', borderBottomWidth:'1px', justifyContent:'center' }}><Col><Row style={{ justifyContent:'center' }}>Test</Row><Row style={{ justifyContent:'center', color:'#727272', fontSize:'14px', fontFamily:'arial' }}>$0/user/month</Row></Col></Card.Title>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">1</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">1</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">100</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">3.100</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Full Access </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Partial</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }} className="">Yes Up to 5</Card.Text>
          

                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2  d-flex flex-column">
                <Card.Title className="column-title" style={{ height:'60px', color:'#c6e5df', borderBottomStyle:'solid', borderBottomColor:'#dfdfdf', borderBottomWidth:'1px', justifyContent:'center' }}><Col><Row style={{ justifyContent:'center' }}>StartUp</Row><Row style={{ justifyContent:'center', color:'#727272', fontSize:'14px', fontFamily:'arial' }}>$50/user/month</Row></Col></Card.Title>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>1</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>2</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>5.000</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>50.000</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Add-on </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>-</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
     

                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2  d-flex flex-column">
                <Card.Title className="column-title" style={{ height:'60px', color:'#c6e5df', borderBottomStyle:'solid', borderBottomColor:'#dfdfdf', borderBottomWidth:'1px', justifyContent:'center' }}><Col><Row style={{ justifyContent:'center' }}>Business</Row><Row style={{ justifyContent:'center', color:'#727272', fontSize:'14px', fontFamily:'arial' }}>$150/user/month</Row></Col></Card.Title>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>2</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>5</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>50.000</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>500.000</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Unlimited</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes </Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>


                </Card.Body>
              </Card>
              <Card className="text-center mx-1">
                <Card.Body className="px-2 d-flex flex-column">
                <Card.Title className="column-title" style={{ height:'60px', borderBottomStyle:'solid', borderBottomColor:'#dfdfdf', borderBottomWidth:'1px', color:'#c6e5df' }}><Col><Row style={{ justifyContent:'center' }}>Enterprise</Row><Row style={{ justifyContent:'center', color:'#727272', fontSize:'14px', fontFamily:'arial' }}>Custom pricing</Row></Col></Card.Title>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>10</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Unlimited</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>500.000</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Unlimited</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Unlimited</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Full Access</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>

                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  <Card.Text style={{ borderBottomStyle:'solid', borderBottomColor:'#f9f9f9', borderBottomWidth:'1px' }}>Yes</Card.Text>
                  
  

                </Card.Body>
              </Card>
            </CardDeck>
          </Col>
        </Row>
        <Row className="pricing-box-footer mx-auto">
          {0<PlanID ? 
          <Col xs="2" className="mx-auto text-center">
            <Button active={false} disabled={true} style={{ backgroundColor: '#7abcb0', borderColor: '#7abcb0' }} className="plans_button_footer_deactivated text-center" onClick={()=>{setPlan2(1);setShow(true)}} >Sign Up</Button>
          </Col>
          :
          <Col xs="2" className="mx-auto text-center">
            <Button disabled={false} className="plans_button_footer" onClick={()=>{setPlan2(1);setShow(true)}} >Apply Now</Button>
          </Col>
          }

          {1<PlanID ? 
          <Col xs="2" className="mx-auto text-center">
            <Button active={false} disabled={true} style={{ backgroundColor: '#313131', borderColor: '#31313100' }} className="plans_button_footer_deactivated text-center" onClick={()=>{setPlan2(2);setShow(true)}} >Apply Now</Button>
          </Col>
          :
          <Col xs="2" className="mx-auto text-center">
            <Button disabled={false} className="plans_button_footer" onClick={()=>{setPlan2(2);setShow(true)}} >Buy Now</Button>
          </Col>
          }

          {2<PlanID ? 
          <Col xs="2" className="mx-auto text-center">
            <Button active={false} disabled={true} style={{ backgroundColor: '#313131', borderColor: '#31313100' }} className="plans_button_footer_deactivated text-center" onClick={()=>{setPlan2(3);setShow(true)}} >Apply Now</Button>
          </Col>
          :
          <Col xs="2" className="mx-auto text-center">
            <Button disabled={false} className="plans_button_footer" onClick={()=>{setPlan2(3);setShow(true)}} >Buy Now</Button>
          </Col>
          }

          <Col xs="2" className="mx-auto text-center">
            <Button disabled={false} className="plans_button_footer" onClick={()=>{history.push('https://www.101obex.com/#comp-jxad0a3m');}} >Contact Us</Button>
          </Col>
        </Row>
</Row>

      </Container>


      <Row className="justify-content-center" style={{ marginBottom: '20px' }}>
      <Col  style={{ backgroundColor: '#7abcb0', padding: '30px' }}>
        <Row>
          <Col md={12} className="d-flex align-items-start">
            <Image src={IconoArch} style={{ width: '50px', marginRight: '10px' }} />
            <div>
              <h1 style={{ paddingTop: '10px', marginBottom: '150px', marginRight:'65%' }}>Spend more time on <span style={{ color: '#ffffff' }}>your product,</span> not internal tools</h1>
              <p style={{ color: '#ffffff', marginBottom: '100px', marginRight:'50%' }}>Eligible early-stage startups can use 101OBeX free of charge for one year to rapidly develop their fintech products and services. Add admin panels, dashboards, and internal tools without losing focus.</p>
              <Button style={{ border: '2px solid white', borderRadius: '10px' }} disabled={false} className="plans_button_footer">Get your free credits</Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>

      
    <Row className="justify-content-center" style={{ paddingTop: '40px' }}>
  <Col style={{ maxWidth: '80px' }}>
    <Image src={IconoFaq} style={{ width: '50px' }} />
  </Col>
  <Col>
    <h1 style={{ paddingTop: '10px' }}>Frequently Asked Questions</h1>
  </Col>
</Row>
        <Row>
        <Col style={{ backgroundImage: `url(${FondoUpgrade})`, backgroundSize: 'cover', backgroundRepeat: 'repeat-y' }}>
          
                        <Row>
                        {/* Primera fila de casillas de texto */}
                        <Col md={4} style={{padding:'40px'}}>
                            <Row style={{paddingBottom:'20px'}}>
                              <Col md={12} className="d-flex align-items-center" >
                                <Image src={IconoSettings} style={{ width: '50px', marginRight: '10px' }} />
                                <p style={{ color: '#000000', margin: 0 }}>What does 101OBeX do?</p>
                              </Col>
                            </Row>
                            <p>101OBeX provides a full-stack API platform that allows developers to build, deploy, and manage APIs quickly and easily, enabling organizations to innovate and be more productive.</p>
                        </Col>
                          <Col md={4} style={{padding:'40px'}}>
                            <Row style={{paddingBottom:'20px'}}>
                              <Col md={12} className="d-flex align-items-center">
                                <Image src={IconoSettings} style={{ width: '50px', marginRight: '10px' }} />
                                <p style={{ color: '#000000', margin: 0 }}>How long does it take to learn 101OBeX</p>
                              </Col>
                            </Row>
                            <p>Learning is gradual but with just a few hours you will be able to start using 101OBeX. If you are not a developer, don’t worry, you can enjoy the 101 experience with our low-code options.</p>
                          </Col>
                          <Col md={4} style={{padding:'40px'}}>
                          <Row style={{paddingBottom:'20px'}}>
                              <Col md={12} className="d-flex align-items-center">
                                <Image src={IconoSettings} style={{ width: '50px', marginRight: '10px' }} />
                                <p style={{ color: '#000000', margin: 0 }}>How do I deploy my app?</p>
                              </Col>
                            </Row>
                            <p>101OBeX does not interfere with the deployment of your project. Deploy your app as you have always done.</p>
                          </Col>
                        </Row>
                        <Row>
                          {/* Segunda fila de casillas de texto */}
                          <Col md={4} style={{padding:'40px'}}>
                          <Row style={{paddingBottom:'20px'}}>
                              <Col md={12} className="d-flex align-items-center">
                                <Image src={IconoSettings} style={{ width: '50px', marginRight: '10px' }} />
                                <p style={{ color: '#000000', margin: 0 }}>Can I deploy 101OBeX on-premises?</p>
                              </Col>
                            </Row>
                            <p>If you have more stringent data requirements, you can deploy 101OBeX on-premises in your own VPC.</p>
                            <p>If you’re interested, please try our self-hosted version above or book a setup call with a partner.</p>
                          </Col>
                          <Col md={4} style={{padding:'40px'}}>
                          <Row style={{paddingBottom:'20px'}}>
                              <Col md={12} className="d-flex align-items-center">
                                <Image src={IconoSettings} style={{ width: '50px', marginRight: '10px' }} />
                                <p style={{ color: '#000000', margin: 0 }}>Who uses 101OBeX? Developers or non-technical people?</p>
                              </Col>
                            </Row>
                            <p>In general, developers are the primary users of 101OBeX, but even non-technical people can use 101OBeX thanks to our low code options. Thus 101 is adaptable to everyone’s needs and capabilities.</p>
                          </Col>
                          <Col md={4} style={{padding:'40px'}}>
                          <Row style={{paddingBottom:'20px'}}>
                              <Col md={12} className="d-flex align-items-center">
                                <Image src={IconoSettings} style={{ width: '50px', marginRight: '10px' }} />
                                <p style={{ color: '#000000', margin: 0 }}>Is 101OBeX secure? Where’s my data stored?</p>
                              </Col>
                            </Row>
                            <p>101OBeX comes with the kind of security, reliability, and controls that every internal tool deserves. Your customer data is never stored on our servers and we use end-to-end encryption to keep your data safe while it’s in transit. For more information, see our security documentation. If we don’t answer your question there, let us know.</p>
                        </Col>
                        </Row>
                    </Col>
      </Row>
      

    </DashLayout>
    </BrowserView>
    <MobileView>
        <Disclaimer/>
    </MobileView>
</>
  );
}

export default UpdatePlan;