import React, { FunctionComponent, useEffect, useState } from 'react'; 
import { Row, Col, Card, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcAmex, faCcDinersClub, faCcMastercard } from '@fortawesome/free-brands-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';

type ProductMethodPaymentProps = {
  title: string,
  aliasPayment?: string,
  descrip?: string,
  typeCard?: string,
  handleCard?: (e) => void,
  isBlue?: boolean,
  cards?:Array<{id, brand, last4}>,
  addedCard?: string,
  spanish: boolean
}

const ProductMethodPayment:FunctionComponent<ProductMethodPaymentProps> = (props: ProductMethodPaymentProps) => {

const { title, aliasPayment, descrip, typeCard, isBlue, spanish } = props;

// const icons = {
//   visa: faCcVisa,
//   amex: faCcAmex, 
//   dinners: faCcDinersClub, 
//   mastercard: faCcMastercard
// }

const icons = {
  visa: "Visa",
  amex: "Amex", 
  dinners: "Dinners", 
  mastercard: "Mastercard"
}
// const [cards_, setCards] = useState([]);
const [cards_, setCards] = useState(props.cards?props.cards:[]);
const [newadded_card, setAddedCard] = useState(props.addedCard?props.addedCard:'');


// const getCards = async () => {
//   try {
//     const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
//     const { defaultCard, cards } = result;
//     const pmCards = [];
//     if (cards) {
//       for (const c of cards) {
//         const isDefault = c.id === defaultCard;
//         c.defaultCard = isDefault;
//         isDefault ? pmCards.unshift(c) : pmCards.push(c);

//       }
//     }
//     setCards(pmCards);

//   } catch (error) {
//     console.error('ERROR FETCHING CARDS ', error);
//     throw error;
//   }
// }


const cardsList = cards_.length>0 || true ? cards_.map(c => <option value={c.id} selected={newadded_card == c.id } >{icons[c.brand]} ****{c.last4}</option>): <option value='addCard'>{ spanish ? 'Añade un método de pago' : 'Add a payment method'}</option>;

useEffect(() => {
  if (props.cards) {
    setCards(props.cards);
  }
}, [props.cards?props.cards:[]]); 

useEffect( () => {
  console.log('props.addedCard', props.addedCard)
  if (props.addedCard) {
    setAddedCard(props.addedCard);
  }
}, [props.addedCard?props.addedCard:'']); 


// useEffect(() => {
//   getCards();
// }, [])

  return(
    <Card className="product-detail-card mb-3">
      <Card.Body className="p-3 paymentMethodCardBody">
        <Card.Title className='paymentMethodCard'>
          <Row>
            <Col md="6" className='paymentMethodCardCol'><span className={`title-product titlePaymentMethod titlesPaymentConfirmation ${props.isBlue?'blue':'green'}`}>{title}</span></Col>
            <Col md="6">
            <Form.Control style={{ backgroundColor:'transparent'}} required as="select" placeholder='Card' custom onChange={props.handleCard} className='cardsSelector'>
                                <option value=''>{spanish ? 'Selecciona el método':'Select a payment method'}</option>
                                {cardsList}
                                <option value='addCard'>{ spanish ? 'Añade un método de pago': 'Add a payment method'}</option>
            </Form.Control>
            </Col>
            <Col md="6" className="text-right my-auto">
              <span><span className="product-price">{aliasPayment}</span></span>
            </Col>
          </Row>
        </Card.Title>

      </Card.Body>
    </Card>
    );
}

export default ProductMethodPayment;