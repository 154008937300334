import React, { FunctionComponent, useState, useEffect } from 'react';
import {  Modal, Button, Row, Col, Form, Alert, Spinner } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import SessionService from '../../services/SessionsService';


type addTeamProps = {
  show: boolean,
  handleClose: () => void,
  projectId: number,
  APIProducstOptions: Array<unknown>,
  APIProductSelected: string
}

const AddLinktModal:FunctionComponent<addTeamProps> = (props: addTeamProps) => {

  const [cardNumber, setCardNumber] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [notFocused, setFocused] = useState(true);
  const [selectedProduc, setSelectedProduct] = useState('');
  const [selectedAPIType, setSelectedAPIType] = useState('REST');
  const [selectedAPIMethod, setSelectedAPIMethod] = useState('GET');
  const [linkName, setLinkName] = useState('');
  const [validName, setValidName ] = useState(false);
  const [validTarget, setValidTarget ] = useState(false);
  const [validEndpoint, setValidEndpoint ] = useState(false);
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorTarget, setErrorTarget] = useState(false);
  const [errorEndpoint, setErrorEndpoint] = useState(false);

  const validateName = (email) => {
    const emailFormat = /^[a-zA-Z]{1,18}$/;
    if (email.match(emailFormat)) {
      setValidName(true); 
      setErrorNombre(false)
      
      if (validTarget && validEndpoint) {
        console.log("TOTAL1")
        setSubmitDisabled(false);
        setErrorNombre(true)
      }
    }
      else {
        setValidName(false);
        setSubmitDisabled(true);
        setErrorNombre(true);
      }
  }

  const validateTarget = (email) => {
    const emailFormat = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    if (email.match(emailFormat)) {
      setValidTarget(true); 
      setErrorTarget(false);
    } else {setErrorTarget(true);}
      
    
    if (validName && validEndpoint) {
        console.log("TOTAL2")
        setErrorTarget(false);
        setSubmitDisabled(false);
      }
      else {
        setValidTarget(false);
        setSubmitDisabled(true);
      }
  }

  const validateEndpoint = (email) => {
    const emailFormat = /^\/[a-zA-Z]{0,17}$/;
    if (!emailFormat.test(email)) {
      if (validName && validTarget) {
        setErrorEndpoint(false);
        setSubmitDisabled(false);
      } else {setErrorEndpoint(true);}
      setValidEndpoint(true);
    }
    else {
      setValidEndpoint(false);
      setSubmitDisabled(true);
      setErrorEndpoint(false);
    }
  }





  const APITypes = [ 
    { id: 0, description: 'REST' } 
  ]

  const APIMethods = [ 
    { id: 'GET', description: 'GET' }, 
    { id: 'POST', description: 'POST' }, 
    { id: 'PUT', description: 'PUT' }, 
    { id: 'DELETE', description: 'DELETE' } 
  ]

  const APIProducstMethods = APIMethods.map(value => <option value={value.id}>{value.description}</option>);

  const APIProducstOptions = APITypes.map(value => <option value={value.id}>{value.description}</option>);

  const changeCardNumber = (e) => {
    const newCardNumber = e.target.value; // remove hyphens
    setCardNumber(newCardNumber);
    //if (newCardNumber.length>3 && cardholder.length>3 && linkName.length>3) setSubmitDisabled(false);

    validateEndpoint(newCardNumber)

  }

  const changeCardholder = (e) => {
    const newCardholder = e.target.value;
    setCardholder(newCardholder);

    //if (newCardholder.length>3 && cardNumber.length>3 && linkName.length>3) setSubmitDisabled(false);
    validateTarget(newCardholder)

  }


  const changeLinkName = (e) => {
    const newLinkName = e.target.value;
    setLinkName(newLinkName);
    validateName(newLinkName)
    //if (newLinkName.length>3 && cardNumber.length>3 && cardholder.length>3) setSubmitDisabled(false);
    

  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);
    setError('');
    
      const payload = {
        type: selectedAPIType,
        method: selectedAPIMethod,
        server: cardholder,
        endpoint: cardNumber,
        ambient: 'local',
        avap_catalog_id: 0,
        name: linkName,
        api_product: selectedProduc
      }
     
      console.log(payload);

    const result = await ObexRequestHandler.post(`/gateway/routing/add_gateway_link?obex_project_id=${props.projectId}`, payload);
    
    const { success, data, message } = result;


    if (success){
      setLoading(false);
      setSuccess(data);
        setTimeout(function() { 
          setSuccess('');
          setSubmitDisabled(true);
          props.handleClose();
          }.bind(this), 1000)
    } else {
      setError(message);
      setLoading(false);
    } 
  };

  useEffect(() => {
    if (selectedProduc=='') setSelectedProduct(props.APIProductSelected);
    if (notFocused) {
      document.getElementById('entrada-organizacion').focus();
      setFocused(false);
    }

    if (errorEndpoint==false && errorNombre==false && errorTarget==false && linkName.length>0 && cardNumber.length>0 && cardholder.length>0) setSubmitDisabled(false);

  })
  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={props.show} 
      onHide={props.handleClose}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Col md="12" className="addons-title">
            <span>Add a new Link</span>
          </Col>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md="10" className="mx-auto">
            <Form className="obex-form payment-method-form" onSubmit={handleSubmit}>

            <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  Name
                </Form.Label>
                <Form.Control isValid={!errorNombre && linkName.length>0} isInvalid={errorNombre} id="entrada-organizacion" required type="text" placeholder="Name of the API Link" onChange={changeLinkName}/>
              </Form.Row>

              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  Target
                </Form.Label>
                <Form.Control isValid={!errorTarget && cardholder.length>0} isInvalid={errorTarget} id="entrada-organizacion" required type="text" placeholder="Target Endpoint" onChange={changeCardholder}/>
              </Form.Row>
              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  Endpoint
                </Form.Label>
                
                  <Form.Control 
                  isValid={!errorEndpoint && cardNumber.length>0} isInvalid={errorEndpoint}
                    required type="text" 
                    maxLength={19} 
                    placeholder="Published Endpoint" 
                    onChange={changeCardNumber}/>
                
              </Form.Row>
              <Form.Row className="mb-3">
              <Form.Label column style={{maxWidth:'30%'}} className="obex-form-label">
                  API Product
                </Form.Label>
                
                        <div style={{ width:'70%', borderColor:'#a0cada', display:'flex', marginTop:'0px' }}>
                          <Form.Control as="select" onChange={(e) => {setSelectedProduct(e.target.value);console.log(e.target.value)}} custom defaultValue={props.projectId}>
                            {props.APIProducstOptions}
                          </Form.Control></div>
              </Form.Row>


              <Form.Row className="mb-3">
              <Form.Label column style={{ maxWidth:'30%' }} className="obex-form-label">
                  API Type
                </Form.Label>
                
                        <div style={{ width:'70%', borderColor:'#a0cada', display:'flex', marginTop:'0px' }}>
                          <Form.Control as="select" onChange={(e) => {setSelectedAPIType(e.target.value);console.log(e.target.value)}} custom defaultValue={props.projectId}>
                            {APIProducstOptions}
                          </Form.Control></div>
              </Form.Row>


              <Form.Row className="mb-3">
              <Form.Label column  style={{ maxWidth:'30%' }} className="obex-form-label">
                  API Method
                </Form.Label>
                
                        <div style={{ width:'70%', borderColor:'#a0cada', display:'flex', marginTop:'0px' }}>
                          <Form.Control as="select" onChange={(e) => {setSelectedAPIMethod(e.target.value);console.log(e.target.value)}} custom defaultValue={props.projectId}>
                            {APIProducstMethods}
                          </Form.Control></div>
              </Form.Row>
              
              <Form.Row className="my-3">
                <Col md="4" className="ml-auto">
                  <button disabled={submitDisabled} type="submit" className="btn-block textBold custom-button-blue">
                      Create {success && <FontAwesomeIcon icon={faCheck}/>}
                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                  </button>
                </Col>
              </Form.Row>

  

            </Form>
            {error && <Alert variant="danger">
              {error}
            </Alert>}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default AddLinktModal;